import {
  DISPLAY_OPTIONS,
  QUESTION_TYPE,
  ANSWER_TYPE,
  COMMON_QUESTION_STRING,
  REQUIRED_REQUIREMENTS_AND_OBJECTIVES_AU,
} from 'Common/constants/customerObjectives';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import {
  getAssociateQuestion,
  getOptions as getUpdatedOptions,
  getQuestion,
  hasOtherQuestion,
  appendQuestionData,
  getCustomerObjQuestions,
  dynamicQuestionHelper,
} from 'Common/utilities/objectivesQuestion';
import { deleteLoanObjectiveAnswer } from 'Assets/js/controllers/loanApp/clientAssess/util/customerObjectives';
import { parseToInt10 } from 'Common/utilities/parse';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { getPlaceHolderCustomerObj } from 'Common/utilities/placeHolder';
import { toastError } from 'Common/utilities/alert';

const LIST_OF_QUESTIONS = {};
export default class ObjectivesQuestionCtrl {
  constructor(
    $rootScope,
    loanApplicationServices,
    objectivesQuestionService,
    currentUserService,
    loanObjectivesService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.loanObjectivesService = loanObjectivesService;
    this.loanApplicationServices = loanApplicationServices;
    this.objectivesQuestionService = objectivesQuestionService;
    this.currentUserService = currentUserService;
    this.dynamicQuestionHelper = dynamicQuestionHelper(currentUserService);
    this.getPlaceHolder = getPlaceHolderCustomerObj(currentUserService);
    this.appendQuestionData = appendQuestionData(currentUserService);
  }

  $onInit() {
    this.inputDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
    this.associatedRisks = [];
    this.customSubquestion = [];
    this.displayOption = DISPLAY_OPTIONS;
    this.isQuestionDone = false;
    this.hasOtherQuestion = hasOtherQuestion;
    this.isAU = this.currentUserService.isAU;
    Object.assign(
      LIST_OF_QUESTIONS,
      getCustomerObjQuestions(this.currentUserService),
    );
    this.FEATURE_PREFERENCE =
      LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT;
  }

  $onChanges(changes) {
    const { questionList, familyId, loanId, hideQuestion } = changes;
    if (questionList && questionList.currentValue) {
      this.questionList = questionList.currentValue;
      this.getQuestionInfo();
    }
    if (loanId && loanId.currentValue) {
      this.loanId = loanId.currentValue;
    }
    if (familyId && familyId.currentValue) {
      this.familyId = familyId.currentValue;
    }
    if (hideQuestion && typeof hideQuestion.currentValue !== 'undefined') {
      this.hideQuestion = hideQuestion.currentValue;
    }
  }

  registerChildHandler(handler) {
    this.riskChildNotificationHandler = handler;
  }

  processUntickRiskNotification(action = 'untick-associated-risk') {
    typeof this.riskChildNotificationHandler === 'function' &&
      this.riskChildNotificationHandler(action);
  }

  setMainQuestionHide() {
    return !(!this.questionInfo || !!this.hideQuestion);
  }

  updateObjectivesData(question) {
    this.loanApplicationServices
      .getLoanObjectives(this.loanId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.objectivesQuestionService.setObjectives(data);
        this.getQuestionInfo();
        this.getFeaturePreferenceQuestion(question);
      });
  }

  checkLoanObjectives(obj) {
    const questionType =
      this.questionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
        ? QUESTION_TYPE.FUNDING_DETAILS.value
        : '';
    const isObjectiveEmpty = !!questionType || !(obj && obj.length);
    if (isObjectiveEmpty) {
      this.loanApplicationServices
        .getLoanObjectives(this.loanId, questionType)
        .then((response) => {
          if (!response || !response.data) {
            return;
          }
          this.objectivesQuestionService.setObjectives(response.data);
          this.getAnswer(response.data);
        });
    } else {
      this.getAnswer(obj);
    }
  }

  showQuestionOption() {
    if (!this.questionInfo) {
      return false;
    }
    const { showOption, answerList } = this.questionInfo;
    return (answerList && answerList.length) || showOption;
  }

  showCustomSubquestion() {
    return (
      this.questionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE &&
      this.questionInfo &&
      this.questionInfo.answerList &&
      this.questionInfo.answerList.length
    );
  }

  getCustomSubquestion() {
    if (!this.showCustomSubquestion()) {
      return;
    }
    this.questionInfo.answerValue = 1;
    this.loanApplicationServices
      .getFundingDetailsObjectives(this.loanId, this.questionId)
      .then((response) => {
        if (!response || !response.length) {
          return;
        }
        this.customSubquestion = response || [];
        this.questionInfo.answerList.map((item) => {
          const subQuestionDetails =
            this.customSubquestion.find((o) => o.value === item.value) || {};
          item.details = subQuestionDetails.details || '';
          item.fundingRefinanceId = subQuestionDetails.fundingDetailsObjId || 0;
          return item;
        });
      });
  }

  getQuestionInfo() {
    if (!this.questionId || !(this.questionList && this.questionList.length)) {
      return;
    }
    this.questionInfo = this.questionList.find(
      (item) => item.QuestionId === this.questionId,
    );
    if (!this.questionInfo) {
      return;
    }
    this.questionInfo.showOption = false;
    this.questionInfo.optionList = this.objectivesQuestionService.getOptionList(
      this.questionInfo,
      this.currentUserService,
    );
    this.questionInfo.placeHolder = this.getPlaceHolder(this.questionId);
    this.questionInfo.answerType = this.objectivesQuestionService.getQuestionOptionType(
      this.questionInfo.QuestionDisplayTypeId,
    );
    this.questionInfo.hasAssociatedRisks = this.dynamicQuestionHelper.isQuestionAssociatedRisks(
      this.questionId,
    );
    const { objectivesData } = this.objectivesQuestionService;
    this.checkLoanObjectives(objectivesData);
  }

  getAnswer(objectivesData) {
    const data = objectivesData.find(
      (item) => item.QuestionId === this.questionId,
    );
    this.questionInfo.answerList = this.objectivesQuestionService.getAnswerList(
      data,
    );
    this.questionInfo.answerValue = this.objectivesQuestionService.getAnswer(
      data,
    );
    this.getCustomSubquestion();
    this.getSubQuestionsAnswer(objectivesData);
    this.setQuestionStatus();
  }

  getSubQuestionsAnswer(objectivesData) {
    if (
      !this.questionInfo ||
      !this.questionInfo.SubQuestion ||
      !this.questionInfo.SubQuestion.length
    ) {
      return;
    }
    this.questionInfo.SubQuestion.map((item) => {
      const obj = item;
      obj.optionList = this.objectivesQuestionService.getOptionList(
        item,
        this.currentUserService,
      );
      obj.answerType = this.objectivesQuestionService.getQuestionOptionType(
        item.QuestionDisplayTypeId,
      );
      obj.placeHolder = this.getPlaceHolder(item.QuestionId);
      obj.optionalNote = this.dynamicQuestionHelper.isOptionalQuestion(
        item.QuestionId,
      );
      obj.withTextFieldQuestion = this.dynamicQuestionHelper.isQuestionTextbox(
        item.QuestionId,
      );
      obj.textareaSize = this.objectivesQuestionService.textareaSize(
        item.QuestionId,
      );
      obj.hideQuestion = this.objectivesQuestionService.hideSubQuestion(
        item.QuestionId,
        this.currentUserService,
      );
      obj.showOtherField = this.objectivesQuestionService.showSubQuestionOtherFields(
        item.QuestionId,
      );
      obj.otherFieldValue = this.objectivesQuestionService.getOtherFieldValue(
        item.QuestionId,
      );
      this.associatedRisksInfo = getAssociateQuestion(
        item,
        this.questionInfo.hasAssociatedRisks,
      );
      const data = objectivesData.find((o) => o.QuestionId === item.QuestionId);
      obj.answerValue = this.objectivesQuestionService.getAnswer(data);
      obj.answerList = this.objectivesQuestionService.getAnswerList(data);
      this.getAssociatedRisksList(obj);
      this.setSubQuestionStructure(obj);
      this.getFeaturePreferenceQuestion(obj);
      return obj;
    });
  }

  getGenericAnswerList(question) {
    if (!question) {
      return [];
    }
    const { objectivesData } = this.objectivesQuestionService;
    const withAnswers = getQuestion(objectivesData, question.QuestionId);
    const isValid =
      isValidObjectValue(() => withAnswers.answerInt) &&
      question.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN;
    if (!isValid) {
      return [];
    }

    const options = this.objectivesQuestionService.getOptionByQuestion(
      question.QuestionId,
    );
    return options.filter(
      (item) =>
        withAnswers.answerInt.includes(parseToInt10(item.value)) && item.value,
    );
  }

  getNestedSubQuestion(question, data) {
    if (!question.SubQuestion) {
      return [];
    }
    const nestedQuestion = getQuestion(question.SubQuestion, data);
    if (!nestedQuestion || !nestedQuestion.SubQuestion) {
      return question.SubQuestion;
    }
    const { objectivesData } = this.objectivesQuestionService;
    nestedQuestion.SubQuestion = nestedQuestion.SubQuestion.map(
      (subQuestion) => ({
        ...subQuestion,
        answerType: this.objectivesQuestionService.getQuestionOptionType(
          subQuestion.QuestionDisplayTypeId,
        ),
        optionList: getUpdatedOptions(
          this.objectivesQuestionService.objectivesOption,
          subQuestion.QuestionId,
        ),
        answerList: this.getGenericAnswerList(subQuestion),
        answerValue: this.objectivesQuestionService.getAnswer(
          getQuestion(objectivesData, subQuestion.QuestionId),
        ),
      }),
    );

    this.setOtherAnswer(nestedQuestion.SubQuestion);
    return nestedQuestion.SubQuestion;
  }

  setSubQuestionStructure(question) {
    const isValidQuestion =
      question.QuestionId === LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE;
    if (!isValidQuestion) {
      return;
    }

    question.optionalQuestion = question.answerList.map((item) => ({
      ...item,
      optionList: getUpdatedOptions(
        this.objectivesQuestionService.objectivesOption,
        this.dynamicQuestionHelper.getOptionQuestionId(
          item.value,
          question.QuestionId,
        ),
      ),
      subQuestion: this.getNestedSubQuestion(
        question,
        this.dynamicQuestionHelper.getOptionQuestionId(
          item.value,
          question.QuestionId,
        ),
      ),
    }));
  }

  updateNestedQuestion(question) {
    const data = {
      ...question,
      answerType: ANSWER_TYPE.ANSWER_STRING,
      answerValue: question.answerValue,
    };
    this.updateObjectives(data, true);
  }

  getFeaturePreferenceQuestion(question) {
    const isRatePreference =
      question.QuestionId === LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE;
    const { objectivesData } = this.objectivesQuestionService;

    if (!isRatePreference) {
      return;
    }

    question.answerList = this.appendQuestionData({
      question,
      questionList: this.questionList,
      answerList: question.answerList,
    });

    this.specialFeatureImportant = question.answerList.map((item) => ({
      ...item,
      answerType: this.objectivesQuestionService.getQuestionOptionType(
        item.QuestionDisplayTypeId,
      ),
      optionList: getUpdatedOptions(
        this.objectivesQuestionService.objectivesOption,
        this.dynamicQuestionHelper.getOptionQuestionId(
          item.value,
          question.QuestionId,
        ),
      ),
      answerList: this.getGenericAnswerList(item),
      other: this.getOtherFeatureQuestion(
        getQuestion(
          objectivesData,
          this.dynamicQuestionHelper.getOptionQuestionId(
            item.value,
            question.QuestionId,
          ),
        ),
      ),
    }));
  }

  getOtherFeatureQuestion(question) {
    if (!question) {
      return;
    }

    const questionId = question.QuestionId;
    const { objectivesData } = this.objectivesQuestionService;

    switch (questionId) {
      case LIST_OF_QUESTIONS.REDRAW:
        return {
          ...getQuestion(this.questionList, LIST_OF_QUESTIONS.REDRAW_OTHER),
          answerValue: this.objectivesQuestionService.getAnswer(
            getQuestion(objectivesData, LIST_OF_QUESTIONS.REDRAW_OTHER),
          ),
        };
      case LIST_OF_QUESTIONS.OFFSET_ACCOUNT:
        return {
          ...getQuestion(this.questionList, LIST_OF_QUESTIONS.OFFSET_OTHER),
          answerValue: this.objectivesQuestionService.getAnswer(
            getQuestion(objectivesData, LIST_OF_QUESTIONS.OFFSET_OTHER),
          ),
        };
      default:
    }
    return null;
  }

  setOtherAnswer(question) {
    const { objectivesData } = this.objectivesQuestionService;

    question.forEach((item) => {
      if (!item.SubQuestion) {
        return;
      }
      item.SubQuestion = item.SubQuestion.map((subQuestion) => ({
        ...subQuestion,
        answerValue: this.objectivesQuestionService.getAnswer(
          getQuestion(objectivesData, subQuestion.QuestionId),
        ),
      }));
    });
  }

  updateFeatureQuestion(question, item) {
    const subquestion = getQuestion(
      question.SubQuestion,
      this.dynamicQuestionHelper.getOptionQuestionId(
        item.value,
        question.QuestionId,
      ),
    );
    const data = {
      ...subquestion,
      answerType: ANSWER_TYPE.ANSWER_STRING,
      answerValue: item.answerValue,
    };

    this.updateObjectives(data, true);
  }

  structureMultipleAnswer({ answerObj, questionObj, isRemove }) {
    const isAnswerExists = questionObj.answerList.includes(answerObj);
    if (!isAnswerExists && !isRemove) {
      questionObj.answerList = [...questionObj.answerList, answerObj];
    }

    if (isAnswerExists && isRemove) {
      const answerIndex = questionObj.answerList.indexOf(answerObj);
      questionObj.answerList.splice(answerIndex, 1);

      deleteLoanObjectiveAnswer(this.loanObjectivesService)({
        loanAppId: this.loanId,
        answerInt: parseToInt10(answerObj.value),
        questionId: questionObj.QuestionId,
      });
      return;
    }

    const data = {
      ...questionObj,
      answerValue: parseToInt10(answerObj.value),
      answerInt: questionObj.answerList.map((item) => parseToInt10(item.value)),
    };
    this.updateObjectives(data, true);
  }

  addMultipleAnswer(item, subQuestion = false) {
    if (subQuestion) {
      this.questionInfo.SubQuestion.map((data) => {
        if (data.QuestionId === item.QuestionId) {
          if (!data.answerList.includes(item.answerValue)) {
            data.answerList.push(item.answerValue);
          }
          item.answerValue = parseToInt10(item.answerValue.value);
        }
        return data;
      });
    } else {
      if (!this.questionInfo.answerList.includes(item.answerValue)) {
        this.questionInfo.answerList.push(item.answerValue);
      }
      item.answerValue = parseToInt10(item.answerValue.value);
    }
    this.updateObjectives(item, subQuestion);
    this.setSubQuestionStructure(item);
    this.getFeaturePreferenceQuestion(item);
  }

  removeMultipleAnswer(question, item, subQuestion = false) {
    this.answerIndex = question.answerList.indexOf(item);
    if (question.answerList.includes(item)) {
      question.answerList.splice(this.answerIndex, 1);
    }
    if (!subQuestion) {
      question.answerValue = question.answerList.length || null;
    }

    deleteLoanObjectiveAnswer(this.loanObjectivesService)({
      loanAppId: this.loanId,
      answerInt: item.value,
      questionId: question.QuestionId,
    }).then(() => {
      this.removeCustomSubquestion(item);
      this.setSubQuestionStructure(question);
      this.getFeaturePreferenceQuestion(question);
    });
  }

  updateOtherField(item) {
    const sendData = this.objectivesQuestionService.setOtherFieldAnswer(
      {
        LoanScenarioId: this.loanId,
        FamilyId: this.familyId,
        QuestionSectionId: 0,
      },
      item,
    );

    if (!sendData.QuestionId) {
      return;
    }
    this.loanApplicationServices.postLoanObjectives(sendData);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setQuestionStatus() {
    this.isQuestionDone = this.objectivesQuestionService.checkQuestionIsDone(
      this.questionInfo,
      this.showAssociatedRiskExplanation(),
    );
    const isYesAnswer = this.questionInfo.answerValue === true;
    if (
      this.questionInfo.QuestionId ===
        LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES &&
      isYesAnswer
    ) {
      const isAnsweredSubFeature =
        this.specialFeatureImportant &&
        this.specialFeatureImportant.every((item) => {
          const hasOptions = item.answerList && item.answerList.length;
          const isFilledOptional =
            !hasOtherQuestion(item) ||
            !!isValidObjectValue(() => item.other.answerValue);
          return hasOptions && isFilledOptional;
        });
      this.isQuestionDone = this.isQuestionDone && !!isAnsweredSubFeature;
    } else if (
      this.questionInfo.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_PREFERENCE &&
      isYesAnswer
    ) {
      if (!isValidObjectValue(() => this.questionInfo.SubQuestion.length)) {
        return;
      }

      const questionOptional = this.questionInfo.SubQuestion.find(
        (question) =>
          question.QuestionId === LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
      );

      const hasMoreSubQuestions = isValidObjectValue(
        () => questionOptional.optionalQuestion.length,
      );
      if (hasMoreSubQuestions) {
        this.setQuestionStatusByOtherAnswers(questionOptional);
      }
    }

    this.objectivesQuestionService.setFeaturePreferenceStatus(
      this.questionId,
      this.isQuestionDone,
    );
    const answerList = this.questionInfo && this.questionInfo.answerList;

    if (
      this.questionInfo.QuestionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
    ) {
      this.isWarning = !(answerList && answerList.length);
    }
    const isEvaluateWarning =
      this.isAU &&
      REQUIRED_REQUIREMENTS_AND_OBJECTIVES_AU.includes(
        this.questionInfo.QuestionId,
      );

    if (isEvaluateWarning) {
      this.isWarning = !this.isQuestionDone;
    }
    const data =
      this.questionInfo.QuestionId === LIST_OF_QUESTIONS.REASON_FOR_REFINANCE
        ? { reasons: answerList }
        : { questionId: this.questionId };
    this.updateQuestion(data);
  }

  setQuestionStatusByOtherAnswers(questionOptional) {
    const isAnsweredOthers = questionOptional.optionalQuestion.every((sub) => {
      const importantQuestion = sub.subQuestion.find(
        (obj) => obj.QuestionContent === COMMON_QUESTION_STRING.WHY_IMPORTANT,
      );
      const otherQuestion = importantQuestion.SubQuestion.find(
        (obj) => obj.QuestionContent === COMMON_QUESTION_STRING.OTHER,
      );
      return (
        !hasOtherQuestion(importantQuestion) || !!otherQuestion.answerValue
      );
    });
    this.isQuestionDone = this.isQuestionDone && !!isAnsweredOthers;
  }

  updateObjectives(item, subQuestion = false) {
    this.dynamicQuestionHelper.isAssociatedQuestion(item.QuestionId) &&
      this.processUntickRiskNotification();

    this.isQuestionDone = false;

    const sendData = this.objectivesQuestionService.setObjectivesAnswer(
      {
        LoanScenarioId: this.loanId,
        FamilyId: this.familyId,
        QuestionSectionId: 0,
        QuestionId: item.QuestionId,
        DisplayOptionId: item.QuestionDisplayTypeId,
      },
      item,
    );
    this.loanApplicationServices
      .postLoanObjectives(sendData)
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
        if (data && subQuestion) {
          this.questionInfo.SubQuestion = this.objectivesQuestionService.updateSubQuestion(
            item,
            this.questionInfo.SubQuestion,
            this.currentUserService,
          );
        }
        this.setQuestionStatus();
        this.getAssociatedRisksList(item);
        this.updateObjectivesData(item);
      })
      .catch(toastError);
  }

  updateCustomSubquestion(item) {
    const postData = {
      LoanApplicationId: this.loanId,
      QuestionId: this.questionId,
      ObjectiveListValue: item.value,
      HelpTextValue: item.details,
    };
    this.loanApplicationServices
      .postFundingDetailsObjectives(postData)
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
      });
  }

  removeCustomSubquestion(item) {
    if (!this.showCustomSubquestion()) {
      return;
    }
    this.loanApplicationServices
      .deleteFundingDetailsObjectives({
        fundingDetailsObjId: item.fundingRefinanceId,
      })
      .then((response) => {
        if (response && response.data) {
          this.getCustomSubquestion();
        }
      });
  }

  showAssociatedRiskExplanation() {
    return (
      this.questionInfo.hasAssociatedRisks &&
      this.associatedRisks.length &&
      this.questionInfo.answerValue
    );
  }

  hideSubQuestionForAssociatedRisks(question) {
    return (
      this.questionInfo.hasAssociatedRisks &&
      question.QuestionDisplayTypeId === DISPLAY_OPTIONS.CHECK_BOX
    );
  }

  getAssociatedRisksList(item) {
    this.loanApplicationServices
      .getAssociatedRisks(this.currentUserService)
      .then(({ data }) => {
        this.associatedRisksDetails = data;
        this.getAssociatedRisks(item);
      });
  }

  getAssociatedRisks(item) {
    if (!this.dynamicQuestionHelper.isAssociatedQuestion(item.QuestionId)) {
      return;
    }
    this.associatedRisks = [];
    if (item.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN) {
      if (!item.answerList || !item.answerList.length) {
        return;
      }
      item.answerList.forEach((answer) => {
        const result = this.associatedRisksDetails.find(
          (risk) =>
            risk.value === answer.value && risk.questionId === item.QuestionId,
        );
        if (result) {
          this.associatedRisks.push(result);
        }
      });
    } else {
      const result = this.associatedRisksDetails.find(
        (risk) =>
          risk.value === item.answerValue &&
          risk.questionId === item.QuestionId,
      );
      if (result) {
        this.associatedRisks.push(result);
      }
    }
  }
}
