import get from 'lodash/get';
import set from 'lodash/set';

export default class HelloPackDealCtrl {
  constructor(
    loanScenarioModelService,
    clientInfoService,
    helloPackService,
    viewOfFileModalService,
    contactSharedDataService,
    uiService,
    helloBookService,
    configService,
  ) {
    'ngInject';

    this.loanScenarioModelService = loanScenarioModelService;
    this.clientInfoService = clientInfoService;
    this.helloPackService = helloPackService;
    this.viewOfFileModalService = viewOfFileModalService;
    this.contactSharedDataService = contactSharedDataService;
    this.uiService = uiService;
    this.helloBookService = helloBookService;
    this.configService = configService;
  }

  $onInit() {
    this.oneTouchPrivacy = this.configService.feature.oneTouchPrivacy;
    this.errorDeal = false;
    this.loanLabel = this.uiService.viewOfAfileTurnedOn
      ? 'deal'
      : 'loan application';
    this.excludedClient = {};
    this.helloPackService.handler.getClientExcludedInDeal = () => {
      this.getClientExcludedInDeal();
    };
    if (this.handler) {
      this.handler.onClientChange = () => {
        this.getClientExcludedInDeal();
      };
    }
    set(this, 'model.dealViewDisabled', true);
    this.dealOptionHandler = {
      addDeal: () => {},
      fetchingCallback: () => {},
    };
  }

  getInvolvedParties({ loanApplicationId, loanApplicationTitle, isEmptyLoan }) {
    if (this.model.isFactFindValid && !this.oneTouchPrivacy) {
      set(this, 'model.isFactFindValid', !!loanApplicationId);
    }
    set(this, 'model.loanApplicationId', loanApplicationId);
    this.errorDeal = !this.oneTouchPrivacy && !loanApplicationId;
    if (isEmptyLoan) {
      this.helloPackService.handler.skipDealView();
    }
    if (!loanApplicationId) {
      this.model.dealViewDisabled = typeof loanApplicationId === 'undefined';
      this.model.loanId = 0;
      return;
    }
    this.helloPackService.handler.setHelloPackLoanId(loanApplicationId);
    return this.helloPackService
      .getHelloPackApplicants(loanApplicationId)
      .then((involvedParties) => {
        const personIds = involvedParties.reduce((accum, family) => {
          return accum.concat(family.personIds);
        }, []);
        this.includedClientPersonIds = personIds;
        this.selectedLoanApplicationTitle = loanApplicationTitle;
        this.getClientExcludedInDeal();
        this.oneTouchPrivacy && this.getPrivacyConsents();
      })
      .finally(() => {
        this.dealOptionHandler.fetchingCallback();
      });
  }

  getClientExcludedInDeal() {
    if (!this.includedClientPersonIds) {
      return;
    }
    this.excludedClient = get(this, 'model.clients', []).reduce(
      (accum, client) => {
        const isIncluded = this.includedClientPersonIds.includes(
          client.personId,
        );
        if (isIncluded) {
          return accum;
        }
        if (accum.clientDisplayName) {
          accum.clientDisplayName = `${accum.clientDisplayName} and ${client.displayName}`;
        } else {
          accum.clientDisplayName = client.displayName;
        }
        accum.clients = [...accum.clients, client];
        return accum;
      },
      {
        clientDisplayName: '',
        clients: [],
      },
    );
    set(this, 'model.excludedClients', this.excludedClient.clients);
    this.setDealDescriptions();
  }

  setDealDescriptions() {
    const clientCount = get(this, 'excludedClient.clients.length', 0);
    const isPlural = clientCount > 1;
    this.involvePartyDescription = `${this.excludedClient.clientDisplayName} ${
      isPlural ? 'are' : 'is'
    } currently not an ${isPlural ? 'applicants' : 'applicant'} on ‘${
      this.selectedLoanApplicationTitle || 'Deal'
    }’`;
    this.involvePartyLabel = `Add ${
      this.excludedClient.clientDisplayName
    } to ‘${this.selectedLoanApplicationTitle || 'Deal'}’`;
    this.isSelectedParty = true;
    this.onCheckClient(this.isSelectedParty);
  }

  onCheckClient(value) {
    set(this, 'model.dealViewDisabled', !value);
  }

  onCreateNewDeal() {
    const personIds = get(this, 'model.clients', []).map(
      (client) => client.personId,
    );
    const isClient = !get(this, 'model.clients[0].isEntity', false);
    const params = {
      familyId: this.familyId,
      allocatedAdviserId: this.model.adviserId,
      personIds,
      isClient,
      disableRouting: true,
    };
    this.viewOfFileModalService
      .launchCreateNewFile(params)
      .then(({ loanApplicationId: loanScenarioId, fileName: dealName }) => {
        this.dealOptionHandler.addDeal({
          loanScenarioId,
          dealName,
        });
        this.contactSharedDataService.reloadContactLending &&
          this.contactSharedDataService.reloadContactLending();
        this.contactSharedDataService.reloadContactLoanTimeline &&
          this.contactSharedDataService.reloadContactLoanTimeline();
      });
  }
}
