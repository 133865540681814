import {
  PIPELINE_TYPES_STR,
  PIPELINE_VIEW_TYPES,
  KANBAN_OPTIONS,
  PIPELINE_SETTINGS_CATEGORY,
  PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS,
} from 'Common/constants/pipelineType';
import { CONVERSION_PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { displayError } from 'Common/utilities/alert';
import { deletePipelineItemService } from 'Common/utilities/pipeline';
import {
  PipelineLeadSettingsService,
  PipelineOpportunitySettingsService,
  PipelineApplicationSettingsService,
} from 'Common/services/pipelineSettingsService';
import {
  pipelineSettingsBuilderForUI,
  pipelineSettingsBuilderForAPI,
} from 'Common/mappers/pipeline';
import { adviserAssistantsBuilderForUI } from 'Common/mappers/options';
import {
  goToOpportunity,
  changeLoanProbability,
  changeLoanSubStatus,
} from 'Common/utilities/loanOpportunity';
import { objectLength } from 'Common/utilities/objectValidation';
import { COLOR } from 'Common/constants/colors';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import { applicationCardMap } from 'Common/utilities/kanbanHelper';
import { pipelineApplicationTableMap } from 'Common/utilities/pipelineTable';
import { parseToInt10 } from 'Common/utilities/parse';
import { PROBABILITIES } from 'Common/constants/probabilities';
import { PIPELINE_FILTER_TYPES } from 'Common/constants/pipelineFilterTypes';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import swal from 'sweetalert';
import toastr from 'toastr';
import moment from 'moment';
import { removeHistorySortForColumnName } from './util/pipelineCtrl';

const pipelineStats = {
  displayPrevious: false,
  barCap: 3,
  trackWidth: 3,
  barWidth: 3,
  size: 36,
  readOnly: true,
  trackColor: 'transparent',
  unit: '%',
};

const loanInformationModalConvertionStatus = [
  LOAN_STATUS.EXISTING.SETTLED,
  LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
];

const DEFAULT_ASSISTANT = {
  assistantId: 0,
  assistantName: 'All',
};

export default class PipelineCtrl {
  constructor(
    opportunityNewModalService,
    loanScenarioModelService,
    loanApplicationServices,
    cardRedirectionsService,
    viewOfFileModalService,
    loanOpportunityService,
    nextGenLoanAppService,
    loanFinancialsService,
    enquirySourceService,
    loanScenarioService,
    mycrmLookupService,
    pipelineSharedData,
    modalRenderService,
    currentUserService,
    crmConfirmation,
    pipelineService,
    contactService,
    optionsService,
    generalService,
    $localStorage,
    configService,
    $stateParams,
    stateService,
    noteService,
    $uibModal,
    uiService,
    $window,
    $state,
    $filter,
    $sce,
    $q,
  ) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.loanApplicationServices = loanApplicationServices;
    this.cardRedirectionsService = cardRedirectionsService;
    this.viewOfFileModalService = viewOfFileModalService;
    this.loanOpportunityService = loanOpportunityService;
    this.loanFinancialsService = loanFinancialsService;
    this.nextGenLoanAppService = nextGenLoanAppService;
    this.enquirySourceService = enquirySourceService;
    this.loanScenarioService = loanScenarioService;
    this.mycrmLookupService = mycrmLookupService;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.currentUserService = currentUserService;
    this.pipelineService = pipelineService;
    this.crmConfirmation = crmConfirmation;
    this.contactService = contactService;
    this.optionsService = optionsService;
    this.generalService = generalService;
    this.$localStorage = $localStorage;
    this.configService = configService;
    this.$stateParams = $stateParams;
    this.stateService = stateService;
    this.noteService = noteService;
    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.$window = $window;
    this.$state = $state;
    this.$filter = $filter;
    this.$sce = $sce;
    this.$q = $q;

    this.getPipelineStats = this.getPipelineStats.bind(this);
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.checkRoute();
    this.additionalLoanImportantDatesFlag = this.configService.feature.additionalLoanImportantDates;
    this.showAssistantFilter = this.configService.feature.loanAllocatedAssistant;
    const isApplication = this.pipelineType === PIPELINE_TYPES_STR.APPLICATION;
    const { pipelineV2P1 } = this.configService.feature;
    this.pipelineV2P1 = pipelineV2P1 && isApplication;

    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.PIPELINE_VIEW_TYPES = PIPELINE_VIEW_TYPES;
    this.KANBAN_OPTIONS = KANBAN_OPTIONS;
    this.pageSource = PAGE_SOURCE.LOAN_PIPELINE;

    const { pipelineType } = this.$stateParams;
    this.pipelineType = pipelineType;

    this.userInfo = {};
    this.kanbanOption = KANBAN_OPTIONS[0];
    this.visitedViews = {
      table: false,
      kanban: false,
    };
    this.isCorporateUser = this.currentUserService.isCorporate;
    this.isNzCorp = this.currentUserService.isNzCorp;
    this.getPipelineFilterSettings();
    this.getPipelineStats();
    this.getPipelineLabelsSettings();
    this.getAdviserList();
    this.getReferrerList();
    this.assistantAdviserList = [DEFAULT_ASSISTANT];

    this.childComponentEventHandler = {
      tableEventHandler: null,
      kanbanEventHandler: null,
    };

    if (
      this.$stateParams.createdContact !== '' &&
      !this.$localStorage.pipelineCardSatusName
    ) {
      this.openAddCard(this.$stateParams.createdContact);
    }

    this.probabilityList = [
      {
        NAME: 'All',
      },
      ...PROBABILITIES,
    ];

    this.mobileFilterTypes =
      (this.pipelineType &&
        PIPELINE_FILTER_TYPES[this.pipelineType.toUpperCase()].map((filter) => {
          const {
            FILTER_KEY: key,
            FILTER_TITLE: title,
            DEFAULT_VALUE: defaultValue,
            IS_SELECTED: isSelected,
          } = filter;
          return {
            key,
            title,
            defaultValue,
            isSelected,
          };
        })) ||
      [];
  }

  $onChanges(changes) {
    const { isSmallDevice } = changes;
    if (isSmallDevice && this.settings) {
      this.setMobileViewSettings();
    }
  }

  checkRoute() {
    const { pipelineType } = this.$stateParams;
    const pipelineTypes = Object.keys(PIPELINE_TYPES_STR).map((key) => {
      return String(PIPELINE_TYPES_STR[key]);
    });
    const isPipelineTypeValid = pipelineTypes.includes(pipelineType);
    if (!isPipelineTypeValid) {
      this.$state.go('app.dashboard');
      return;
    }
    this.pipelineType = pipelineType;

    if (this.uiService.newDealFeature) {
      this.addCardText = 'New deal';
      return;
    }

    if (this.pipelineType === PIPELINE_TYPES_STR.APPLICATION) {
      this.addCardText = 'New Loan Application';
    } else {
      this.addCardText = 'Add Card';
    }
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getStatuses() {
    if (this.statusListRadio) {
      return this.$q.resolve({
        data: this.statusListRadio,
      });
    }

    return this.pipelineService
      .SettingsStatusGet(false)
      .then(
        ({ data }) =>
          (data &&
            data.filter(
              (status) =>
                status.CategoryName ===
                  PIPELINE_SETTINGS_CATEGORY[this.pipelineType.toUpperCase()] ||
                !status.PipelineCategoryID,
            )) ||
          [],
      )
      .then((statusList) => {
        const statusGroup = [];
        const otherStatusGroup = [];
        statusList.forEach((status) => {
          const isExcludeSettledInOpportunity =
            this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY &&
            status.PipelineStatusID === CONVERSION_PIPELINE_STATUS.TO_SETTLED;
          if (!status.PipelineCategoryID && isExcludeSettledInOpportunity) {
            return;
          }

          if (!status.PipelineCategoryID) {
            otherStatusGroup.push(status);
          } else {
            statusGroup.push(status);
          }
        });

        this.statusListRadio = [[...statusGroup], [...otherStatusGroup]];
        this.statusGroup = [...statusGroup];
        const substatusDropdownList = statusGroup.reduce((accum, current) => {
          const { PipelineStatus, PipelineStatusID, SubStatus } = current;
          const mappedSubStatus =
            (SubStatus &&
              SubStatus.map((o) => {
                o.PipelineStatus = PipelineStatus;
                o.PipelineStatusID = PipelineStatusID;
                return o;
              })) ||
            [];
          const subStatusList = [...mappedSubStatus];

          return accum.concat(subStatusList);
        }, []);
        const allSubStatusOption = {
          pipelineSubStatusID: 0,
          SubStatusName: 'All',
          PipelineStatus: 'All',
        };
        this.substatusDropdownList = [
          allSubStatusOption,
          ...substatusDropdownList,
        ];
        this.otherStatusGroup = [...otherStatusGroup];

        const listOfstatusIds = this.settings.statusIds
          ? this.settings.statusIds.split(',').map((id) => parseInt(id, 10))
          : [];
        const listOfConversionStatusIds = this.settings.conversionStatusIDs
          ? this.settings.conversionStatusIDs
              .split(',')
              .map((id) => parseInt(id, 10))
          : [];

        this.settings.pipelineStatusIDs = [];
        statusGroup &&
          statusGroup.forEach((obj) => {
            if (
              listOfstatusIds.includes(obj.PipelineStatusID) ||
              this.settings.isFetchAllPipelineStatusIDs
            ) {
              this.settings.pipelineStatusIDs.push(obj);
            }
          });

        otherStatusGroup &&
          otherStatusGroup.forEach((obj) => {
            if (
              listOfConversionStatusIds.includes(obj.PipelineStatusID) ||
              this.settings.isFetchAllPipelineStatusIDs
            ) {
              this.settings.pipelineStatusIDs.push(obj);
            }
          });

        if (this.settings.isFetchAllPipelineStatusIDs) {
          this.settings.statusIds = statusGroup
            .map((obj) => obj.PipelineStatusID)
            .join(',');
          this.settings.conversionStatusIDs = otherStatusGroup
            .map((obj) => obj.PipelineStatusID)
            .join(',');
        }
      });
  }

  openAddCard(familyId = 0) {
    if (this.isNzCorp) {
      return;
    }
    if (this.uiService.newDealFeature) {
      return this.viewOfFileModalService.launchCreateNewFile({ familyId });
    }
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        this.$uibModal
          .open({
            templateUrl:
              '/assets/views/pipeline/leads/partials/add_new_card.html',
            resolve: {
              leadsStatusList: () => this.statusGroup || [],
              familyId: () => familyId,
              subtool: () => 'leads',
            },
            controller: 'OpenAddCardModalCtrl',
            size: 'modalWidthAuto',
          })
          .result.then(() => {
            const eventHandler = this.settings.isTableView
              ? 'tableEventHandler'
              : 'kanbanEventHandler';
            const handlerAction = this.settings.isTableView
              ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
              : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN;
            if (!this.childComponentEventHandler[eventHandler]) {
              return;
            }
            this.childComponentEventHandler[eventHandler](handlerAction, {});
          });
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.opportunityNewModalService.openNewOpportunityModal();
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.uiService.openAddNewLoanApplicationModal(true);
        break;
      default:
        break;
    }
  }

  onSwitchViewType(viewType, isUpdateSettings) {
    const { searchClientName } = this.settings || {};
    this.setPipelineState(searchClientName);
    this.switchViewType(viewType, isUpdateSettings);
  }

  switchViewType(viewType, isUpdateSettings = false) {
    this.settings.isTableView = viewType === PIPELINE_VIEW_TYPES.TABLE;
    if (isUpdateSettings) {
      if (this.settings.isFetchAllPipelineStatusIDs) {
        this.getStatuses().then(() => {
          this.settings.isFetchAllPipelineStatusIDs = 0;
          this.switchViewType(viewType, isUpdateSettings);
        });
        return;
      }

      this.pipelineService
        .setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings))
        .then(() => {
          this.isDesktopTableView = this.settings.isTableView;
          const isRefreshTable =
            this.settings.isTableView && this.visitedViews.table;
          const action = isRefreshTable
            ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
            : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
          this.changeSettings(
            {
              noFilterUpdate: true,
            },
            action,
          );
        });
    } else {
      const isRefreshTable =
        this.settings.isTableView && this.visitedViews.table;
      const action = isRefreshTable
        ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
        : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
      this.changeSettings(
        {
          noFilterUpdate: true,
        },
        action,
      );
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  closeWorkbenchLoanInformationModal(
    resultingLoanStatusId,
    pipelineStatusId,
    loanId,
    cardDetails,
  ) {
    const isConverted = loanInformationModalConvertionStatus.includes(
      parseInt(resultingLoanStatusId, 10),
    );
    const { loanScenarioID: loanScenarioId } = cardDetails;

    if (isConverted) {
      this.getPipelineStats();
      const eventHandler = this.settings.isTableView
        ? 'tableEventHandler'
        : 'kanbanEventHandler';
      const handlerAction =
        PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;

      if (!this.childComponentEventHandler[eventHandler]) {
        return;
      }
      this.childComponentEventHandler[eventHandler](handlerAction, {
        pipelineStatusId,
        loanId,
        loanScenarioId,
      });
      return;
    }

    this.pipelineService
      .getPipelineLoan(loanId, {
        isTable: this.settings.isTableView,
      })
      .then(({ data }) => {
        const mappedCardData = this.settings.isTableView
          ? pipelineApplicationTableMap(data, this.$filter)
          : applicationCardMap(data);
        const isStatusChanged =
          cardDetails.pipelineStatusID !== mappedCardData.pipelineStatusID;

        if (!cardDetails.loanScenarioID) {
          mappedCardData.loanScenarioID = null;
        }

        Object.keys(mappedCardData).forEach((prop) => {
          cardDetails[prop] = mappedCardData[prop];
        });

        cardDetails.pipelineStatusID = data.pipelineStatusID;

        if (!this.settings.isTableView) {
          const eventHandler = 'kanbanEventHandler';
          const handlerAction = isStatusChanged
            ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.MOVE_CARD
            : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TOTAL_AMOUNTS;

          if (!this.childComponentEventHandler[eventHandler]) {
            return;
          }
          this.childComponentEventHandler[eventHandler](handlerAction, {
            card: cardDetails,
            primaryKey: 'loanID',
            sourcePipelineStatusId: pipelineStatusId,
          });
        }
      });
  }

  openWorkbenchModal(cardDetails, event = null) {
    event && event.stopPropagation();
    if (!this.isSmallDevice) {
      const {
        loanID: loanId,
        pipelineStatusID: pipelineStatusId,
      } = cardDetails;

      this.$uibModal
        .open({
          templateUrl: 'assets/views/loanApplication/components/workbench.html',
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            cLoanId: () => loanId,
            CLoanStatusId: () => String(cardDetails.loanStatusID),
            CPipelineCardsID: () => cardDetails.pipelineCardsID,
            cLoanScenarioId: () => cardDetails.loanScenarioID,
            cFamilyId: () => cardDetails.familyInfo[0].familyId,
            cEnquirySource: () => cardDetails.enquirySource,
          },
          controller: 'WorkbenchModalCtrl',
          controllerAs: 'vm',
          windowTopClass: 'workbench-modal',
        })
        .result.then(
          () => {},
          ({ loanStatusId: resultingLoanStatusId }) =>
            this.closeWorkbenchLoanInformationModal(
              resultingLoanStatusId,
              pipelineStatusId,
              loanId,
              cardDetails,
            ),
        );
    } else {
      const params = {
        LoanID: cardDetails.loanID,
        LoanStatusID: String(cardDetails.loanStatusID),
        PipelineCardsID: cardDetails.pipelineCardsID,
        LoanScenarioID: cardDetails.loanScenarioID,
        familyId: cardDetails.familyInfo[0].familyId,
        enquirySource: cardDetails.enquirySource,
        clientNames: cardDetails.clients.trim(),
      };
      this.stateService.savePrevState(
        this.$state.$current.self,
        this.$state.$current.locals.globals.$stateParams,
      );
      this.$state.go('app.mobileWorkbench', {
        ...params,
      });
    }
  }

  getFamiliesPerLoan(
    cardDetails,
    key = 'loanID',
    openMenuModal = false,
    modalProps,
  ) {
    const { familyInfo } = cardDetails;
    const loanId = cardDetails[key];
    const hasFamilyInfoAndOpenModal =
      familyInfo && objectLength(familyInfo) && openMenuModal && modalProps;

    if (hasFamilyInfoAndOpenModal) {
      this.openThreeDotModal(cardDetails, modalProps);
    }
    if (familyInfo && objectLength(familyInfo)) {
      return;
    }
    return this.contactService
      .getFamiliesPerLoan(loanId)
      .then(({ succeeded, data }) => {
        if (!succeeded || !data) {
          return;
        }

        const { familyInfo: families } = data;
        const clients = families.filter(
          (family) => family.isClient && !family.isGuarantor,
        );
        const entities = families.filter((family) => !family.isClient);
        const guarantors =
          this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY
            ? families.filter((family) => family.isClient && family.isGuarantor)
            : [];

        cardDetails.familyInfo = [...clients, ...entities, ...guarantors];
        if (openMenuModal && modalProps) {
          this.openThreeDotModal(cardDetails, modalProps);
        }
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  clickPipelineItem(event, cardDetails, isMenuClicked, override = false) {
    event && event.stopPropagation();
    if (this.isSmallDevice && !isMenuClicked) {
      return;
    }
    const isPipelineOpportunity =
      this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
    const isPipelineApplication =
      this.pipelineType === PIPELINE_TYPES_STR.APPLICATION;

    if (isPipelineOpportunity || override) {
      this.viewOpportunityPage(cardDetails, override);
    } else if (isPipelineApplication) {
      return this.viewApplicationPage(cardDetails);
    }
  }

  viewApplicationPage(cardDetails) {
    if (!cardDetails.loanID || this.isNzCorp) {
      return;
    }

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      return this.getFamiliesPerLoan(cardDetails).then(() => {
        if (!cardDetails.loanScenarioID) {
          this.openLoanInformation(cardDetails);
        } else {
          this.openWorkbenchModal(cardDetails);
        }
      });
    }

    if (!cardDetails.loanScenarioID) {
      this.openLoanInformation(cardDetails);
    } else {
      this.openWorkbenchModal(cardDetails);
    }
  }

  viewOpportunityPage(cardDetails, override) {
    const firstFamilyId =
      this.uiService.viewOfAfileTurnedOn &&
      cardDetails.familyInfo &&
      cardDetails.familyInfo.length &&
      cardDetails.familyInfo[0].familyId;
    const {
      loanScenarioId: opportunityId,
      loanId,
      loanScenarioID,
      loanID,
    } = cardDetails;
    const params = {
      opportunityId: opportunityId || loanScenarioID,
      loanId: loanId || loanID,
      sourceFamilyId: firstFamilyId || 0,
      sourceFamilyName: '',
      isContactOpener: false,
      tabName: 'details',
      activeTab: override ? 'fact-find' : 'summary',
    };
    goToOpportunity(this.$state, params);
  }

  deletePipelineItem(
    event,
    loanId,
    pipelineCardsId,
    successCallback,
    successCallbackParams,
    loanDetails,
  ) {
    const deleteAction = () => {
      const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
      if (isLeads && !pipelineCardsId) {
        return;
      }

      const service = isLeads ? 'pipelineService' : 'contactService';
      const method = isLeads ? 'PipelineCardsDelete' : 'loanDelete';
      const args = isLeads
        ? {
            cardsID: pipelineCardsId,
          }
        : loanId;

      this[service][method](args)
        .then(() => {
          successCallback && successCallback(successCallbackParams);
        })
        .catch((error) => {
          displayError(error);
        })
        .finally(() => {
          swal({
            title: 'Success',
            text: `${
              isLeads ? 'Lead' : 'Loan'
            } has been been successfully deleted.`,
            type: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        });
    };

    const deletePipelineItemServiceParams = {
      event,
      loanDetails,
      swal,
      deleteAction,
      loanScenarioService: this.loanScenarioService,
      crmConfirmation: this.crmConfirmation,
    };

    deletePipelineItemService(deletePipelineItemServiceParams);
  }

  initializeFilterLookup(adviserId) {
    this.getEnquirySourceList();
    this.getAssignAssistantForAdviser(adviserId);
  }

  createNewSettings() {
    this.visitedViews.kanban = true;

    const { familyId: advisorId } = this.currentUserService;
    const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
    const isOpportunity = this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;

    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        this.settings = new PipelineLeadSettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          {
            pipelineService: this.pipelineService,
          },
        );
        this.getEnquirySourceList();
        this.changeSettings(
          {
            noFilterUpdate: true,
            isNzCorp: this.isNzCorp,
          },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
        );
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.settings = new PipelineOpportunitySettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          {
            pipelineService: this.pipelineService,
            isNzCorp: this.isNzCorp,
          },
        );
        this.getEnquirySourceList();
        this.changeSettings(
          {
            noFilterUpdate: true,
          },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
        );
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.settings = new PipelineApplicationSettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          {
            pipelineService: this.pipelineService,
          },
        );
        this.initializeFilterLookup(advisorId);
        this.changeSettings(
          {
            noFilterUpdate: true,
            isNzCorp: this.isNzCorp,
          },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
        );
        break;
      default:
        break;
    }

    this.isDesktopTableView = this.settings.isTableView;
    this.setMobileViewSettings();
  }

  getPipelineFilterSettings(callback, callbackParams = {}) {
    // Send a function on callback
    const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
    const isOpportunity = this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
    this.pipelineService
      .getPipelineCardFilters(isLeads, isOpportunity)
      .then((res) => {
        const { data } = res;
        if (!data || !data.length || !data[0] || !objectLength(data[0])) {
          this.createNewSettings();
          return;
        }

        const { IsTableView, AdvisorId } = data[0];
        this.visitedViews[IsTableView ? 'table' : 'kanban'] = true;
        this.selectedView = !IsTableView;

        switch (this.pipelineType) {
          case PIPELINE_TYPES_STR.LEADS:
            this.settings = new PipelineLeadSettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              {
                pipelineService: this.pipelineService,
              },
            );
            this.settings.searchClientName = this.$stateParams.search || '';
            this.getEnquirySourceList();
            this.changeSettings(
              {
                isNzCorp: this.isNzCorp,
                noFilterUpdate: true,
              },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
            );
            break;
          case PIPELINE_TYPES_STR.OPPORTUNITY:
            this.settings = new PipelineOpportunitySettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              {
                pipelineService: this.pipelineService,
              },
            );
            this.getEnquirySourceList();
            this.changeSettings(
              {
                isNzCorp: this.isNzCorp,
                noFilterUpdate: true,
              },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
            );
            break;
          case PIPELINE_TYPES_STR.APPLICATION:
            this.settings = new PipelineApplicationSettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              {
                pipelineService: this.pipelineService,
              },
            );
            this.initializeFilterLookup(AdvisorId);
            this.changeSettings(
              {
                isNzCorp: this.isNzCorp,
                noFilterUpdate: true,
              },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD,
            );
            break;
          default:
            break;
        }
        if (!this.settings) {
          return;
        }
        this.settings.searchClientName = this.$stateParams.search || '';
      })
      .then(() => {
        this.getStatuses();
        this.lastSortColumn = this.settings.sortColumn;
        this.isDesktopTableView = this.settings.isTableView;
        if (typeof callback === 'function') {
          callback(callbackParams);
        }
        this.setMobileViewSettings();
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setMobileViewSettings() {
    if (this.isSmallDevice || !this.isDesktopTableView) {
      this.switchViewType(PIPELINE_VIEW_TYPES.KANBAN);

      if (
        this.isMobileInstructionsOpened ||
        (this.videos && this.videos.length)
      ) {
        return;
      }

      if (this.isSmallDevice) {
        this.generalService.getTickableItems().then((tickableItems) => {
          const pipelineTickableItem =
            (tickableItems &&
              tickableItems.find(
                (item) => item.id === TICKABLE_ITEMS.PIPELINE_MOBILE_VIEW,
              )) ||
            {};
          this.videos =
            (pipelineTickableItem.attachments &&
              pipelineTickableItem.attachments.map((attachment) => {
                attachment.source = this.$sce.trustAsResourceUrl(
                  attachment.source,
                );
                return attachment;
              })) ||
            [];

          if (pipelineTickableItem.isTicked) {
            return;
          }
          this.mobileInstructionsModal();
        });
      }
    } else {
      this.switchViewType(PIPELINE_VIEW_TYPES.TABLE);
    }
  }

  mobileInstructionsModal() {
    if (this.isMobileInstructionsOpened) {
      return;
    }

    const props = {
      videos: this.videos || [],
    };
    this.$uibModal
      .open({
        template: `<pipeline-mobile-demo-modal
        modal-instance="vm.modalInstance"
        videos="vm.props.videos"
      ></pipeline-mobile-demo-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
        size: 'sm',
        windowClass: 'pipeline-mobile-demo-modal',
        keyboard: false,
        backdrop: 'static',
      })
      .result.then(
        () => {
          this.isMobileInstructionsOpened = false;
        },
        () => {
          this.isMobileInstructionsOpened = false;
        },
      );

    this.isMobileInstructionsOpened = true;
  }

  changeStatusSettings(values = []) {
    const statusGroupList = [];
    const otherStatusGroupList = [];
    values &&
      values.forEach((value) => {
        const isStatusGroupFound = this.statusGroup.find(
          (status) =>
            status.PipelineStatusID === value.PipelineStatusID &&
            status.PipelineCategoryID === value.PipelineCategoryID,
        );
        if (isStatusGroupFound) {
          statusGroupList.push(value.PipelineStatusID);
        }

        const isOtherStatusGroupFound = this.otherStatusGroup.find(
          (status) =>
            status.PipelineStatusID === value.PipelineStatusID &&
            status.PipelineCategoryID === value.PipelineCategoryID,
        );
        if (isOtherStatusGroupFound) {
          otherStatusGroupList.push(value.PipelineStatusID);
        }
      });
    this.settings.pipelineStatusIDs = [...values];
    this.changeSettings(
      {
        statusIds: statusGroupList.join(','),
        conversionStatusIDs: otherStatusGroupList.join(','),
      },
      null,
      true,
    );
  }

  getAssignAssistantForAdviser(adviserId) {
    if (typeof adviserId === 'undefined') {
      return;
    }
    this.uiService.viewOfAfileTurnedOn && this.getEnquirySourceList(adviserId);
    this.optionsService.adviserAssistantsGet(adviserId).then(({ data }) => {
      const parsedData =
        (data && data.map(adviserAssistantsBuilderForUI)) || [];
      this.assistantAdviserList = [DEFAULT_ASSISTANT, ...parsedData];
      if (!this.settings.assistantId) {
        this.settings.assistantId = 0;
        return;
      }
      const selectedAssistant = this.assistantAdviserList.find(
        (assistant) => assistant.assistantId === this.settings.assistantId,
      );
      if (selectedAssistant && selectedAssistant.assistantId) {
        return;
      }
      this.settings.assistantId = 0;
    });
  }

  changeExcludeColumnsSettings(tableViewExcludedColumns) {
    const action =
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_EXCLUDE_COLUMN;
    this.changeSettings(
      {
        tableViewExcludedColumns,
      },
      action,
      true,
    );
  }

  setPipelineState(search) {
    if (typeof search === 'undefined') {
      return;
    }
    this.$stateParams.search = search;
    this.$stateParams.page = 1;
    this.stateService.transition(this.$state.current, this.$stateParams);
  }

  changeSettings(changes, action, triggerChildNotification = true) {
    Object.keys(changes).forEach((prop) => {
      this.settings[prop] = changes[prop];
    });
    this.setPipelineState(changes.searchClientName);
    this.countMobileActiveFilters();
    this.getAssignAssistantForAdviser(changes.advisorId);
    this.settings.isTableView ||
      this.pipelineType === PIPELINE_TYPES_STR.LEADS ||
      (this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY &&
        this.pipelineSharedData.isMergeLeadAndOpportunities &&
        this.getStatuses());

    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';

    const handlerAction =
      action ||
      (this.settings.isTableView
        ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
        : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN);

    if (!this.childComponentEventHandler[eventHandler]) {
      return;
    }

    if (this.settings.noFilterUpdate) {
      this.settings.noFilterUpdate = false;
      triggerChildNotification &&
        this.childComponentEventHandler[eventHandler](
          handlerAction,
          this.settings,
        );
    } else {
      this.settings = removeHistorySortForColumnName(this.settings);
      this.pipelineService
        .setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings))
        .then(() => {
          triggerChildNotification &&
            this.childComponentEventHandler[eventHandler](
              handlerAction,
              this.settings,
            );
        });
    }
  }

  refreshData() {
    this.changeSettings(
      {
        noFilterUpdate: true,
      },
      null,
      true,
    );
    this.toggleShowLoader(true);
  }

  toggleShowLoader(bool) {
    this.showLoader = bool;
  }

  openModalForDemoVideo() {
    const controller =
      this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
        ? 'PipelineAppDemoVideoModalCtrl'
        : 'ContactOpenModalForDemoVideoCtrl';
    const size = 'lg';
    this.$uibModal.open({
      templateUrl: '/assets/views/partials/demo_video_modal.html',
      controller,
      size,
      windowClass: 'demo-video-modal-window-class',
    });
  }

  getPipelineStats() {
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS: {
        const fromDate = moment().subtract(12, 'months').format('DD MMM YYYY');
        const toDate = moment().format('DD MMM YYYY');
        this.pipelineService
          .getPipelineCardsSummary(fromDate, toDate, 0)
          .then(({ data }) => {
            const { Successful, UnSuccessfulPercent } = data;
            this.pipelineStats = {
              convertedToSettled: {
                ...pipelineStats,
                barColor: COLOR.BRIGHT_TURQUOISE_DARKER,
                textColor: COLOR.DARKER_BLUE,
                displayValue: Math.round(Successful),
                value: Successful,
              },
              convertedToNotProceeding: {
                ...pipelineStats,
                barColor: COLOR.BLUE_VIKING,
                textColor: COLOR.BLUE_VIKING,
                displayValue: Math.round(UnSuccessfulPercent),
                value: UnSuccessfulPercent,
              },
            };
          });
        break;
      }
      case PIPELINE_TYPES_STR.APPLICATION:
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.pipelineService
          .getPipelineStats(this.pipelineType)
          .then(({ data }) => {
            const { successful, unSuccessfulPercent } = data;
            this.pipelineStats = {
              convertedToSettled: {
                ...pipelineStats,
                barColor: COLOR.BRIGHT_TURQUOISE_DARKER,
                textColor: COLOR.DARKER_BLUE,
                displayValue: Math.round(successful),
                value: successful,
              },
              convertedToNotProceeding: {
                ...pipelineStats,
                barColor: COLOR.BLUE_VIKING,
                textColor: COLOR.BLUE_VIKING,
                displayValue: Math.round(unSuccessfulPercent),
                value: unSuccessfulPercent,
              },
            };
          });
        break;
      default:
        break;
    }
  }

  getLendingCategories() {
    this.optionsService.getLendingCategories().then((response) => {
      if (!response) {
        return;
      }
      this.lendingCategories = response;
      const data = this.lendingCategories.map((item) => {
        return {
          labelName: item.categoryName,
          pipelineSettingsLabelID: -item.categoryId,
        };
      });
      this.newlabelSettingsList = [...this.labelsSettings, ...data];
    });
  }

  getPipelineLabelsSettings(refreshParams = null) {
    this.pipelineService.PipelineSettingsLabel().then(({ data }) => {
      this.labelsSettings = collectionCamelizeBuilderForUI(data);
      this.newlabelSettingsList = [...this.labelsSettings];
      this.threeDotModalNotificator('refresh-labels');
      refreshParams &&
        refreshParams.updatedLabel &&
        refreshParams.updatedLabel.labelId &&
        this.refreshCardLabels(refreshParams);
      this.getLendingCategories();
    });
  }

  refreshCardLabels(refreshParams) {
    const { updatedLabel, action } = refreshParams;
    if (!updatedLabel || !action || action === 'add') {
      return;
    }
    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';
    const handlerAction =
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_LABELS;

    if (!this.childComponentEventHandler[eventHandler]) {
      return;
    }
    this.childComponentEventHandler[eventHandler](handlerAction, refreshParams);
  }

  refreshView() {
    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';
    const handlerAction = this.settings.isTableView
      ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
      : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN;
    if (!this.childComponentEventHandler[eventHandler]) {
      return;
    }
    this.childComponentEventHandler[eventHandler](handlerAction);
  }

  getAdviserList() {
    const params = { includeInactive: true, allAdviserLabel: 'All' };
    this.mycrmLookupService.getAdvisers(params).then((advisersList) => {
      this.advisersList = advisersList;
    });
  }

  getEnquirySourceList(selectedAdviserId = 0) {
    let params = {
      showCorporateLeadOptions: true,
    };
    if (this.uiService.viewOfAfileTurnedOn) {
      params = selectedAdviserId
        ? { ...params, selectedAdviserId }
        : { ...params, allAdvisersChosen: true };
    }
    this.optionsService.getEnquirySourceList(params).then(({ data }) => {
      const isValidResponse = data && data.length;
      if (!isValidResponse) {
        this.enquirySourceList = [];
        return;
      }
      const enquirySourceList = this.enquirySourceService.getEnquirySourceListData(
        data,
      );
      this.enquirySourceList = [
        {
          ReferralItemId: 0,
          ReferralItemName: 'All',
          ReferralCategoryId: 0,
          ReferralCategoryName: '',
        },
        ...enquirySourceList,
      ];

      const findEnquiryObject = this.enquirySourceList.find(
        (item) =>
          item.ReferralItemId === this.settings.referralItemID &&
          item.ReferralCategoryId === this.settings.referralCategoryID,
      );
      if (findEnquiryObject) {
        this.settings.enquiryObject = {
          ...findEnquiryObject,
        };
      }
      this.countMobileActiveFilters();
    });
  }

  getReferrerList() {
    this.optionsService.getReferencedReferralList().then(({ data }) => {
      const referrerList =
        data.map((o) => {
          o.ReferralMemberId = o.ReferralMemberId.toString();
          o.ReferralOrganizationName +=
            (o.ReferralOrganizat0ionName !== '' ? ' - ' : '') +
            o.ReferralMemberName;
          return o;
        }) || [];
      this.referrerList = [
        {
          ReferralMemberId: 'All',
          ReferralOrganizationName: 'All',
        },
        ...referrerList,
      ];
    });
  }

  showFilters() {
    this.isShowFilters = !this.isShowFilters;
  }

  redirectLoanApp(cardDetails) {
    const { loanScenarioID: loanAppId } = cardDetails;
    const familyId = cardDetails.familyInfo[0].familyId;
    this.$state.go('app.loanApplicationDetailsV3', {
      familyId,
      loanAppId,
    });
  }

  openLoanInformation(cardDetails) {
    const {
      loanID: loanId,
      pipelineStatusID: pipelineStatusId,
      loanScenarioID: loanAppId,
    } = cardDetails;
    const familyId = cardDetails.familyInfo[0].familyId;

    const params = {
      loanInformationAction: 'edit',
      familyId: parseInt(familyId, 10),
      loanId,
    };

    // @TODO refresh the card or row detail with the updated one
    this.$uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
        controller: 'LoanInformationCtrl',
        size: this.additionalLoanImportantDatesFlag ? 'wide' : 'lg',
        resolve: {
          params: () => params,
          loanType: () => 'npw',
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((result) => {
        const {
          loanStatusId: resultingLoanStatusId,
          originalLoanStatusId,
        } = result;

        const isStatusSetToSettled =
          parseToInt10(resultingLoanStatusId) !==
            parseToInt10(originalLoanStatusId) &&
          parseToInt10(resultingLoanStatusId) === LOAN_STATUS.EXISTING.SETTLED;

        if (isStatusSetToSettled) {
          this.loanFinancialsService.showAutoCreatedFinancialsOnSettlement({
            params: {
              familyId,
              loanAppId,
            },
          });
        }

        this.closeWorkbenchLoanInformationModal(
          resultingLoanStatusId,
          pipelineStatusId,
          loanId,
          cardDetails,
        );
      });
  }

  viewLoanInformation(event, cardDetails) {
    event && event.stopPropagation();
    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.openLoanInformation(cardDetails);
      });
    } else {
      this.openLoanInformation(cardDetails);
    }
  }

  viewLoanApp(event, cardDetails) {
    event && event.stopPropagation();
    if (this.uiService.viewOfAfileTurnedOn) {
      return this.clickPipelineItem(
        event,
        cardDetails,
        true,
        this.uiService.viewOfAfileTurnedOn,
      );
    }

    if (this.isNzCorp) {
      return;
    }
    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.redirectLoanApp(cardDetails);
      });
    } else {
      this.redirectLoanApp(cardDetails);
    }
  }

  viewBorrower(event, family) {
    event && event.stopPropagation();
    if (this.isNzCorp) {
      return;
    }
    const { familyId, isClient } = family;
    if (isClient) {
      this.$state.go('app.contactsSingle', {
        familyId,
        activeTab: 'lending',
      });
    } else {
      this.$state.go('app.BusinessAccount', {
        familyId,
        activeTab: 'loans',
      });
    }
  }

  viewSubmission(event, cardDetails) {
    event && event.stopPropagation();

    const { loanScenarioID: LoanScenarioID, loanID: LoanID } = cardDetails;

    this.nextGenLoanAppService
      .nextGenAOLView({
        LoanScenarioID,
        LoanID,
      })
      .then(({ data }) => {
        const nextGenObj = data;
        nextGenObj.https = this.$sce.trustAsResourceUrl(nextGenObj.https);
        const guid = nextGenObj.GUID || '';
        const startPage = nextGenObj.StartPage || '';
        const applicationViewURL = `${nextGenObj.https}?GUID=${guid}&StartPage=${startPage}`;
        this.$window.open(applicationViewURL);
      });
  }

  duplicateLoan(cardDetails) {
    const familyId = cardDetails.familyInfo[0].familyId;
    const { loanScenarioID } = cardDetails;
    this.loanApplicationServices.cloneLoan(loanScenarioID, familyId).then(
      ({ data: loanAppId }) => {
        cardDetails.loansSectionLoadingStatus = false;
        this.$state.go('app.loanApplicationDetailsV3', {
          familyId,
          loanAppId,
        });
      },
      () => {
        cardDetails.loansSectionLoadingStatus = false;
      },
    );
  }

  onDuplicateLoan(event, cardDetails) {
    event && event.stopPropagation();

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.duplicateLoan(cardDetails);
      });
    } else {
      this.duplicateLoan(cardDetails);
    }
  }

  changeProbability({ probability, card }) {
    if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
      changeLoanProbability({
        probability,
        card,
        loanScenarioModelService: this.loanScenarioModelService,
      });
      return;
    }

    card.probability = probability;
    const args = {
      cardID: card.pipelineCardsID || card.pipelineCardsId,
      probability,
    };
    this.pipelineService.SetPipelineCardsProbability(args).then(({ data }) => {
      if (data) {
        toastr.success('Probability Successfully Saved!', 'Success');
      }
    });
  }

  changeSubStatus({ subStatus, card, showNotification = true }) {
    if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
      changeLoanSubStatus({
        subStatus,
        card,
        showNotification,
        loanScenarioModelService: this.loanScenarioModelService,
      });
      return;
    }

    card.pipelineStatusID = subStatus.StatusID;
    card.subStatusID = subStatus.pipelineSubStatusID;
    card.subStatusName = subStatus.SubStatusName;
    const args = {
      CardsID: card.pipelineCardsID || card.pipelineCardsId,
      SubStatusID: subStatus.pipelineSubStatusID,
    };
    this.pipelineService.CardsSubStatusSet(args).then(({ data }) => {
      if (data && showNotification) {
        toastr.success('Substatus Successfully Saved!', 'Success');
      }
    });
  }

  openThreeDotModal(card, props) {
    if (!card || !props || this.isThreeDotModalOpen) {
      return;
    }

    props.card = card;
    props.labelsSettings = this.labelsSettings;
    props.childComponentNotificationRegistration = this.childComponentNotificationRegistration;

    this.isThreeDotModalOpen = true;
    this.$uibModal
      .open({
        template: `
        <three-dot-modal
          modal-instance="vm.modalInstance"
          card="vm.props.card"
          menu-type="vm.props.menuType"
          props="vm.props"
          parent-to-child-notification-registration="vm.props.childComponentNotificationRegistration(handler, 'threeDotModalEventHandler')"
        >
        </three-dot-modal>
      `,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        size: 'sm',
        windowClass: 'threedot-modal-window',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          props: () => props,
        },
      })
      .result.then(
        () => {
          this.isThreeDotModalOpen = false;
        },
        () => {
          this.isThreeDotModalOpen = false;
        },
      );
  }

  threeDotModalNotificator(action) {
    if (
      action === 'refresh-labels' &&
      this.childComponentEventHandler &&
      this.childComponentEventHandler.threeDotModalEventHandler
    ) {
      this.childComponentEventHandler.threeDotModalEventHandler(
        'refresh-labels-settings',
        this.labelsSettings,
      );
    }
  }

  openMobileFilter() {
    const props = {
      pipelineType: this.pipelineType,
      pipelineTypes: PIPELINE_TYPES_STR,
      settings: this.settings,
      substatusList: this.substatusDropdownList,
      advisersList: this.advisersList,
      assistantAdviserList: this.assistantAdviserList,
      showAssistantFilter: this.showAssistantFilter,
      labelsList: this.labelsSettings,
      probabilityList: this.probabilityList,
      enquirySourceList: this.enquirySourceList,
      referrerList: this.referrerList,
      isSmallDevice: this.isSmallDevice,
      changeSettings: this.changeSettings,
      isCorporateUser: this.isCorporateUser,
    };

    this.$uibModal
      .open({
        template: `<pipeline-filters-mobile-modal
        modal-instance="vm.modalInstance"
        pipeline-type="vm.props.pipelineType"
        pipeline-types="vm.props.pipelineTypes"
        is-corporate-user="vm.props.isCorporateUser"
        settings="vm.props.settings"
        is-small-device="vm.props.isSmallDevice"
        substatus-list="vm.props.substatusList"
        advisers-list="vm.props.advisersList"
        assistant-adviser-list="vm.props.assistantAdviserList"
        show-assistant-filter="vm.props.showAssistantFilter"
        labels-list="vm.props.labelsList"
        probability-list="vm.props.probabilityList"
        enquiry-source-list="vm.props.enquirySourceList"
        referrer-list="vm.props.referrerList"
        on-change-settings="vm.props.changeSettings($e, null, triggerChildNotification)"
      ></pipeline-filters-mobile-modal`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        style: 'sm',
        windowClass: 'pipeline-filters-mobile-modal',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          props: () => props,
        },
      })
      .result.then((response) => {
        if (!response || !response.changes) {
          return;
        }

        const { changes } = response;

        const pipelineHasEnquiry =
          this.pipelineType === PIPELINE_TYPES_STR.LEADS ||
          this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
        if (pipelineHasEnquiry) {
          const findEnquiryObject = this.enquirySourceList.find(
            (item) =>
              item.ReferralItemId === changes.referralItemID &&
              item.ReferralCategoryId === changes.referralCategoryID,
          );
          if (findEnquiryObject) {
            this.settings.enquiryObject = {
              ...findEnquiryObject,
            };
          }

          this.settings.dateCreatedStart = changes.dateCreatedStart;
          this.settings.dateCreatedEnd = changes.dateCreatedEnd;
          this.settings.displayFilterDate = this.settings.formatCreatedDateFilter();
        }

        this.changeSettings(
          changes,
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN,
        );
      });
  }

  countMobileActiveFilters() {
    const activeFilters =
      this.mobileFilterTypes.filter((filter) => {
        const { key } = filter;
        const value = this.settings[key];
        const testNumber = typeof value === 'number' && value > 0;
        const testString =
          typeof value === 'string' &&
          value.length &&
          value.toLowerCase() !== 'all' &&
          value.toLowerCase() !== 'none';
        const testEnquiry =
          key === 'enquiryObject' &&
          this.settings.enquiryObject &&
          this.settings.enquiryObject.ReferralCategoryId !== 0 &&
          this.settings.enquiryObject.ReferralItemId !== 0;
        return testNumber || testString || testEnquiry;
      }) || [];

    this.activeFiltersLength = activeFilters.length;
  }

  onViewToggleChange() {
    const { KANBAN, TABLE } = this.PIPELINE_VIEW_TYPES;
    const view = this.selectedView ? KANBAN : TABLE;
    this.settings.isTableView = view === TABLE;
    this.onSwitchViewType(view, true);
  }

  handleAddNote(data) {
    this.noteService.newOpportunityNotesModal(data);
  }

  getPipelineTitle() {
    return this.pipelineSharedData.isMergeLeadAndOpportunities &&
      this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY
      ? 'Leads Pipeline'
      : this.pipelineType;
  }
}
