import { setAllRoleLogics } from './uiRoleTypeLogics';

export default (() => {
  const configRolesAndTypes = (userRolesAndTypes) => {
    const cacheRoles = Object.create(userRolesAndTypes);

    return {
      aliasRolesAs(key) {
        return {
          withRoleValuesOf: (roleValue) => {
            cacheRoles[key] = roleValue;
            return this;
          },
        };
      },
      getTypeThat: (key) => cacheRoles[key],
    };
  };

  const rolesStartsWith = (role) => {
    const where = (query) => role.startsWith(query);
    return where('is') || where('org');
  };

  return {
    roleDefinition: ({ vm, feature, currentUserService = {} }) => {
      Promise.resolve(currentUserService).then((roles) => {
        const getRoles = Object.keys(roles)
          .filter((role) => {
            return rolesStartsWith(role);
          })
          .reduce((acc, cur) => {
            acc[cur] = roles[cur];
            return acc;
          }, {});

        const user = configRolesAndTypes(getRoles);
        setAllRoleLogics.addAliases({ vm, feature, user });
        Object.freeze(user);
      });
    },
  };
})();
