/* eslint-disable angular/window-service */
import { getApiResourceBasedOnLocation } from 'Common/utilities/windowLocation';
import { showBusinessPlanningDashboard } from 'Common/utilities/showFeature';
import { DEFAULT_DASHBOARD_TAB } from 'Common/constants/businessPlanningDashboard';

const configData = {};

export function setConfigData(data) {
  Object.assign(configData, data);
}

export function getConfigData() {
  return { ...configData };
}

export function getHashConfig() {
  // webpack define global variable
  return process.env.COMMIT_HASH || '';
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getConfigFromEnv() {
  return {
    resource: getApiResourceBasedOnLocation(),
    login: process.env.LOGIN || `${window.location.origin}/login/login.html`,
    oktaLogin: process.env.LOGIN_OKTA || `${window.location.origin}/app/login`,
    authUrl: process.env.AUTH_URL || window.location.origin,
    googleApiKey: process.env.GOOGLE_API_KEY || '',
    userSnapKey: process.env.USER_SNAP_KEY || '',
    userSnapKeyCorporate: process.env.USER_SNAP_KEY_CORPORATE || '',
    sentry_dsn: process.env.SENTRY_DSN || '',
    sentry_env: process.env.SENTRY_ENV || 'test',
    gaAccount: process.env.GA_ACCOUNT || '',
    rolloutKey: process.env.ROLLOUT_KEY || '',
    oktaIssuer: process.env.OKTA_ISSUER || '',
    oktaClientId: process.env.OKTA_CLIENT_ID || '',
    oktaEarlyRenew: process.env.OKTA_EARLY_RENEW || 60 * 3,
    debug: process.env.DEBUG || false,
    disableOkta: process.env.DISABLE_OKTA || false, // for local development only
    authApi: `${process.env.AUTH_URL}/api/auth`,
    useCloudWatch: process.env.USE_CLOUD_WATCH || false,
    cloudWatchLogsAccessKeyId: process.env.CLOUD_WATCH_LOGS_ACCESS_KEY_ID || '',
    cloudWatchLogsSecretAccessKey:
      process.env.CLOUD_WATCH_LOGS_SECRET_ACCESS_KEY || '',
    cloudWatchLogsRegion: process.env.CLOUD_WATCH_LOGS_REGION || '',
    mycrmServicesBaseUrl: process.env.AUTH_URL || window.location.origin,
  };
}

export function routeDefaultPage({ $state, feature, currentUserService }) {
  const { myCRMNewHomeLandingPage, businessPlanningDashboard } = feature;
  if (myCRMNewHomeLandingPage) {
    return $state.go('app.home');
  }
  const routeBusinessPlanningDashboard = showBusinessPlanningDashboard(
    businessPlanningDashboard,
    currentUserService,
  );
  if (routeBusinessPlanningDashboard) {
    return $state.go('app.dashboardV2', { activeTab: DEFAULT_DASHBOARD_TAB });
  }
  return $state.go('app.dashboard');
}
