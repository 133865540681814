import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { CONFIG_EMAIL } from 'Common/config/email';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { LOAN_LENDING_CATEGORY } from 'Common/constants/lenderConstants';
import { CALCULATION_TYPE } from 'Common/constants/calculations';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';
import { MAIN_STATUS_LENGTH } from 'Common/constants/mainLoanStatusLength';
import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';
import { splitByCapitalLetters, camelize } from 'Common/utilities/string';
import { getCountryCode } from 'Common/utilities/user';
import { isObject } from 'Common/utilities/objectValidation';
import { getLenderLogoImg } from 'Common/utilities/image';
import { searchSameLoanFromList } from 'Common/utilities/factFind';
import { loanScenarioFactFindBuilderForUI } from 'Common/mappers/loanScenario';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { mergeList } from 'Common/utilities/array';
import { parseToInt10 } from 'Common/utilities/parse';
import { initializeOpportunityLabel } from 'Common/utilities/addCalculationUtility';
import { setLoanAppAssistant } from 'Common/utilities/loanApplication';
import { formatReferralItemId } from 'Common/utilities/referral';
import {
  updateImportantDateForEstimatedSettlement,
  getLoanImportantDates,
  setStatusDefault,
  getDateMessage,
  getCalcType,
  showVehicleDetailsUtil,
  showSecurityAddressUtil,
  showSecurityAssetUtil,
  updateRefNoOnlyUtil,
  extendLendingDetailViewOfFile,
  updateLendingDetailScope,
  setLoanListLoadingValue,
  redirectToLoanFullPageView,
  isTopUpLoanVariationQualified,
} from './util/lendingDetail';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LendingDetailCtrl', function LendingDetailCtrl(
      $scope,
      contactService,
      $q,
      $uibModal,
      $state,
      $stateParams,
      toaster,
      $timeout,
      loanScenarioService,
      $rootScope,
      lendingScenarioService,
      fundingCalculatorService,
      nextGenLoanAppService,
      $window,
      pipelineService,
      contactSharedData,
      contactSharedDataService,
      broadcastDataService,
      $sce,
      optionsService,
      $localStorage,
      configService,
      loanCalculatorLinking,
      loanAppDetailsService,
      uiService,
      loanService,
      currentUserService,
      onlineFactFindModal,
      onlineFactFindService,
      crmConfirmation,
      loanScenarioModelService,
      contactModelService,
      loanApplicationServices,
      corporateService,
      leadOpportunitiesService,
      loanFinancialsService,
      viewOfFileModalService,
      loanFileService,
      helloBookPreviewService,
    ) {
      $scope.uiService = uiService;
      $scope.currentUserService = currentUserService;
      $scope.lendingScenarioId = $stateParams.lendingScenarioId;
      $scope.FACT_FIND_TYPE = FACT_FIND_TYPE;
      $scope.LENDING_CATEGORY = LENDING_CATEGORY;
      $scope.LOAN_LENDING_CATEGORY = LOAN_LENDING_CATEGORY;
      $scope.CALCULATION_TYPE = CALCULATION_TYPE;
      $scope.savedCalculationsList = [];
      $scope.appsStatusList = [];
      $scope.isAdviserAccess = false;
      $scope.assetFinanceCategories = [
        LENDING_CATEGORY.ASSET_FINANCE,
        LENDING_CATEGORY.PERSONAL_LOAN,
      ];
      $scope.isLockLoanappFeatureEnabled =
        (configService.feature && configService.feature.lockLoanapp) || 0;
      $scope.familyId = $state.params.familyId;
      $scope.showVehicleDetails = showVehicleDetailsUtil;
      $scope.showSecurityAddress = showSecurityAddressUtil;
      $scope.showSecurityAsset = showSecurityAssetUtil;
      $scope.updateRefNoOnly = (loan) => {
        updateRefNoOnlyUtil({
          $scope,
          contactModelService,
          contactSharedData,
          loan,
        });
      };

      initializeOpportunityLabel({
        $scope,
        leadOpportunitiesService,
        isSingular: true,
      });

      $scope.updateLendingDetailScope = updateLendingDetailScope(
        $scope,
        uiService,
      );
      $scope.setLoanListLoadingValue = setLoanListLoadingValue($scope);
      const updateImportantDate = updateImportantDateForEstimatedSettlement(
        loanScenarioModelService,
      );
      getLoanImportantDates({ $scope, loanApplicationServices });
      $scope.isTopUpLoanVariationQualified = isTopUpLoanVariationQualified(
        $scope.uiService.showTopUpLoanVariation,
      );

      const {
        reviewFactFind,
        lendingLoanAppFactFindUIImprovements,
        assetFinance,
      } = configService.feature;
      $scope.showReviewFF = reviewFactFind;
      $scope.isLendingLoanAppFFImprovementsFeatureEnabled = !!lendingLoanAppFactFindUIImprovements;
      $scope.showAssetFinance = !!assetFinance;

      const getSubscriptionPopUp = () => {
        if (!$scope.userFamilyId) {
          return;
        }
        const params = {
          brokerId: $scope.userFamilyId,
          tickedItemId: TICKABLE_ITEMS.INTERCEPT,
        };
        contactModelService.getSubscriptionPopUp(params).then((data) => {
          const { showPopUp } = data;
          $scope.showPopUp = showPopUp;
        });
      };
      $scope.supportEmail = currentUserService.isAU
        ? CONFIG_EMAIL.MARKETING_AU
        : CONFIG_EMAIL.ADVISER_SERVICES_NZ;
      $scope.isLoadingAdviserTheme = true;

      const getUserInfo = () => {
        const { intercept } = configService.feature;
        contactService.getUserInfo().then((response) => {
          const data = response.data;
          if (data) {
            $scope.userInfo = data;
            $scope.isCorporateUser =
              parseInt(data.AccessType, 10) === ACCESS_TYPE.CORPORATE;
            const adviserAccessList = [
              ACCESS_TYPE.ADVISER,
              ACCESS_TYPE.PRINCIPAL_ADVISER,
            ];
            $scope.isAdviserAccess = adviserAccessList.includes(
              data.AccessType,
            );
            $scope.userFamilyId = data.FamilyId;
            $scope.isAdviserAccess && intercept && getSubscriptionPopUp();
          }
        });
      };

      getUserInfo();

      const setLoadingAdviserTheme = (bool) => {
        $scope.isLoadingAdviserTheme = bool;
      };

      $scope.goToLendingScenario = (id) => {
        if ($scope.crmCountry === 'New Zealand') {
          $state.go('app.LoanProfilerSingle', {
            familyId: $stateParams.familyId,
            lendingScenarioId: id,
          });
        } else {
          $state.go('app.LendingScenarioSingle', {
            familyId: $stateParams.familyId,
            lendingScenarioId: id,
          });
        }
      };

      $scope.$watch('selectedContacts', (newValue) => {
        if (newValue === 'lending') {
          $scope.getLoanListGet();
        }
      });

      /**
       * Open initial tab
       * */
      switch ($stateParams.lendingTab) {
        case 'inprogress':
          $scope.lendingTab = 'inprogressLoans';
          break;
        case 'previous':
          $scope.lendingTab = 'previousLoans';
          break;
        default:
          $scope.lendingTab = 'loans';
          break;
      }

      $scope.$watch('lendingTab', (newVal) => {
        switch (newVal) {
          case 'loans':
            $scope.lendingTemplate =
              '/assets/views/contacts/client/lending/loans.html';
            if (contactSharedData.summaryData.destinationTabToLending) {
              contactSharedData.summaryData.destinationTabToLending = '';
            }
            break;

          case 'inprogressLoans':
            $scope.lendingTemplate =
              '/assets/views/contacts/client/lending/inProgress.html';
            if (contactSharedData.summaryData.destinationTabToLending) {
              contactSharedData.summaryData.destinationTabToLending = '';
            }
            break;

          case 'previousLoans':
            $scope.lendingTemplate =
              '/assets/views/contacts/client/lending/previousLoans.html';
            break;
          default:
            break;
        }
        $scope.getLoanListGet();
      });

      $scope.existingLoans = [];
      $scope.previousLoans = [];
      $scope.inprogressLoans = [];

      /**
       * Description: Loan status list
       * */
      $scope.getLoanStatusList = () => {
        $scope.SettlementDateType = [
          {
            Type: 'Estimated Date',
            Value: true,
          },
          {
            Type: 'Actual Date',
            Value: false,
          },
        ];
        const includedStatus = `${EXTRA_STATUS.CANCELLED},${
          EXTRA_STATUS.LOAN_REPAID
        }${uiService.showTopUpLoanVariation ? `,${EXTRA_STATUS.VARIED}` : ''}`;
        optionsService.getLoanStatus(includedStatus).then((response) => {
          $scope.LoanStatusList = [];
          $scope.LoanStatusMainGroup = [];
          $scope.LoanStatusMiscGroup = [];
          _.forEach(response.data, (status, index) => {
            const statusValue = {
              LoanStatusGroupName: status.GroupName,
              LoanStatusName: status.Name,
              LoanStatusID: status.Value,
            };

            if (index < MAIN_STATUS_LENGTH.STATUS_INDEX) {
              $scope.LoanStatusList.push(statusValue);
            }
            if (index < MAIN_STATUS_LENGTH.STATUS_DIVIDER) {
              $scope.LoanStatusMainGroup.push(statusValue);
            } else {
              $scope.LoanStatusMiscGroup.push(statusValue);
            }
          });
        });
      };
      $scope.getLoanStatusList();

      $scope.getFileStatusList = () => {
        loanFileService.getStatusList().then((data) => {
          if (!data || data.length) {
            $scope.FileStatusList = [];
          }

          $scope.FileStatusList = data.map((status) => {
            return {
              LoanStatusName: status.StatusName,
              LoanStatusID: status.LoanStatusId,
              ...status,
            };
          });
        });
      };
      uiService.viewOfAfileTurnedOn && $scope.getFileStatusList();

      $scope.deleteImportantDate = (loan, type) => {
        swal(
          {
            title: 'Are you sure?',
            text: 'Are you sure you want to remove this date?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes!',
            closeOnConfirm: false,
          },
          (willDelete) => {
            if (willDelete) {
              contactService
                .loanImportantDatesDelete(loan.LoanID, type, false)
                .then(() => {
                  switch (type) {
                    case IMPORTANT_DATE_TYPES.DELETE.APPROVALEXPIRY:
                      loan.PreApprovalExpiry = null;
                      break;
                    case IMPORTANT_DATE_TYPES.DELETE.FINANCE:
                      loan.FinanceDate = null;
                      break;
                    case IMPORTANT_DATE_TYPES.DELETE.SUBMITTED:
                      loan.SubmittedDate = null;
                      break;
                    case IMPORTANT_DATE_TYPES.DELETE.APPROVAL:
                      loan.ApprovalDate = null;
                      break;
                    case IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT:
                      loan.SettlementDate = null;
                      loan.EstimatedSettlementDate = null;
                      break;
                    case IMPORTANT_DATE_TYPES.DELETE.ACTUAL_SETTLEMENT:
                      loan.SettlementDate = null;
                      break;
                    default:
                      break;
                  }
                  swal({
                    title: 'Success',
                    text: 'Date has been successfully deleted.',
                    type: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                  });
                });
            }
          },
        );
      };

      const setInprogressLoansLockableProperty = () => {
        if ($scope.familyId) {
          loanScenarioService
            .scenarioListGet($scope.familyId)
            .then(({ data }) => {
              $scope.scenarioListLoans = data;
              $scope.scenarioListLoans.forEach((loanObj) => {
                const loanList = mergeList(
                  $scope.inprogressLoans,
                  $scope.opportunityLoans,
                );
                const inprogLoan = searchSameLoanFromList(loanObj, loanList);

                if (inprogLoan) {
                  Object.assign(
                    inprogLoan,
                    loanScenarioFactFindBuilderForUI(loanObj),
                  );
                }
              });
            });
        }
      };

      const loanListGet = () => {
        $scope.setLoanListLoadingValue(true);
        $scope.scenarioListLoans = [];

        $scope.loanData = {
          familyId: $scope.familyId,
        };

        $scope.CountryCode = getCountryCode(currentUserService.countryId);

        loanService
          .loanListGet($scope.familyId)
          .then((response) => {
            angular.extend($scope, {
              opportunityLoans: response.opportunityLoans,
              existingLoans: response.existingLoans,
              previousLoans: response.previousLoans,
              inprogressLoans: response.inprogressLoans,
            });
            setInprogressLoansLockableProperty();
          })
          .finally(() => {
            $scope.setLoanListLoadingValue(false);
          });
      };

      $scope.existingLoansSeeAll = false;
      $scope.inprogressLoansSeeAll = false;
      $scope.seeAllInprogress = () => {
        $scope.inprogressLoansSeeAll = true;
      };

      $scope.seeAllExisting = (bool = true) => {
        $scope.existingLoansSeeAll = bool;
        $scope.isShowInProgress = bool;
      };

      $scope.isShowPreviousLoan = () => {
        return (
          $scope.isShowInProgress &&
          !$scope.showPreviousLoansLoading &&
          !$scope.showPreviousLoansLoading &&
          $scope.previousLoans.length
        );
      };

      $scope.getLoanAppLink = (params) => {
        redirectToLoanFullPageView({
          viewOfAfileTurnedOn: uiService.viewOfAfileTurnedOn,
          $state,
        })(params);
      };

      function selectStatus(statusObj, inprogressObj) {
        $scope.inprogressLoan = inprogressObj;
        $scope.statusName = statusObj.PipelineStatus;
        $scope.inprogressLoan.PipelineStatus = $scope.statusName;
        $scope.cardMove = {};
        $scope.cardMove.PipelineCardsID = $scope.inprogressLoan.PipelineCardsID;
        $scope.cardMove.ToStatusID = statusObj.PipelineStatusID;

        pipelineService.PipelineCardsMoveToNextStatusSet($scope.cardMove).then(
          () => {
            contactSharedData.lendingSharedData.inProgressToPipeline = {
              statusObj,
              inprogressObj,
            };

            toaster.pop(
              'success',
              'Moved',
              'Stage has been updated successfully.',
            );
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
        if (
          statusObj.PipelineStatusID === 100 ||
          statusObj.PipelineStatusID === 101
        ) {
          loanListGet();
        }
      }
      function saveReferenceNo(inProgressObj) {
        contactService
          .loanImportantDatesSet(
            inProgressObj.LoanID,
            1,
            moment(inProgressObj.SettlementDate).format('YYYY-MM-DD'),
            inProgressObj.ReferenceNumber,
            inProgressObj.IsEstimated,
          )
          .then((res) => {
            if (res.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
              toaster.pop('warning', 'Reference number failed to update.');
            } else {
              contactSharedData.lendingSharedData.referenceUpdate = {
                loanScenarioID: inProgressObj.LoanScenarioID,
                referenceNumber: inProgressObj.ReferenceNumber,
                isUpdating: false,
              };
              toaster.pop('success', 'Reference number updated successfully.');
            }
          });
      }
      /**
       * auto-load-updated-sections
       * */
      angular.extend($scope, {
        pipelineStatusData: {},
        storedRefNo: '',
        /**
         * loan-status-update
         * */
        selectStatus(statusObj, inprogressObj) {
          selectStatus(statusObj, inprogressObj);
        },
        /**
         * refno-update
         * */
        updateRefNo(inProgressObj) {
          if ($scope.storedRefNo !== inProgressObj.ReferenceNumber) {
            saveReferenceNo(inProgressObj);
          } else {
            $timeout(() => {
              contactSharedData.lendingSharedData.referenceUpdate.isUpdating = false;
            }, 1000);
          }
        },
        storeRefno(inProgressObj) {
          contactSharedData.lendingSharedData.referenceUpdate = {
            loanScenarioID: inProgressObj.LoanScenarioID,
            referenceNumber: inProgressObj.ReferenceNumber,
            isUpdating: true,
          };
          $scope.storedRefNo = inProgressObj.ReferenceNumber;
        },
        checkIfHasSettlementDate: (loan) => {
          if (
            _.trim(loan.SettlementDate) === '' ||
            _.isUndefined(loan.SettlementDate)
          ) {
            return false;
          }
          return true;
        },
      });

      $scope.viewSubmission = (loan) => {
        nextGenLoanAppService.nextGenAOLView(loan).then((response) => {
          if (!response.data) {
            return;
          }
          $scope.nextGenObj = response.data;
          $scope.nextGenObj.https = $sce.trustAsResourceUrl(
            $scope.nextGenObj.https,
          );
          const guid = $scope.nextGenObj.GUID || '';
          const startPage = $scope.nextGenObj.StartPage || '';
          $scope.applicationViewURL = `${$scope.nextGenObj.https}?GUID=${guid}&StartPage=${startPage}`;
          $window.open($scope.applicationViewURL);
        });
      };

      $scope.getLoanListGet = () => {
        loanListGet();
      };

      $scope.toggleLockStatus = (app) => {
        onlineFactFindService.toggleLockStatus(app);
      };

      const getClientInfo = (loanId) => {
        onlineFactFindModal.getInvolvePartyToReview(loanId).then(() => {});
      };

      const openInterceptModal = () => {
        const { userFamilyId: familyId } = $scope;
        const props = {
          familyId,
        };
        const modalInstance = $uibModal.open({
          template: `<intercept-bank-connect
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  >
                </intercept-bank-connect>`,
          size: 'lg',
          backdrop: 'static',
          windowClass: 'intercept-bank-connect',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
        modalInstance.result.then((response) => {
          if (!response) {
            return;
          }
          $scope.openFactFind = response;
          $scope.inviteFactFind($scope.inprogressLoan);
        });
      };

      $scope.openGenerateFactFindModal = (
        loanScenarioID,
        type = FACT_FIND_TYPE.GENERAL,
      ) => {
        const loanId = parseToInt10(loanScenarioID) || $scope.loanScenarioId;
        const showReview = type === FACT_FIND_TYPE.GENERAL;
        if (
          $scope.showPopUp &&
          !$scope.openFactFind &&
          (type === FACT_FIND_TYPE.GENERAL || type === FACT_FIND_TYPE.INVITE)
        ) {
          openInterceptModal();
          return;
        }
        broadcastDataService.reloadLendingLoanList = () => {
          $scope.getLoanListGet();
        };
        if (uiService.newHelloModalEnabled && type !== FACT_FIND_TYPE.REVIEW) {
          helloBookPreviewService
            .open({
              familyId: $scope.familyId,
              isForLoanWriters: null,
              isNewModal: true,
              loanApplicationId: loanId,
              selectedService: '',
              onlyFactFind: true,
            })
            .then((response) => {
              getClientInfo(loanId);
              $scope.getLoanListGet();
            });
        } else {
          onlineFactFindModal
            .open({
              loanId,
              type,
              isShowReviewButton: showReview,
            })
            .then((response) => {
              if (!response) {
                return;
              }
              const { factFindSend, factFindType } = response;
              if (factFindSend) {
                getClientInfo(loanId);
              } else if (!factFindSend && factFindType) {
                $scope.openGenerateFactFindModal(loanId, factFindType);
              }
            });
        }
      };

      $scope.inviteFactFind = (inprogressLoan) => {
        $scope.loanScenarioId = inprogressLoan.LoanScenarioID;
        const loanList = mergeList(
          $scope.inprogressLoans,
          $scope.opportunityLoans,
        );
        const activeFactFindLoans = loanList.filter((loan) => loan.IsLockable);

        if ($scope.showPopUp && !$scope.openFactFind) {
          // eslint-disable-next-line sonarjs/no-extra-arguments
          openInterceptModal(FACT_FIND_TYPE.GENERAL);
          return;
        }

        if (!activeFactFindLoans.length) {
          $scope.openGenerateFactFindModal();
          return;
        }

        const personName =
          (inprogressLoan.FamilyList &&
            inprogressLoan.FamilyList.length &&
            inprogressLoan.FamilyList[0].DisplayName) ||
          '';
        const transcludeHtml = $scope.getActiveFactFindTranscludeHtml(
          activeFactFindLoans,
        );
        crmConfirmation.open({
          type: 'warning',
          title: 'Before we send that invite...',
          description: `Sending a new fact find to ${personName} will remove them from their current fact find:`,
          buttonText: 'Yes, I wish to proceed',
          onConfirm: $scope.openGenerateFactFindModal,
          showCloseButton: true,
          showCancelButton: true,
          modalSize: 'md',
          cancelButtonClass: 'colored',
          transcludeHtml,
        });
      };

      $scope.getActiveFactFindTranscludeHtml = (activeFactFindLoans) => {
        const currency = currentUserService.brokerRegionalization.currencySign;

        // eslint-disable-next-line unicorn/consistent-function-scoping
        const getActiveFactFindRowItem = (
          activeFactFindLoan,
          amountCurrency,
        ) => {
          const {
            LoanAmount: loanAmount,
            Person: person,
            ReferenceNumber: referenceNo,
            LoanStatus: loanStatus,
            Lender: lender,
          } = activeFactFindLoan;
          const params = {
            lenderId: lender.LenderId || 0,
            lenderName: lender.LenderName || '',
            countryCode: lender.CountryCode || '',
          };

          const logoImg = getLenderLogoImg(params);
          return `<div class="loan-active-item">
          ${logoImg}
          <div class="name-amount-group"><div class="name" title="${person}" uib-tooltip="${person}">${person}</div>
          ${
            loanAmount
              ? `- <div class="amount">${amountCurrency} ${loanAmount}</div>`
              : ''
          }
          ${
            referenceNo
              ? `<div class="tag reference-no">Ref #: ${referenceNo}</div>`
              : ''
          }
          </div>
          <div class="tag pipeline-status" title="${
            loanStatus.LoanStatusName
          }">${loanStatus.LoanStatusName}</div>
        </div>`;
        };

        const activeFactFindItems = activeFactFindLoans.reduce(
          (accum, currentLoan) => {
            const row = getActiveFactFindRowItem(currentLoan, currency);
            return `${accum} ${row}`;
          },
          '',
        );

        return `<div class="active-fact-find-items">${activeFactFindItems}</div>`;
      };

      /**
       * watchers - contactSharedData
       * */
      $scope.$watch(
        () => {
          return contactSharedData.lendingSharedData.loanDetail;
        },
        () => {
          const obj = contactSharedData.lendingSharedData.loanDetail;
          if (obj.processResult === 'close' || obj.processAction === 'edit') {
            $scope.getLoanListGet();
          }
        },
      );

      /**
       * detects changes for pipeline menu
       * */
      $scope.$watch(
        () => {
          return contactSharedData.pipelineStatusData.statusUpdate;
        },
        () => {
          const obj = contactSharedData.pipelineStatusData.statusUpdate;
          if (obj.StatusID !== 100 && obj.StatusID !== 101) {
            if ($scope.inprogressLoans && $scope.inprogressLoans.length) {
              _.forEach($scope.inprogressLoans, (o) => {
                if (o.LoanScenarioID === obj.LoanScenarioID) {
                  for (let x = 0; x < $scope.appsStatusList.length; x++) {
                    if (
                      $scope.appsStatusList[x] &&
                      $scope.appsStatusList[x].PipelineStatusID === obj.StatusID
                    ) {
                      o.PipelineStatus =
                        $scope.appsStatusList[x].PipelineStatus;
                    }
                  }
                }
              });
            }
          } else {
            loanListGet();
          }
        },
      );

      $scope.$watch(
        () => {
          return contactSharedData.pipelineStatusData.referenceUpdate;
        },
        () => {
          $scope.pipelineStatusData.referenceUpdate =
            contactSharedData.pipelineStatusData.referenceUpdate;
          const obj = $scope.pipelineStatusData.referenceUpdate;

          if ($scope.inprogressLoans && $scope.inprogressLoans.length) {
            _.forEach($scope.inprogressLoans, (o) => {
              if (o.LoanScenarioID === obj.LoanScenarioID) {
                $scope.storedRefNo = obj.ReferenceNumber;
                o.ReferenceNumber = obj.ReferenceNumber;
              }
            });
          }
        },
      );

      $scope.dateParser = (dateString) => {
        if (dateString) {
          let dateArray = dateString.split('-');
          dateArray = dateString.split(' ');
          return `${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`;
        }
      };

      $scope.format = 'dd MMM yyyy';
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      $scope.importantDateSave = (obj, type, date, loanreferenceNumber) => {
        if (!date) {
          return;
        }
        const dateFormat = moment(date).format('YYYY-MM-DD');
        const message = getDateMessage(type);
        if (updateImportantDate({ obj, type, dateFormat, message })) {
          return;
        }
        contactService
          .loanImportantDatesSet(
            obj.LoanID,
            type,
            dateFormat,
            loanreferenceNumber,
            obj.IsEstimated,
          )
          .then(
            (res) => {
              if (res.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
                toaster.pop('warning', 'Date is invalid');
              } else {
                toaster.pop('success', message);
                if (IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT !== type) {
                  setStatusDefault({
                    $scope,
                    loanService,
                    contactService,
                    loanFinancialsService,
                    familyId: $scope.familyId,
                    obj,
                    type,
                  });
                }
              }
            },
            () => {
              toaster.pop('error', 'Error saving important date.');
            },
          );
      };

      /**
       * logo (when logo is not displayed)
       * */
      $scope.errPreviousLoanImg = (lender) => {
        if (lender) {
          const indexOfResult = parseInt(lender['0'].name, 10);
          $scope.previousLoans[indexOfResult].Lender.noImg = true;
        }
      };
      $scope.errInProgressLoanImg = (lender) => {
        if (lender) {
          const indexOfResult = parseInt(lender['0'].name, 10);
          $scope.inprogressLoans[indexOfResult].Lender.noImg = true;
        }
      };
      $scope.errExistingLoansImg = (lender) => {
        if (lender) {
          const indexOfResult = parseInt(lender['0'].name, 10);
          $scope.existingLoans[indexOfResult].Lender.noImg = true;
        }
      };

      /**
       * duration
       * */
      $scope.getDuration = (endParam) => {
        let end = endParam;
        const start = moment();
        end = moment(end);

        if (!_.isUndefined(end) || end >= start) {
          try {
            return moment.duration(end - start).humanize();
          } catch (error) {
            return 'Cant evaluate';
          }
        } else {
          return null;
        }
      };

      const getContactCalculations = () => {
        return contactService
          .savedCalculationsGet($scope.familyId)
          .then((response) => {
            if (!response) {
              return;
            }

            return response.data;
          });
      };

      const getLoanCalculations = () => {
        if (uiService.viewOfAfileTurnedOn) {
          return contactModelService.getDealContactCalculations(
            $scope.familyId,
          );
        }
        return (
          contactService
            .getLoanCalculations($scope.familyId)
            // eslint-disable-next-line sonarjs/no-identical-functions
            .then((response) => {
              if (!response) {
                return;
              }

              return response.data;
            })
        );
      };

      /**
       * Saved Calculations
       * */
      function savedCalculationsListGet() {
        const calculationsPromises = [
          getContactCalculations(),
          getLoanCalculations(),
        ];

        /**
         * please make sure not to interchange the values of @param calculationsPromises
         * as it is tightly coupled with the code block below
         */
        $q.all(calculationsPromises).then((data) => {
          if (!data || !data.length) {
            return;
          }

          const savedCalculationsList = _.orderBy(
            [...data[0], ...data[1]],
            ['DateOfCalculation'],
            ['asc'],
          );

          $scope.savedCalculationsList = savedCalculationsList.map(
            (calculation) => {
              calculation.calcTypeDesc = splitByCapitalLetters(
                calculation.TypeOfCalculation,
              );
              return calculation;
            },
          );
        });
      }

      savedCalculationsListGet();

      $scope.setSessionStorage = () => {
        $window.sessionStorage.setItem('loanApplication', $scope.loanAppId);
        $window.sessionStorage.setItem(
          'loanApplicationContact',
          $scope.familyId,
        );
      };

      $scope.goToFundsCalc = () => {
        $scope.setSessionStorage();
        $scope.redirectToFundingCalculator();
      };

      /**
       * Redirect To Funding Calculator Page
       * */
      $scope.redirectToFundingCalculator = (eventId) => {
        if (!_.isUndefined(eventId)) {
          $window.sessionStorage.setItem(
            'contactFamilyIdForFundingCalculator',
            $scope.familyId,
          );
          $state.go('app.fundingCalculator', { brokerEventId: eventId });
        } else {
          // Get Broker Event Id
          fundingCalculatorService
            .getFundingCalculatorNewBrokerEvent()
            .then((response) => {
              $window.sessionStorage.setItem(
                'contactFamilyIdForFundingCalculator',
                $scope.familyId,
              );
              $state.go('app.fundingCalculator', {
                brokerEventId: response.data,
              });
            });
        }
      };

      $scope.redirectToLoanProfiler = (tabName, eventId) => {
        const obj = {
          tabName,
          eventId,
          familyId: $scope.familyId,
          contact: loanCalculatorLinking.LINKERS.CONTACT,
        };
        $localStorage.contactLoanCalculatorLinker = {
          linkerId: loanCalculatorLinking.LINKERS.CONTACT,
          familyId: $scope.familyId,
        };
        loanCalculatorLinking.redirectToLoanProfiler(obj);
      };

      $scope.redirectToLoanCalc = (calcObj) => {
        if (typeof calcObj.IsOpportunity === 'undefined') {
          switch (calcObj.TypeOfCalculation) {
            case 'Serviceability':
              $scope.redirectToLoanProfiler(
                'serviceability',
                calcObj.BrokerEventID,
              );
              break;
            case 'ProductFinder':
              $scope.redirectToLoanProfiler(
                'productFinder',
                calcObj.BrokerEventID,
              );
              break;
            case 'FundingCalculator':
              $scope.redirectToFundingCalculator(calcObj.BrokerEventID);
              break;
            default:
              break;
          }
        } else {
          const args = {
            tabName: camelize(calcObj.TypeOfCalculation),
            eventId: calcObj.BrokerEventID,
            id: calcObj.LoanScenarioId,
            linkerId: loanCalculatorLinking.LINKERS.OPPORTUNITY,
            title: calcObj.Title,
            isEdit: true,
            isOpportunityOrLoanapp: calcObj.IsOpportunity,
            familyId: $scope.familyId,
            familyName: $scope.clientHeaderName || $scope.business?.Name,
            isBusiness: $scope.isBusiness,
            previousRouteStateParams: {
              opportunityId: calcObj.LoanScenarioId,
              loanId: calcObj.LoanId,
              sourceFamilyId: $scope.familyId,
              sourceFamilyName: $scope.clientHeaderName,
              isContactOpener: 1,
              tabName: 'calculations',
            },
          };

          const { tabName } = args;
          if (tabName === 'fundingCalculator') {
            loanCalculatorLinking.redirectToFundingCalculator(args);
          } else {
            loanCalculatorLinking.redirectToLoanProfiler(args);
          }
        }
      };

      /**
       * Delete Calculations
       * */
      $scope.removeSavedCalc = (calc, e) => {
        e && e.stopPropagation();
        const {
          LoanScenarioId: opportunityId,
          TypeOfCalculation: typeOfCalculation,
        } = calc;
        const calcType = getCalcType(typeOfCalculation);
        if (calcType) {
          contactService
            .savedCalculationsDelete(
              calcType,
              calc.LoanScenariOID || opportunityId,
              calc.BrokerEventID,
            )
            .then((response) => {
              if (response.data === 1) {
                toaster.pop(
                  'success',
                  'Successfully deleted Saved Calculation',
                );
                savedCalculationsListGet();
              } else {
                toaster.pop('error', 'Error deleting Saved Calculation');
              }
            });
        }
      };

      $scope.addExistingLoansModal = (action, familyId, loanId) => {
        $scope.params = {
          loanInformationAction: action,
          familyId: parseInt(familyId, 10),
          loanId,
        };
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/loan_modal_v2_add_existing.html',
          controller: 'LoanInformationAddExistingCtrl',
          scope: $scope,
          size: 'md',
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(
          (args) => {
            if (_.size(args) > 0) {
              $scope.loanInformationModal(
                args.action,
                args.familyId,
                args.loanId,
              );
            }
          },
          // eslint-disable-next-line sonarjs/no-identical-functions
          (error) => {
            if (_.size(error) > 0) {
              $scope.loanInformationModal(
                error.action,
                error.familyId,
                error.loanId,
              );
            }
          },
        );
      };

      $scope.loanInformationModal = (
        action,
        familyId,
        loanId,
        type = 'npw',
      ) => {
        const params = {
          loanInformationAction: action,
          familyId: parseInt(familyId, 10),
          loanId,
        };
        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
          controller: 'LoanInformationCtrl',
          scope: $scope,
          size: 'wide',
          backdrop: 'static',
          keyboard: false,
          windowClass: configService.feature.additionalLoanImportantDates
            ? 'loan-information-modal'
            : '',
          resolve: {
            params: () => params,
            loanType: () => type,
          },
        });

        modalInstance.result.then(
          () => {
            $scope.getLoanListGet();
            contactSharedDataService.reloadContactLoanTimeline();
          },
          // eslint-disable-next-line sonarjs/no-identical-functions
          () => {
            $scope.getLoanListGet();
            contactSharedDataService.reloadContactLoanTimeline();
          },
        );
      };

      $scope.openTaxInvoiceModal = (inprogressLoan) => {
        const props = {
          loanDetails: inprogressLoan,
        };
        $uibModal.open({
          template: `<tax-invoice-modal
                    modal-instance="vm.modalInstance"
                    loan-details="vm.props.loanDetails">
                  </tax-invoice-modal>`,
          size: 'md',
          backdrop: 'static',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
      };

      $scope.isShowTaxInvoiceOption = (lendingCategoryId) => {
        return (
          currentUserService.isAU &&
          $scope.showAssetFinance &&
          $scope.assetFinanceCategories.includes(lendingCategoryId)
        );
      };

      $rootScope.$on('Open_loanInformationModal', (e, info) => {
        $scope.loanInformationModal(info.action, info.familyId, info.loanId);
      });

      /**
       * Loan Application
       * */
      $scope.CreateDummyLoanScenario = () => {
        broadcastDataService.InlinePreloader = {
          preLoaderText: 'Creating New Loan Application',
          preLoaderSize: '',
          showPreLoader: true,
        };
        contactService
          .getContactAssignedAdviser($stateParams.familyId)
          .then((response) => {
            if (response.data) {
              $scope.dummyscenario = {
                LoanScenarioId: '0',
                AdviserId: response.data,
                Title: `Loan Application ${Math.random()
                  .toString(36)
                  .slice(20)}`,
                StatusName: 'ongoing',
                FamilyId: $stateParams.familyId,
                AssignedLenderId: '0',
              };
              if (!$scope.isAdviserAccess) {
                $scope.userFamilyId = response.data;
              }
              loanScenarioService
                .scenarioSet($scope.dummyscenario)
                .then((respond) => {
                  if (!respond.data) {
                    return;
                  }
                  loanAppDetailsService.setInsuranceDefault(
                    respond.data,
                    $stateParams.familyId,
                    $scope.userFamilyId,
                  );
                  setLoanAppAssistant({
                    $state,
                    corporateService,
                    loanScenarioService,
                    dummyScenario: $scope.dummyscenario,
                  })(respond.data);
                  $rootScope.$on('$stateChangeSuccess', () => {
                    broadcastDataService.InlinePreloader = {
                      preLoaderText: '',
                      preLoaderSize: '',
                      showPreLoader: false,
                    };
                  });
                });
            }
          });
      };

      $rootScope.countLoanApplication = 0;

      $scope.deleteScenario = (id) => {
        lendingScenarioService.scenarioDelete(id).then((response) => {
          if (response.status === 200) {
            toaster.pop('success', 'Successful', 'Successfully Deleted');
            $scope.GetLendingScenarioList();
          } else {
            toaster.pop('warning', 'Error', 'Something went wrong.');
          }
        });
      };

      /**
       * lending scenario
       * */
      $scope.GetLendingScenarioList = () => {
        lendingScenarioService
          .scenarioListGet($stateParams.familyId)
          .then((response) => {
            $scope.lendingScenario = response.data;
            $scope.loanPurposeList = [
              {
                Name: 'First Home',
                Value: 'FirstHome',
              },
              {
                Name: 'Refinancing',
                Value: 'Refinancing',
              },
              {
                Name: 'Property Purchase',
                Value: 'PropertyPurchase',
              },
              {
                Name: 'Investment Property',
                Value: 'InvestmentProperty',
              },
              {
                Name: 'Building',
                Value: 'Building',
              },
              {
                Name: 'Home Improvements',
                Value: 'HomeImprovements',
              },
              {
                Name: 'Debt Consolidation',
                Value: 'DebtConsolidation',
              },
              {
                Name: 'Monthly Income/Equity Release',
                Value: 'MonthlyIncome_EquityRelease',
              },
              {
                Name: 'Other',
                Value: 'Other',
              },
            ];

            const LoanPurposes = [];

            angular.forEach($scope.lendingScenario, (lp) => {
              angular.forEach($scope.loanPurposeList, (val) => {
                if (lp.LoanPurpose === val.Value) {
                  LoanPurposes.push(val.Name);
                }
              });

              if (!isObject(lp)) {
                return;
              }
              lp.LoanPurposes = LoanPurposes.join(', ');
            });
          });
      };

      /**
       * Initialize Lending Scenario List
       * */
      $scope.GetLendingScenarioList();

      $scope.addScenario = () => {
        $uibModal.open({
          templateUrl:
            '/assets/views/contacts/client/lending/lendingScenario/addScenario.html',
          scope: $scope,
          controller: 'AddScenarioModalCtrl',
          size: 'md',
        });
      };

      if ($rootScope.createLoanAppPipeline === true) {
        $rootScope.createLoanAppPipeline = false;
        $scope.lendingTab = 'loanApplications';
        $scope.CreateDummyLoanScenario();
      }

      /**
       * josandi starts here
       * */

      $scope.$watch(() => {
        if ($('.loan-modal-v2').length) {
          $('.modal-dialog').addClass('modal-v2');
        }
      });

      /**
       * dont automatically show ADD LOAN SPLIT if there is already a list
       * */
      $scope.addLoanVar = contactSharedData.showLoanSplit;

      $scope.addLoanSplitButton = true;
      $scope.showAddLoanSplitButton = () => {
        $scope.addLoanSplitButton = !$scope.addLoanSplitButton;
      };

      /**
       * $scope.getFamEnquirySource
       * Used as callback after loading the enquirylist or contactInfoSetValue
       * */
      $scope.getFamEnquirySource = () =>
        $q((resolve, reject) => {
          contactService.contactFamilyInfoGet($scope.familyId).then((res) => {
            if (!res || !res.data) {
              reject();
            }
            const {
              ReferralCategoryId,
              ReferralCategory: ReferralCategoryName,
              ReferralItem: ReferralItemName,
              ReferrerAgreementTypeId,
              ReferrerID,
              ReferrerName,
              ReferrerOrgID,
              ReferrerOrgName,
            } = res.data;
            const source = {
              ReferralCategoryId,
              ReferralCategoryName,
              ReferralItemId: formatReferralItemId(res.data),
              ReferralItemName,
              ReferrerAgreementTypeId,
              ReferrerID,
              ReferrerName,
              ReferrerOrgID,
              ReferrerOrgName,
            };
            if (source.ReferralItemId) {
              resolve(source);
            } else {
              reject();
            }
          });
        });

      /**
       * $scope.processEnquirySource()
       * check if Enquiry Source should be disabled
       * Disabled if Category is Website - Loan Market or Affiliate - Loan Market
       * */
      $scope.processEnquirySource = (ReferralCategoryId) => {
        contactService.getUserInfo().then((userlevel) => {
          /**
           * Override condition if Corporate User
           */
          if (
            parseInt(userlevel.data.AccessType, 10) !== ACCESS_TYPE.CORPORATE &&
            (ReferralCategoryId === REFERRAL_TYPE.WEB_LOANMARKET ||
              ReferralCategoryId === REFERRAL_TYPE.CONTACT_US)
          ) {
            $('.loan-details')
              .find('#EnquirySourceDropdown')
              .attr('disabled', 'disabled'); // disable the dropdown
          }
        });
      };

      $scope.hasThreeOvals = (loanPurpose) => {
        return loanPurpose ? 'three-ovals' : 'two-ovals';
      };

      $scope.toggleDate = (obj, property) => {
        obj[property] = !obj[property];
      };

      $scope.getAdviserBranding = () => {
        contactModelService
          .getFamilyAdviserTheme($scope.familyId)
          .then((data) => {
            setLoadingAdviserTheme(false);
            if (!data) {
              return;
            }
            $scope.isBrandingReady =
              !data.isBYOB || !!(data && data.logoDocId && data.tradingName);
          })
          .catch(() => setLoadingAdviserTheme(false));
      };
      $scope.getAdviserBranding();

      contactSharedDataService.reloadContactLending = () => {
        $scope.getLoanListGet();
      };
      contactSharedDataService.reloadContactCalculations = () => {
        savedCalculationsListGet();
      };
      uiService.viewOfAfileTurnedOn &&
        extendLendingDetailViewOfFile({
          $scope,
          uiService,
          contactSharedData,
          viewOfFileModalService,
        });
    });
