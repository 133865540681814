import {
  MONTHLY_MULTIPLIER,
  JOINT_OWNERSHIP,
  SINGLE_OWNERSHIP,
  COLLAPSE,
  JOINT_OWNERSHIP_OPTION,
  SEPARATE_AMOUNT_UNEQUALFF,
  JOINT_OWNERSHIP_UNEQUALFF,
  UNEQUAL_OWNERSHIP,
  FILLER_FINANCIAL_ID,
} from 'Common/constants/livingExpense';
import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';
import { humanizeDate, dateLesserThan } from 'Common/utilities/date';
import { getParsed } from 'Common/utilities/math';
import moment from 'moment';
import { SOURCE_PLATFORM } from 'Common/constants/sourcePlatform';
import { INFO_EXPIRATION_LIMIT } from 'Common/config/infoExpiration';
import { parseToInt10 } from 'Common/utilities/parse';
import _ from 'lodash';

const ONE_TYPE = 1;
const TWO_TYPES = 2;

export const mapFrequencyTypesData = (frequencyData) => {
  frequencyData &&
    frequencyData.map((o) => {
      o.Desc = o.Name;
      o.Value = parseInt(o.Value, 10);

      return o;
    });
  return frequencyData;
};

export const getFrequencyMultiplier = (
  frequency = FINANCIAL_FREQUENCY.MONTHLY,
) => {
  return MONTHLY_MULTIPLIER.find((obj) => obj.VALUE === frequency).MULTIPLIER;
};

export const getOwnerName = (borrowers = []) => {
  if (!_.get(borrowers, 'length')) {
    return '';
  }
  return borrowers.length > 1
    ? 'Joint'
    : `${borrowers[0].FirstName} ${borrowers[0].LastName}`;
};

export const getLastSavedDescription = (expenseCategory) => {
  if (!expenseCategory) {
    return '';
  }
  const { LastModifiedDate, ModifiedBy, SourcePlatform } = expenseCategory;
  const isInvalid = !LastModifiedDate || !ModifiedBy || !SourcePlatform;
  if (isInvalid) {
    return '';
  }
  return `Last saved by ${ModifiedBy} via ${SourcePlatform} ${humanizeDate(
    LastModifiedDate,
  )}`;
};

export const calculateTotalMonthlyExpense = (
  totalLivingExpense = 0,
  categoryMonthyExpense = 0,
) => {
  const amount = getParsed(categoryMonthyExpense);
  if (!amount) {
    return totalLivingExpense;
  }
  return totalLivingExpense + amount;
};

export const createBorrowers = (
  borrowers = [],
  borrowerId = JOINT_OWNERSHIP,
) => {
  return borrowers.reduce((initialBorrower, borrower) => {
    if (
      borrowerId === JOINT_OWNERSHIP ||
      (borrower && borrowerId === borrower.BorrowerID)
    ) {
      return [...initialBorrower, { ...borrower }];
    }
    return initialBorrower;
  }, []);
};

export const setBorrowerId = (borrowers = [], borrowerId) => {
  return (
    borrowerId ||
    (borrowers &&
      ((borrowers.length === SINGLE_OWNERSHIP && borrowers[0].BorrowerID) ||
        (borrowers.length > SINGLE_OWNERSHIP && JOINT_OWNERSHIP)))
  );
};

export const createTypes = (types = [], borrowers = []) => {
  if (!types) {
    return types;
  }
  return types.reduce((initial, type) => {
    const { financialId, id, borrowerId, value } = type;
    const parsedValue = parseToInt10(value);
    const invalidType = !financialId && !id && !parsedValue;
    if (invalidType) {
      return initial;
    }
    const selectedBorrowers = createBorrowers(
      borrowers,
      setBorrowerId(borrowers, borrowerId),
    );
    return [
      ...initial,
      {
        ...type,
        borrowers: [...selectedBorrowers],
        isDeleted: type.isDeleted || !parsedValue,
      },
    ];
  }, []);
};

export const getExpenseTypeIndex = (expenseTypes = [], expenseType) => {
  const { categoryId } = expenseType;
  return expenseTypes.map((obj) => obj.categoryId).indexOf(categoryId);
};

export const getOwnerNameInitial = (borrowers, borrowerId) => {
  if (!borrowerId) {
    return '';
  }
  if (borrowerId === JOINT_OWNERSHIP) {
    return 'Joint';
  }
  const borrower = borrowers.find((obj) => obj.BorrowerID === borrowerId);
  return `${borrower.FirstName} ${borrower.LastName}`;
};

export const isValidToUpdate = (obj, firstName) => {
  obj.lastSavedDescription =
    getLastSavedDescription({
      LastModifiedDate: moment(),
      ModifiedBy: firstName,
      SourcePlatform: SOURCE_PLATFORM.MY_CRM.DISPLAY_TEXT,
    }) ||
    obj.lastSavedDescription ||
    '';
  obj.lastModifiedDate = moment().format();
  obj.modifiedBy = firstName;
  obj.description = obj.tempDescription || obj.description;
  obj.tempDescription = '';
  return obj;
};

export const checkExpensesAge = (expenseTypes, confirmFinancials) => {
  if (!confirmFinancials || !_.get(expenseTypes, 'length')) {
    return false;
  }
  return expenseTypes.some((expense) => {
    const { lastModifiedDate } = expense;
    return dateLesserThan(
      lastModifiedDate,
      INFO_EXPIRATION_LIMIT.unit,
      INFO_EXPIRATION_LIMIT.frequency,
    );
  });
};

export const calculateTotalGroupAmount = (expense) => {
  const { types } = expense;
  if (!_.get(types, 'length')) {
    return expense;
  }
  const groupTotalAmountPerMonth = types.reduce((initial, current) => {
    const { value, frequencyId, isDeleted } = current;
    if (!getParsed(value) || isDeleted) {
      return initial;
    }
    return initial + getParsed(value) * getFrequencyMultiplier(frequencyId);
  }, 0);
  return {
    ...expense,
    groupTotalAmountPerMonth,
  };
};

export const getTypeCount = (expenseType, type, index) => {
  if (typeof index === 'undefined' || !expenseType || !expenseType.types) {
    return;
  }
  const { typeId } = type;
  return expenseType.types.reduce((count, currentType) => {
    if (currentType.typeId !== typeId || currentType.isDeleted) {
      return count;
    }
    return count + 1;
  }, 0);
};

export const addType = (expenseType, type, index) => {
  const countType = getTypeCount(expenseType, type, index);
  if (!countType) {
    return;
  }
  const { type: typeName } = type;
  return expenseType.types.splice(index + countType, 0, {
    ...type,
    ...COLLAPSE,
    borrowerId: JOINT_OWNERSHIP,
    borrowers: [],
    financialId: 0,
    id: 0,
    frequencyId: FINANCIAL_FREQUENCY.MONTHLY,
    isContinuingAfterSettlement: false,
    owner: '',
    type: typeName,
    value: 0,
    isMainExpense: false,
  });
};

export const removeType = (expenseType, type, index) => {
  const OTHER = 'Other';
  const countType = getTypeCount(expenseType, type, index);
  if (!countType) {
    return;
  }
  const { typeId, id } = type;
  !id && expenseType.types.splice(index, 1);
  let sameTypeCounter = 0;
  let typeName = '';
  return expenseType.types.map((currentType, currentIndex) => {
    if (currentType.typeId !== typeId) {
      return currentType;
    }
    currentType.isDeleted =
      (currentType.id && currentIndex === index) ||
      currentType.isDeleted ||
      (type.type === OTHER && currentType.description === type.description);
    !currentType.isDeleted && sameTypeCounter++;
    typeName =
      sameTypeCounter === 1 && !currentType.isDeleted
        ? currentType.type
        : typeName;
    return currentType;
  });
};

export const selectFrequency = (type, value) => {
  if (!type || !value) {
    return type;
  }
  type.frequencyId = value;
  return type;
};

export const selectOwner = (type, borrowerId, borrowers = []) => {
  if (!type || !borrowerId) {
    return type;
  }
  const { borrowerId: typeBorrowerId } = type;
  type.clicked = true;
  switch (!!borrowerId) {
    case borrowerId === typeBorrowerId:
      type.borrowerId = !borrowerId;
      break;
    case JOINT_OWNERSHIP === typeBorrowerId:
      type.borrowerId = borrowers.find(
        (borrower) => borrower.BorrowerID !== borrowerId,
      ).BorrowerID;
      break;
    case borrowerId &&
      typeBorrowerId &&
      borrowerId !== typeBorrowerId &&
      typeBorrowerId !== JOINT_OWNERSHIP:
      type.borrowerId = JOINT_OWNERSHIP;
      break;
    default:
      type.borrowerId = borrowerId;
      break;
  }
  return type;
};

export const resetDefault = (expense) => {
  if (!_.get(expense, 'types.length')) {
    return expense;
  }
  const types = expense.types.map((type) => {
    return {
      ...type,
      ...COLLAPSE.TYPE,
    };
  });
  expense.types = types;
  return expense;
};

export const getBorrowersDropdown = (list, isUseUnequalDropdown) => {
  if (!list || !list.length) {
    return [];
  }

  let prependOption = [];
  let borrowersDropdown = [];

  if (isUseUnequalDropdown) {
    prependOption =
      list.length > SINGLE_OWNERSHIP
        ? [JOINT_OWNERSHIP_UNEQUALFF, SEPARATE_AMOUNT_UNEQUALFF]
        : list;
    borrowersDropdown = prependOption;
  } else {
    prependOption =
      list.length > SINGLE_OWNERSHIP ? [JOINT_OWNERSHIP_OPTION] : [];
    borrowersDropdown = [...prependOption, ...list];
  }

  return borrowersDropdown;
};

export const resetDeletePopover = (expense) => {
  if (!_.get(expense, 'types.length')) {
    return;
  }
  expense.types.forEach((type) => {
    type.showConfirmDeletePopover = false;
  });
};

export const setUnequalBorrowerIfAny = (livingExpenses, pureBorrowers = []) => {
  if (!livingExpenses) {
    return [];
  }
  const firstBorrowerId = _.get(pureBorrowers, '[0].BorrowerID', 0);
  return livingExpenses.map((livingExpense) => {
    let typeIdArrayToSetBorrowerId = [];
    let modifiedTypes = [];

    typeIdArrayToSetBorrowerId = livingExpense.types.reduce(
      (accum, current) => {
        if (current.borrowerId === JOINT_OWNERSHIP || !current.borrowerId) {
          return accum;
        } else {
          return [...accum, `${current.typeId}${current.description}`];
        }
      },
      [],
    );

    typeIdArrayToSetBorrowerId = [...new Set(typeIdArrayToSetBorrowerId)];

    modifiedTypes = livingExpense.types.map((type) => {
      if (
        typeIdArrayToSetBorrowerId.includes(
          `${type.typeId}${type.description}`,
        ) &&
        pureBorrowers.length > SINGLE_OWNERSHIP
      ) {
        return {
          ...type,
          borrowerId: UNEQUAL_OWNERSHIP,
        };
      } else {
        return {
          ...type,
          ...(pureBorrowers.length === SINGLE_OWNERSHIP && {
            borrowerId: firstBorrowerId,
          }),
        };
      }
    });

    return {
      ...livingExpense,
      types: modifiedTypes,
    };
  });
};

export const getUnequalBorrowers = (pureBorrowersList, borrowerId) => {
  let borrowers = [];

  if (borrowerId > 0) {
    borrowers = pureBorrowersList.filter(
      (borrower) => borrower.BorrowerID === borrowerId,
    );
  }

  if (borrowerId === JOINT_OWNERSHIP) {
    borrowers = pureBorrowersList;
  }

  return borrowers;
};

export const addGroupProp = (type) => {
  return `${type.borrowerId}-${type.description}-${type.type}`;
};

export const getGroupById = (types) => {
  const addedGroupProp = types.map((type) => ({
    ...type,
    groupBy: addGroupProp(type),
  }));

  return _.groupBy(addedGroupProp, (type) => {
    return addGroupProp(type);
  });
};

export const mapOtherTypesFromBorrowers = (
  pureBorrowersList,
  typeFromObject,
) => {
  return pureBorrowersList.map((borrower) => {
    const firstTypeBorrower = _.get(typeFromObject, 'borrowers[0]', []);
    if (firstTypeBorrower.BorrowerID === borrower.BorrowerID) {
      return typeFromObject;
    } else {
      return getAppendType({
        type: typeFromObject,
        borrowers: [borrower],
        borrowerId: UNEQUAL_OWNERSHIP,
      });
    }
  });
};

export const initTypes = (types, pureBorrowersList) => {
  if (!types) {
    return [];
  }
  const groupedByTypeId = getGroupById(types);

  return (
    Object.keys(groupedByTypeId).reduce((accum, currentType) => {
      let toAdd = [];

      groupedByTypeId[currentType].map((typeFromObject, key) => {
        const defaultTypeCondition =
          typeFromObject.borrowerId === JOINT_OWNERSHIP ||
          !typeFromObject.borrowerId ||
          pureBorrowersList.length === SINGLE_OWNERSHIP;
        if (defaultTypeCondition) {
          toAdd = [...toAdd, typeFromObject];
        } else {
          let otherTypes = [];
          if (groupedByTypeId[currentType].length === ONE_TYPE) {
            otherTypes = mapOtherTypesFromBorrowers(
              pureBorrowersList,
              typeFromObject,
            );
          } else if (groupedByTypeId[currentType].length === TWO_TYPES) {
            otherTypes = [groupedByTypeId[currentType][key]];
          } else {
            otherTypes = groupedByTypeId[currentType];
          }
          toAdd = [
            ...toAdd,
            ...((key === 0 && [getFillerType(typeFromObject)]) || []),
            ...otherTypes,
          ];
        }
      });

      return [...accum, ...toAdd];
    }, []) || []
  );
};

export const mapUnequalExpenseTypes = (expenseTypes, pureBorrowersList) => {
  if (!expenseTypes) {
    return [];
  }
  return expenseTypes.map((expense) => {
    return {
      ...expense,
      types: initTypes(expense.types, pureBorrowersList),
    };
  });
};

export const getFillerType = (type = {}, additionalProps = {}) => {
  return {
    ...type,
    ...additionalProps,
    owner: 'filler value',
    borrowerId: UNEQUAL_OWNERSHIP,
    financialId: -1,
    id: -1,
    value: 0,
    borrowers: [],
  };
};

export const getAppendType = ({
  type,
  borrowers,
  borrowerId,
  additionalProps = {},
  typeValueLookup,
}) => {
  const borrower = _.get(borrowers, '[0]', {});
  const owner = `${borrower.FirstName || ''} ${borrower.LastName || ''}`;

  return {
    ...type,
    ...additionalProps,
    frequencyId: FINANCIAL_FREQUENCY.MONTHLY,
    financialId: 0,
    id: 0,
    value: getTypeValueLookup({
      lookup: typeValueLookup,
      owner,
      borrowerId,
      typeId: type.typeId,
      description: type.description,
    }),
    borrowers,
    borrowerId,
    owner,
  };
};

export const setTypeValueLookup = (types) => {
  if (!types) {
    return {};
  }

  return types.reduce((accum, currentType) => {
    if (currentType.value) {
      accum[
        `${currentType.owner}${currentType.borrowerId}${currentType.typeId}${currentType.description}`
      ] = currentType.value;
    }
    return accum;
  }, {});
};

export const getTypeValueLookup = ({
  lookup = {},
  owner,
  borrowerId,
  typeId,
  description,
}) => {
  return lookup[`${owner}${borrowerId}${typeId}${description}`] || null;
};

export const getDefaultType = ({
  type,
  ownershipType,
  pureBorrowersList,
  typeValueLookup,
}) => {
  let defaultType = [];
  if (ownershipType === JOINT_OWNERSHIP) {
    defaultType = [
      {
        ...type,
        owner: JOINT_OWNERSHIP_OPTION.FirstName,
        financialId: 0,
        id: 0,
        value: getTypeValueLookup({
          lookup: typeValueLookup,
          owner: JOINT_OWNERSHIP_OPTION.FirstName,
          borrowerId: ownershipType,
          typeId: type.typeId,
          description: type.description,
        }),
        borrowerId: JOINT_OWNERSHIP,
        borrowers: getUnequalBorrowers(pureBorrowersList, type.borrowerId),
      },
    ];
  } else if (ownershipType === UNEQUAL_OWNERSHIP) {
    const appendTypes = pureBorrowersList.map((borrower) => {
      const gotBorrower = getUnequalBorrowers(
        pureBorrowersList,
        borrower.BorrowerID,
      );

      return getAppendType({
        type,
        borrowers: gotBorrower,
        borrowerId: ownershipType,
        typeValueLookup,
      });
    });

    defaultType = [getFillerType(type), ...appendTypes];
  } else {
    defaultType = [];
  }

  return defaultType;
};

export const getNewTypes = ({
  types,
  typeId,
  borrowerId,
  pureBorrowersList,
  typeValueLookup,
  description,
}) => {
  let newTypes = [];
  let removeTypeIndex = -1;
  const addedSortProp = types.map((type, index) => ({
    ...type,
    sortIndex: index,
  }));
  const toRemoveTypes =
    addedSortProp.filter((type, index) => {
      if (type.typeId === typeId && type.description === description) {
        removeTypeIndex =
          removeTypeIndex === -1
            ? index
            : (removeTypeIndex >= index && index) || removeTypeIndex;
        return type;
      }
    }) || [];
  const toRetainTypes =
    addedSortProp.filter(
      (type) => type.typeId !== typeId || type.description !== description,
    ) || [];
  newTypes = [
    ...getDefaultType({
      type: _.get(toRemoveTypes, '[0]', {}),
      ownershipType: borrowerId,
      pureBorrowersList,
      typeValueLookup,
    }),
  ];
  toRetainTypes.splice(removeTypeIndex, 0, ...newTypes);
  return toRetainTypes
    .map((type, index) => ({ ...type, sortIndex: index }))
    .sort((a, b) => a.sortIndex - b.sortIndex);
};

export const getOriginalTypes = (types, originalExpenseTypes) => {
  if (!types || !originalExpenseTypes) {
    return [];
  }
  const typeIdToSearch = types.find((type) => type.typeId).typeId || 0;
  return (
    originalExpenseTypes.find((expense) => {
      return expense.types.find((type) => type.typeId === typeIdToSearch);
    }) || []
  );
};

export const formatTypesFromUnequal = (types, originalExpenseTypes) => {
  if (!types || !originalExpenseTypes) {
    return [];
  }
  const filteredTypes = types.filter(
    (type) => type.financialId !== FILLER_FINANCIAL_ID,
  );
  const toReturn = filteredTypes.map((type) => {
    if (type.borrowerId === UNEQUAL_OWNERSHIP) {
      return {
        ...type,
        financialId: 0,
        id: 0,
        borrowerId: _.get(type, 'borrowers[0].BorrowerID'),
      };
    } else {
      return { ...type, financialId: 0, id: 0 };
    }
  });
  const originalTypes = getOriginalTypes(types, originalExpenseTypes);
  const mappedOriginalTypes = _.get(originalTypes, 'types', []).map((type) => ({
    ...type,
    isDeleted: true,
  }));

  return [...toReturn, ...mappedOriginalTypes];
};

export const mapTypeToAdd = (
  typeData,
  defaultTypeToAdd,
  pureBorrowersList,
  typeValueLookup,
) => {
  if (typeData.owner === JOINT_OWNERSHIP) {
    return [defaultTypeToAdd];
  } else {
    const foundOwner = pureBorrowersList.find(
      (borrower) => borrower.BorrowerID === typeData.owner,
    );
    const toReturnType = getDefaultType({
      type: defaultTypeToAdd,
      ownershipType: UNEQUAL_OWNERSHIP,
      pureBorrowersList,
      typeValueLookup,
    });
    return toReturnType.map((type) => {
      const ownerName =
        foundOwner?.PreferedName ||
        `${foundOwner?.FirstName} ${foundOwner?.LastName}`;
      if (ownerName === type.owner) {
        return {
          ...type,
          frequencyId: Number(typeData.frequencyId),
          value: typeData.value,
        };
      } else {
        return type;
      }
    });
  }
};
