import template from './headerLendingDetails.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    lendingDetails: '<',
  },
};
