export const QUESTION_ID = {
  APPLICANT_EMAIL_SUBJECT: 133,
  APPLICANT_EMAIL_CONTENT: 134,
  LENDER_EMAIL_SUBJECT: 84,
  LENDER_EMAIL_CONTENT: 85,
  SUBMISSION_EMAIL_TYPE: 280,
  ASSIGNED_ADVISER_EMAIL: 101,
  IS_URGENT: 211,
};

export const QUESTION_ID_AU = {
  RATIONALE_SUMMARY: 281,
  RATIONALE_INTEREST_RATE: 282,
  APPLICANT_EMAIL_SUBJECT: 215,
  APPLICANT_EMAIL_CONTENT: 216,
  INTERVIEWED_CLIENT_VIA: 307,
  INTERVIEWED_CLIENT_VIA_DETAILS: 308,
  CONFIRM_DECLARATION: 311,
  CONFIRM_DELCARATION_DETAILS: 312,
};

export const QUESTION_ID_NZ = {
  RATIONALE_SUMMARY: 289,
  INTERVIEWED_CLIENT_VIA: 309,
  INTERVIEWED_CLIENT_VIA_DETAILS: 310,
  CONFIRM_DECLARATION: 313,
  CONFIRM_DELCARATION_DETAILS: 314,
  SEND_LOAN_TO_OTHER: 320,
  OTHER_LOAN_RECIPIENT: 321,
  COPY_TO_CLIENT: 322,
  COPY_TO_ADVISER: 323,
  COPY_TO_ASSISTANT: 324,
};

export const INTERVIEWED_CLIENT_VIA_IDS = [
  QUESTION_ID_AU.INTERVIEWED_CLIENT_VIA,
  QUESTION_ID_NZ.INTERVIEWED_CLIENT_VIA,
];

export const CONFIRM_DECLARATION_IDS = [
  QUESTION_ID_AU.CONFIRM_DECLARATION,
  QUESTION_ID_NZ.CONFIRM_DECLARATION,
];

export const REQUIRED_DECLARATION_QUESTION_ID = [
  ...INTERVIEWED_CLIENT_VIA_IDS,
  ...CONFIRM_DECLARATION_IDS,
];

export const INTERVIEWED_CLIENT_VIA_DETAILS_IDS = [
  QUESTION_ID_AU.INTERVIEWED_CLIENT_VIA_DETAILS,
  QUESTION_ID_NZ.INTERVIEWED_CLIENT_VIA_DETAILS,
];

export const CONFIRM_DECLARATION_DETAILS_IDS = [
  QUESTION_ID_AU.CONFIRM_DELCARATION_DETAILS,
  QUESTION_ID_NZ.CONFIRM_DELCARATION_DETAILS,
];

export const DECLARATION_SUB_QUESTION_ID = [
  ...INTERVIEWED_CLIENT_VIA_DETAILS_IDS,
  ...CONFIRM_DECLARATION_DETAILS_IDS,
];

export const REQUIRED_RATIONALE_QUESTION_ID_AU = [
  QUESTION_ID_AU.RATIONALE_SUMMARY,
  QUESTION_ID_AU.RATIONALE_INTEREST_RATE,
];
export const REQUIRED_RATIONALE_QUESTION_ID_NZ = [
  QUESTION_ID_NZ.RATIONALE_SUMMARY,
];
