import { NOTES_TABLE_SIZE } from 'Common/constants/pageTable';
import { splitByCapitalLetters } from 'Common/utilities/string';

export default class MycrmTableCtrl {
  constructor(contactsNotesService) {
    'ngInject';

    this.contactsNotesService = contactsNotesService;
  }

  $onInit() {
    this.tableSizes = NOTES_TABLE_SIZE;
  }

  onTablePaginationChange({ pageNumber, pageSize, isPageSizeChanged = false }) {
    this.onPageChange({ pageNumber, pageSize });
    if (isPageSizeChanged) {
      this.contactsNotesService.setDisplayCount({ displayCount: pageSize });
    }
  }

  onSort(column) {
    if (!column.isSortable) {
      return;
    }

    const isCurrentColumn = column.value === this.sortColumn;
    const currentColumn = this.sortOrder === 'asc' ? 'desc' : 'asc';
    const sortOrder = isCurrentColumn
      ? currentColumn
      : column.defaultSort || 'asc';
    this.onSortColumn({ sortColumn: column.value, sortOrder });
  }

  getSortClass(column) {
    const isSortedColumn =
      column.isSortable && column.value === this.sortColumn;
    return isSortedColumn ? `sort-${this.sortOrder || 'asc'}` : '';
  }

  getClassName(value) {
    return splitByCapitalLetters(value, '-', true);
  }
}
