import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import toastr from 'toastr';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { SMS_SUBSCRIPTION } from 'Common/constants/smsSubcription';
import { convertUtcToUserDate } from 'Common/utilities/date';
import { adviserAllocateSwal } from 'Common/utilities/adviserAllocateSwal';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { toastError, toastSuccess, alertSucess } from 'Common/utilities/alert';
import {
  sendEmail,
  sendHelloBook,
  showPipelineLineInContact,
} from 'Common/utilities/contact';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';

import {
  PIPELINE_TYPES_STR,
  PIPELINE_TYPES_ID,
} from 'Common/constants/pipelineType';
import { COLOR } from 'Common/constants/colors';
import { BROKER_NOT_SUBSCRIBED_MSG } from 'Common/constants/creditCheck';
import {
  getClientInformationInContact,
  isActiveCreditContactUtil,
  sendReferral,
  getAgencies,
  templateLoader,
  setContactHomeAddress,
  setUtmValue,
  checkForDeceased,
  checkGenerateHelloBookUtil,
} from './util/contactDetailCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ContactDetailCtrl', function ContactDetailCtrl(
      $location,
      $scope,
      $timeout,
      $interval,
      $uibModal,
      $state,
      $stateParams,
      $filter,
      contactService,
      corporateService,
      mapService,
      $sce,
      SweetAlert,
      $rootScope,
      toaster,
      pipelineService,
      utilitiesService,
      loanScenarioService,
      contactSharedData,
      generalService,
      broadcastDataService,
      loanAppSharedData,
      commonFnService,
      timeoutService,
      uiService,
      $window,
      $log,
      modalRenderService,
      pipelineSharedData,
      clientInfoService,
      loanAppDetailsService,
      contactSharedDataService,
      currentUserService,
      opportunityNewModalService,
      configService,
      contactModelService,
      helloBookService,
      downloadDataService,
      helloBookPreviewService,
      stateService,
      sendReferralService,
      errorMessageHandler,
      crmConfirmation,
      creditCheckSubscriptionService,
      corporateModelService,
      dashboardService,
      creditCheckService,
    ) {
      if (!$state.params.familyId) {
        $state.go('app.dashboard');
        return;
      }
      $scope.familyId = $state.params.familyId;
      if ($scope.familyId) {
        dashboardService.storeRecentlyViewedContact($scope.familyId);
      }
      $scope.overrideContactCache = true;
      $scope.template = {
        summary: '',
        clients: '',
        addresses: '',
        employment: '',
        financials: '',
        lending: '',
        notes: '',
        communications: '',
        documents: '',
      };
      $scope.BROKER_NOT_SUBSCRIBED_MSG = BROKER_NOT_SUBSCRIBED_MSG;

      $scope.templateLoader = (value) => templateLoader({ $scope, value });
      $scope.getTemplate = (value) => $scope.template[value];
      $scope.isShowPipelineLine = showPipelineLineInContact({
        currentUserService,
      });

      templateLoader({
        $scope,
        value: $state.params.activeTab || 'summary',
      });

      // will track any change of route on same view
      const trackRoutesOnSamePage = $rootScope.$on(
        '$locationChangeStart',
        () => {
          const path = $location.path();
          const isContactDetails = path.includes('app/contacts');
          if (!isContactDetails) {
            return false;
          }
          const pathArr = $location.path().split('/');
          const section = _.last(pathArr);
          templateLoader({
            $scope,
            value: section,
          });
        },
      );

      $scope.$on('destroy', () => {
        trackRoutesOnSamePage();
      });

      const getClientInformation = getClientInformationInContact({
        $scope,
        configService,
        clientInfoService,
        contactSharedData,
      });

      creditCheckSubscriptionService.creditCheck = {
        isAllocatedAdviserSubscribed: false,
        isCreditCheckDisabled: true,
      };

      const onCreditGuideModalClose = (response) => {
        if ($scope.isActiveCreditContact) {
          $state.reload();
          return;
        }
        $scope.handleCreditCheckModalResponse(response);
      };

      $scope.openNewCreditCheckModal = () => {
        creditCheckService.openNewCreditCheckModal({
          designatedBrokerInfo: $scope.designatedBrokerInfo,
          familyId: $scope.familyId,
          clientHeaderName: $scope.clientHeaderName,
          onModalClose: onCreditGuideModalClose,
        });
      };
      const stateTransition = (tab) => {
        $state.params.activeTab = tab;
        stateService.transition($state.current, $state.params);
      };
      $scope.routeContactList = stateService.routeToContactList;
      // state params
      $scope.brokerBasicinfo = {};
      $scope.uiService = uiService;
      $scope.currentUserService = currentUserService;
      const {
        nurture,
        customerIOEmailImport,
        bccEmailSync,
        CPACQPGeneration,
        multiLoanCpa,
      } = configService.feature;
      $scope.showNurture = nurture || 0;
      $scope.showHelloBook = uiService.showHelloBook;
      $scope.showGenerateHelloBook = uiService.showGenerateHelloBook;
      $scope.showCustomerIOEmailImport = customerIOEmailImport || 0;
      $scope.showDisclosureGuide = uiService.showDisclosureGuide;
      $scope.isCPACQPGenerationOff =
        !CPACQPGeneration && currentUserService.isAU;
      $scope.showMultiLoanCPA = !currentUserService.isNZ && multiLoanCpa;
      $scope.isActiveCreditContact = isActiveCreditContactUtil({
        configService,
        currentUserService,
      });
      getAgencies({ $scope, sendReferralService, currentUserService });
      $scope.sendReferral = sendReferral({
        $scope,
        $uibModal,
        contactService,
        errorMessageHandler,
        crmConfirmation,
        $state,
      });
      $scope.sendEmail = sendEmail({ $window, $state, $stateParams, $scope });

      corporateService
        .corporateContactGet(0, $scope.familyId)
        .then(({ data }) => {
          $scope.clientInfo = data;
        });

      generalService
        .isClientNotCorpLockedOut($stateParams.familyId)
        .then((res) => {
          if (!res.data) {
            if (res.data !== null) {
              swal(
                'Security Restriction',
                'Access to this record is restricted for Corporate users',
                'error',
              );
            }
            ($window.history.length > 2 && $window.history.back()) ||
              $scope.routeContactList();
          }
        });

      // Variables
      $rootScope.contacts = {};

      //  Normal Contact
      $scope.tabs = [];
      $scope.tabs.summary = 0;
      $scope.tabs.clients = 1;
      $scope.tabs.addresses = 2;
      $scope.tabs.lending = 3;
      $scope.tabs.insurance = 4;
      $scope.tabs.financials = 5;
      $scope.tabs.employment = 6;
      $scope.tabs.workflow = 7;
      // $scope.tabs['loan_application']  = 7;
      $scope.tabs.records = 8;
      $scope.tabs.communications = 9;
      $scope.tabs.notes = 10;
      $scope.tabs.documents = 11;
      $scope.defaultTabOnloadAction = '';
      $scope.contactHomeAddress = '';
      $scope.contactHomeAddressID = 0;
      $scope.allocatedAdviser = [];
      $scope.lastTwoSubstatusesArr = [];

      $scope.active = $state.params.activeTab || 'summary';

      $scope.contactModel = {
        isSmsEnabled: false,
        isCorporateUser: false,
      };
      $scope.aLIQuoteEnabled = false;
      $scope.showSystemInfo = false;

      $scope.toggleDropDown = (bool) => {
        $scope.showSystemInfo = bool;
      };

      const getHasAutomationMarketing = () => {
        if (!$scope.familyId || !customerIOEmailImport) {
          return;
        }
        contactModelService
          .getHasAutomationMarketing($scope.familyId)
          .then((response) => {
            if (!response) {
              return;
            }
            $scope.showHasAutomationMarketing = response.hasAutomationMarketing;
          });
      };

      getHasAutomationMarketing();

      const getSmsEnabledSettings = (adviserFamilyId) => {
        corporateService
          .familyBrokerSupportGet(adviserFamilyId, 0)
          .then((response) => {
            if (!response || !response.data) {
              return false;
            }
            const { data } = response;
            $scope.contactModel.isSmsEnabled =
              data.SMSEnabled && $scope.contactModel.isSmsEnabled;
          });
      };
      function isCorporateUser() {
        const { accessType, sMSEnabled } = currentUserService;
        $scope.contactModel.isCorporateUser =
          accessType === ACCESS_TYPE.CORPORATE;
        const assistantTypeList = [
          ACCESS_TYPE.ADMIN_ASSISTANT,
          ACCESS_TYPE.ASSISTANT,
        ];
        if (assistantTypeList.includes(accessType)) {
          contactService
            .getContactAssignedAdviser($scope.familyId)
            .then((res) => {
              if (!res || !res.data) {
                return;
              }
              getSmsEnabledSettings(res.data);
            });
        } else {
          $scope.contactModel.isSmsEnabled =
            sMSEnabled && $scope.contactModel.isSmsEnabled;
        }
      }

      $scope.selectNormalContacts = function (selectedContacts, defaultAction) {
        if (defaultAction === 'inprogressLoans' || defaultAction === 'loans') {
          contactSharedData.summaryData.destinationTabToLending = defaultAction;
        }
        $scope.selectedContacts = selectedContacts;
        stateTransition(selectedContacts);
        if (defaultAction) {
          $scope.defaultTabOnloadAction = defaultAction;
        }
      };

      $scope.goToTabAndOpenModal = function (tab, defaultAction) {
        $scope.selectNormalContacts(tab, defaultAction);
      };

      $scope.openCreditGuideModal = () => {
        const contactDetails = {
          familyID: $scope.familyId,
          clientName: $scope.clientHeaderName,
          familyInfo: $scope.getContactFamilyInfo,
          adviserName: $scope.adviserName,
          clients: $scope.client.information,
        };
        const props = {
          contactDetails,
        };
        $uibModal.open({
          template: `<send-credit-guide-modal modal-instance="vm.modalInstance" contact-details="vm.props.contactDetails"></send-credit-guide-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props: () => props,
          },
        });
      };

      $scope.refreshNotes = () => {
        $scope.$broadcast('REFRESH_NOTES_LIST', true);
      };

      let actionButtonInterval = '';
      if ($scope.familyId) {
        if ($state.params.activeTab) {
          $scope.tabs &&
            Object.keys($scope.tabs).forEach((x) => {
              if (x === $state.params.activeTab) {
                $scope.selectedContacts = x;
                $scope.active = x;
              }
            });
          // Please don't remove this!
          if (
            $scope.active === 'lending' &&
            $state.params.loanId &&
            $state.params.inprogress
          ) {
            actionButtonInterval = $interval(() => {
              const $ele = angular.element(
                `#loansContainer .loan-detail-item #loan-${$state.params.loanId} .lending-manage-btn .lending-manage-menu li.menu-section:nth-child(1) ul.menu-items li a`,
              );
              if ($ele.length) {
                $interval.cancel(actionButtonInterval);
                const eventTimeout = $timeout(() => {
                  $ele.click();
                }, 0);
                $scope.$on('$destroy', () => $timeout.cancel(eventTimeout));
              }
            }, 1000);
          }
        } else {
          $scope.selectedContacts = 'summary';
        }
        $scope.selectNormalContacts($scope.selectedContacts);
      }

      $scope.pipelineBanner = true;
      $scope.cancelPipelineBanner = function () {
        $scope.pipelineBanner = false;
      };
      $scope.cardsMoveToNextStatus = {};
      $scope.pipelineType = '';
      $scope.cardId = 0;
      $scope.isolateStatus = '';
      $scope.nextPtr = 1;
      $scope.nextLocation = {};

      $scope.$watch('nextPtr', () => {
        switch ($scope.nextPtr) {
          case 2:
            $scope.nextLocation = { left: 345 };
            break;
          case 3:
            $scope.nextLocation = { left: 645 };
            break;
          case 4:
            $scope.nextLocation = { left: 944 };
            break;
          case 5:
            $scope.nextLocation = { left: 1244 };
            break;
          default:
            $scope.nextLocation = { display: 'none' };
            break;
        }
      });

      $scope.leadStat = false;
      $scope.appStat = false;
      pipelineService.SettingsStatusGetFiltered().then((response) => {
        $scope.pipelineStatusList = [];
        const pipelineStatusList = [];
        _.forEach(response.data, (o) => {
          if (o.TypeName === 'Lead') {
            pipelineStatusList.push({
              StatusID: o.PipelineStatusID,
              SubStatusName: 'Please select',
              pipelineSubStatusID: 0,
            });

            _.forEach(o.SubStatus, (sub) => {
              pipelineStatusList.push(sub);
            });
          }
        });

        $scope.pipelineStatusList = pipelineStatusList;
      });
      $scope.isLast2Chosen = (cardObject, subStatusId) => {
        const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
          {
            showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
              $scope.getContactFamilyInfo.ReferralItem,
            ),
          },
        );
        confirmationPopup.result.then((result) => {
          if (result.isOk) {
            pipelineService
              .PipelineCardsMoveToNextStatusSet({
                PipelineCardsID: cardObject.PipelineCardsID,
                ToStatusID: $scope.notProceededObject.PipelineStatusID,
                reasonSubstatusID: subStatusId,
                shouldSendNurtureCampaigns: result.shouldSendNurtureCampaigns,
              })
              .then(() => {
                $scope.PipelineCardsTimeLineViewGet();
              });
          }
        });
      };
      $scope.changeSubStatus = function (cardObj, subStatusId) {
        const isLast2 = _.includes($scope.lastTwoSubstatusesArr, subStatusId);
        if (isLast2) {
          $scope.isLast2Chosen(cardObj, subStatusId);
        } else {
          pipelineService
            .PipelineCards(cardObj.PipelineCardsID, {
              SubStatusID: cardObj.SubStatusId,
            })
            .then((response) => {
              toaster.pop(
                'success',
                'Set',
                'Substatus has been Set successfully',
              );
              if (response.data.Result) {
                cardObj.LastModifiedDate = response.data.LastUpdate;
              }
            })
            .catch(toastError);
        }
      };

      $scope.reactiveateLead = () => {
        if (!$scope.isAllowLeadReactivation) {
          return;
        }
        swal(
          {
            title: 'Please confirm action',
            text: 'Are you sure you want to Reactivate Lead?',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: COLOR.SCOOTER,
            confirmButtonText: 'Confirm',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              const params = {
                clientFamilyId: $scope.familyId,
                pipelineStatusID: PIPELINE_STATUS.NEW_LEADS,
                isLeadReactivate: $scope.isAllowLeadReactivation,
              };
              pipelineService.PipelineCardsSet(params).then((response) => {
                if (!response.data) {
                  return;
                }
                alertSucess('Lead successfully reactivated.');
                $scope.PipelineCardsTimeLineViewGet();
              });
            }
          },
        );
      };

      $scope.splitContact = () => {
        if (!$scope.showContactSplit) {
          return;
        }
        const props = {
          adults: $scope.adults,
          familyId: $scope.familyId,
        };
        const modalInstance = $uibModal.open({
          template: `<contact-split-modal
                  modal-instance="vm.modalInstance"
                  adults="vm.props.adults"
                  family-id="vm.props.familyId"
                  >
                </contact-split-modal>`,
          size: 'lg',
          windowClass: 'contact-split-modal',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
        modalInstance.result.then((isSelectedPrimaryContact) => {
          if (!isSelectedPrimaryContact) {
            return;
          }
          $scope.getSummaryData({}, isSelectedPrimaryContact);
          $scope.selectNormalContacts('summary');
        });
      };

      $scope.PipelineCardsTimeLineViewGet = () => {
        pipelineService
          .getPipelineCardsTimeLineView($scope.familyId)
          .then((response) => {
            const { timeLineList: data, isAllowLeadReactivation } = response;
            $scope.isAllowLeadReactivation = isAllowLeadReactivation;
            const firstStatus = data && data.length && data[0];
            if (!data || !data.length) {
              $scope.timelineData = [];
            }
            if (
              !firstStatus ||
              firstStatus.PipelineStatusID === PIPELINE_STATUS.NEW_OPPORTUNITY
            ) {
              return;
            }

            $scope.timelineData = data;
            $scope.inProgress = false;
            $scope.almostDone = false;
            $scope.done = false;
            $scope.adjustLineIndex = -1;
            $scope.loanAmount = 0;
            $scope.loanScenarioId = 0;
            $scope.lender = '';
            $scope.lenderId = '';
            $scope.country = '';
            $scope.countHistoryIndex = -1;
            $scope.createdDate = '';

            for (let i = 0; i < $scope.timelineData.length; i++) {
              $scope.timelineData[i].done = false;
              if ($scope.timelineData[i].HistoryDate) {
                const rangeTime = _.range(0, i);
                _.forEach(rangeTime, (index) => {
                  // check if previous timeline is not done yet current index timeline is done
                  if ($scope.timelineData[i].HistoryDate) {
                    $scope.timelineData[index].done = true;
                    $scope.adjustLineIndex = index + 1;
                  }
                });
                $scope.statusType = $scope.timelineData[i];
                $scope.timelineData[i].done = true;
                $scope.cardsMoveToNextStatus.PipelineCardsID =
                  $scope.timelineData[i].PipelineCardsID;
                $scope.pipelineType = $scope.timelineData[i].PipelineType;
                $scope.loanAmount = $scope.timelineData[i].LoanAmount;
                $scope.lender = $scope.timelineData[i].Lender;
                $scope.loanScenarioId = $scope.timelineData[i].LoanScenarioID
                  ? $scope.timelineData[i].LoanScenarioID
                  : 0;
                $scope.cardId = $scope.timelineData[i].PipelineCardsID;
                $scope.lenderId = $scope.timelineData[i].LenderID;
                $scope.country = $scope.timelineData[i].Country;
                $scope.countHistoryIndex = i;

                if ($scope.timelineData[i].done) {
                  $scope.nextPtr = $scope.timelineData[i].Sequence + 1;
                }
              } else if (
                !$scope.timelineData[i].done &&
                $scope.adjustLineIndex === -1
              ) {
                $scope.adjustLineIndex = i - 1;
              }
            }
            let timelineData;
            if ($scope.countHistoryIndex >= -1) {
              timelineData = $scope.timelineData[$scope.countHistoryIndex];
            } else {
              $scope.timelineData = [];

              return;
            }

            /**
             * continue if timelineData has value
             * */

            if (timelineData) {
              $scope.adviserName = timelineData.Adviser;
              $scope.createdDate = timelineData.HistoryDate;

              $scope.cardDetails = angular.copy(timelineData);

              const dateArr = $scope.createdDate.split('T');
              const splittedDate = dateArr[0];

              const dateFull = new Date(splittedDate);
              const date = dateFull.getDate();
              const today = new Date();
              const todaysDate = today.getDate();
              if (date === todaysDate) {
                $scope.day = 'Today';
              } else if (date === todaysDate - 1) {
                $scope.day = 'Yesterday';
              } else {
                $scope.day = '';
              }

              $scope.getStatus($scope.pipelineType, $scope.statusType);
              $scope.getLoanApplications($scope.cardId);

              const offsetArray = [0, 0, 54, 42, 35, 35, 33, 31, 30, 30];
              if ($scope.timelineData.length > 9) {
                $scope.offsetForPileline = 20 + $scope.timelineData.length;
              } else {
                $scope.offsetForPileline =
                  offsetArray[$scope.timelineData.length];
              }
            } else {
              $scope.timelineData = [];
            }
          });
      };

      contactSharedDataService.refreshLoanForLead = () =>
        $scope.PipelineCardsTimeLineViewGet();

      // auto-updated-when-changed-listener
      $scope.$watch(
        () => {
          return contactSharedData.lendingSharedData.updateLinkToLoan;
        },
        () => {
          const updateLinkToLoan =
            contactSharedData.lendingSharedData.updateLinkToLoan;
          if (updateLinkToLoan) {
            contactSharedData.lendingSharedData.updateLinkToLoan = false;
            if ($scope.cardId) {
              $scope.getLoanApplications($scope.cardId);
            } else {
              $scope.PipelineCardsTimeLineViewGet();
            }
          }
        },
      );

      $scope.$watch(
        () => {
          return contactSharedData.lendingSharedData.referenceUpdate;
        },
        (nv, ov) => {
          if (nv.referenceNumber !== ov.referenceNumber && !nv.isUpdating) {
            $scope.lendingSharedData.referenceUpdate = nv;
            if (
              nv.referenceNumber !== 0 &&
              nv.loanScenarioID === $scope.loanScenarioId
            ) {
              $scope.referenceNo = nv.referenceNumber;
            }
          }
        },
      );

      angular.extend($scope, {
        lendingSharedData: {},
        getLoanDetails() {
          if (
            $scope.timelineData[0] &&
            $scope.timelineData[0].LoanScenarioID &&
            typeof $scope.timelineData[0].LoanScenarioID !== 'undefined'
          ) {
            loanScenarioService
              .scenarioDetailsGet($scope.timelineData[0].LoanScenarioID)
              .then((response) => {
                if (
                  typeof response.data !== 'undefined' &&
                  response.data &&
                  parseInt(response.data.LoanId, 10)
                ) {
                  contactService
                    .loanDetailsGet(response.data.LoanId)
                    .then(() => {
                      $scope.referenceNo =
                        (response.data.loan &&
                          response.data.loan.ReferenceNumber) ||
                        null;
                    });
                }
              });
          }
        },
        getLoanScenarioDetails(scenarioId) {
          loanScenarioService
            .scenarioDetailsGet(scenarioId)
            .then((response) => {
              if (response.data && parseInt(response.data.LoanId, 10)) {
                $scope.saveReferenceNo(response.data.LoanId);
              } else {
                toaster.pop('error', 'Unable to set get loan details');
              }
            });
        },
        saveReferenceNo(loanId) {
          contactService
            .loanImportantDatesSet(loanId, '', '', $scope.storedRefno)
            .then((response) => {
              if (response.status === 200) {
                toaster.pop(
                  'success',
                  'Reference number updated successfully.',
                );
                contactSharedData.pipelineStatusData.referenceUpdate = {
                  LoanScenarioID: $scope.loanScenarioId,
                  ReferenceNumber: $scope.storedRefno,
                  isUpdating: false,
                };
              } else {
                toaster.pop('warning', 'Reference number failed to update.');
              }
            });
        },
        item: {
          storedRefno: '',
          updateReference(refno) {
            if (refno && refno !== $scope.storedRefno) {
              $scope.storedRefno = refno;
              // get loanId using LoanScenarioID
              if (
                $scope.timelineData &&
                $scope.timelineData.length > 0 &&
                typeof $scope.timelineData[0].LoanScenarioID !== 'undefined'
              ) {
                $scope.getLoanScenarioDetails(
                  $scope.timelineData[0].LoanScenarioID,
                );
              }
            } else {
              $timeout(() => {
                contactSharedData.pipelineStatusData.referenceUpdate.isUpdating = false;
              }, 1000);
            }
          },
          storeReference(refno) {
            $scope.storedRefno = refno;
            contactSharedData.pipelineStatusData.referenceUpdate = {
              LoanScenarioID: $scope.loanScenarioId,
              ReferenceNumber: $scope.storedRefno,
              isUpdating: true,
            };
          },
        },
      });

      $scope.$watch('timelineData', () => {
        if (
          $scope.timelineData &&
          $scope.timelineData.length > 0 &&
          typeof $scope.timelineData[0].LoanScenarioID !== 'undefined'
        ) {
          $scope.getLoanDetails();
        }
      });

      // watcher for changes in inProgress Loans
      $scope.$watch(
        () => {
          return contactSharedData.lendingSharedData.inProgressToPipeline;
        },
        () => {
          const obj = contactSharedData.lendingSharedData.inProgressToPipeline;
          if (obj.statusObj.PipelineStatusID) {
            $scope.PipelineCardsTimeLineViewGet();
          }
        },
      );

      $scope.PipelineCardsTimeLineViewGet();
      $scope.getLoanApplications = function (cardId) {
        loanScenarioService
          .scenarioListGet($scope.familyId)
          .then((response) => {
            $scope.loanApplications = response.data;
            for (let i = 0; i < $scope.loanApplications.length; i++) {
              $scope.formattedDate = $filter('date')(
                $scope.loanApplications[i].DateCreated,
                'dd MMM yyyy',
              );
              let textField;
              if (
                typeof $scope.loanApplications[i].LenderName === 'undefined'
              ) {
                textField = $scope.formattedDate;
              } else {
                textField = `${$scope.loanApplications[i].LenderName} ${$scope.formattedDate}`;
              }
              $scope.loanApplications[i].loanAppName = textField;
              $scope.loanApplications[i].PipelineCardID = cardId;
            }
            // filter NotProceeded LoanTypes
            $scope.loanApplications = _.filter($scope.loanApplications, (l) => {
              return l.StatusName === 'Ongoing';
            });

            $scope.loanApplications.splice(0, 0, {
              LoanScenarioId: -1,
              loanAppName: 'Select Loan Application',
              PipelineCardID: cardId,
            });
            $scope.loanApp = $scope.loanApplications[0];
          });
      };
      $scope.linkLoanToPipeline = function (loanApp) {
        if (parseInt(loanApp.LoanScenarioId, 10) === -1) {
          toaster.pop(
            'error',
            'Error',
            'Yikes! Please select valid Loan Application',
          );
        }
        $scope.loanAppObj = {};
        $scope.loanAppObj.pipelineCardsID = loanApp.PipelineCardID;
        $scope.loanAppObj.loanScenarioID = loanApp.LoanScenarioId;

        pipelineService
          .PipelineCardsLoanScenarioSet($scope.loanAppObj)
          .then(() => {
            toaster.pop(
              'success',
              'Linked',
              'Loan Application has been linked successfully.',
            );
            $scope.PipelineCardsTimeLineViewGet();
            loanAppDetailsService.loadLoanList = true;
          })
          .catch(toastError);
      };
      $scope.getStatus = function (pipelineType, statusType) {
        if (_.isUndefined(statusType)) {
          return false;
        }

        pipelineService.SettingsStatusGetFiltered().then((response) => {
          if (!response.data) {
            return;
          }
          $scope.statuses = response.data;

          const settled = {
            BrokerBrandingID: 0,
            CategoryName: 'Application in Progress',
            PipelineCategoryID: 2,
            PipelineStatus: 'Settled',
            PipelineStatusID: 100,
            TypeName: 'Application',
          };
          const notProceeded = {
            BrokerBrandingID: 0,
            CategoryName: 'Application in Progress',
            PipelineCategoryID: 2,
            // eslint-disable-next-line sonarjs/no-duplicate-string
            PipelineStatus: 'Not Proceeded',
            PipelineStatusID: 101,
            TypeName: 'Application',
          };
          const convertToApplication = {
            BrokerBrandingID: 0,
            CategoryName: 'New Lead',
            PipelineCategoryID: 1,
            PipelineStatus: 'Convert To Application',
            PipelineStatusID: 7,
            TypeName: 'Lead',
          };
          const convertToOpportunity = {
            BrokerBrandingID: 0,
            CategoryName: 'New Opportunity',
            PipelineCategoryID: 3,
            PipelineStatus: 'Convert To Opportunity',
            PipelineStatusID: 13,
            TypeName: 'Lead',
          };
          const notProceededLead = {
            BrokerBrandingID: 0,
            CategoryName: 'New Lead',
            PipelineCategoryID: 1,
            PipelineStatus: 'Not Proceeded',
            PipelineStatusID: 101,
            TypeName: 'Lead',
          };

          $scope.statuses.push(settled);
          $scope.statuses.push(notProceeded);
          $scope.statuses.push(convertToApplication);
          $scope.statuses.push(convertToOpportunity);
          $scope.statuses.push(notProceededLead);
          $scope.leadsStatus = [];
          $scope.applicationStatus = [];
          $scope.notProceededObject = [];
          for (let i = 0; i < $scope.statuses.length; i++) {
            if (statusType.PipelineType === 'Leads') {
              $scope.leadStat = true;
              $scope.appStat = false;
              if ($scope.statuses[i].TypeName === 'Lead') {
                $scope.leadsStatus.push($scope.statuses[i]);
              }

              _.remove($scope.pipelineStatusList, (o) => {
                return (
                  o.TypeName === 'Application' || o.TypeName === 'Opportunity'
                );
              });
            } else {
              $scope.appStat = true;
              $scope.leadStat = false;
              if ($scope.statuses[i].TypeName === 'Application') {
                $scope.applicationStatus.push($scope.statuses[i]);
              }

              _.remove($scope.pipelineStatusList, (o) => {
                return o.TypeName === 'Lead' || o.TypeName === 'Opportunity';
              });
            }
          }
          $scope.notProceededObject = _.find(
            $scope.leadsStatus,
            (objectLeadStatus) =>
              objectLeadStatus.PipelineStatus === 'Not Proceeded',
          );
          if (!statusType.PipelineStatusID) {
            $scope.statusTypeLead = $scope.leadsStatus[0];
          } else {
            for (let j = 0; j < $scope.leadsStatus.length; j++) {
              if (
                parseInt($scope.leadsStatus[j].PipelineStatusID, 10) ===
                parseInt(statusType.PipelineStatusID, 10)
              ) {
                $scope.statusTypeLead = $scope.leadsStatus[j];
                return;
              }
            }
          }

          if (statusType.PipelineStatusID === undefined) {
            $scope.statusTypeApp = $scope.applicationStatus[0];
          } else {
            for (let k = 0; k < $scope.applicationStatus.length; k++) {
              if (
                parseInt($scope.applicationStatus[k].PipelineStatusID, 10) ===
                parseInt(statusType.PipelineStatusID, 10)
              ) {
                $scope.statusTypeApp = $scope.applicationStatus[k];
                return;
              }
            }
          }
        });
      };
      $scope.leadStat = false;
      $scope.appStat = false;

      $scope.$watch(
        () => {
          return loanAppSharedData.lendingTabPipelineStatusData;
        },
        (nv) => {
          if (nv && nv.PipelineStatusID) {
            $scope.PipelineCardsTimeLineViewGet();
            $scope.pipelineBanner = true;
            const statusChanged = {
              LoanScenarioID: $scope.loanScenarioId,
              StatusID: nv.PipelineStatusID,
            };
            if ($scope.statusTypeApp && $scope.statusTypeApp.PipelineStatusID) {
              statusChanged.StatusID = $scope.statusTypeApp.PipelineStatusID;
            }

            contactSharedData.pipelineStatusData.statusUpdate = statusChanged;
          }
        },
        true,
      );

      $scope.pipelineCardsMoveNextProcedure = (pipelineStatus) => {
        pipelineService
          .PipelineCardsMoveToNextStatusSet($scope.cardsMoveToNextStatus)
          .then(() => {
            toaster.pop(
              'success',
              'Moved',
              'Status has been updated successfully.',
            );
            $rootScope.$broadcast('UPDATE_LENDING_ON_CHANGE_STATUS', true);
            $scope.PipelineCardsTimeLineViewGet();
            $scope.pipelineBanner = true;
            const statusChanged = {
              LoanScenarioID: $scope.loanScenarioId,
              StatusID: pipelineStatus.StatusID,
            };
            if (
              $scope.statusTypeApp &&
              typeof $scope.statusTypeApp.PipelineStatusID !== 'undefined'
            ) {
              statusChanged.StatusID = $scope.statusTypeApp.PipelineStatusID;
            }

            contactSharedData.pipelineStatusData.statusUpdate = statusChanged;
          })
          .catch(toastError);
      };

      $scope.openPipelineAppMovedToSettledModal = function (
        size,
        familyID,
        LoanScenarioID,
        pipelineStatus,
      ) {
        loanScenarioService
          .scenarioDetailsGet(LoanScenarioID)
          .then((response) => {
            const $modalInstance = $uibModal.open({
              templateUrl:
                '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
              controller: 'PipelineAppMoveToSettledModalCtrl',
              resolve: {
                LoanScenarioID: () => response.data.LoanId,
                familyID: () => familyID,
              },
              size,
            });

            $modalInstance.result.then(
              () => {
                $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
              },
              () => {
                $scope.statusTypeApp.PipelineStatusID =
                  $scope.statusType.PipelineStatusID;
              },
            );
          });
      };

      $scope.updateStage = function (pipelineStatus, origValueInString) {
        $scope.cardsMoveToNextStatus.ToStatusID =
          pipelineStatus.PipelineStatusID;
        if (
          $scope.cardsMoveToNextStatus.ToStatusID ===
          LOAN_APP_STATUS_TYPES.NOT_PROCEEDED_STATUS_ID
        ) {
          $scope.updateStageIsNotProceeding(pipelineStatus, origValueInString);
        } else if (
          $scope.cardsMoveToNextStatus.ToStatusID ===
            LOAN_APP_STATUS_TYPES.SETTLE_STATUS_ID &&
          pipelineStatus.TypeName === 'Application' &&
          $scope.loanScenarioId
        ) {
          $scope.openPipelineAppMovedToSettledModal(
            'md',
            $scope.familyId,
            $scope.loanScenarioId,
            pipelineStatus,
          );
        } else if (
          $scope.cardsMoveToNextStatus.ToStatusID ===
          PIPELINE_STATUS.NEW_OPPORTUNITY
        ) {
          const isContactOpener = 1;
          const tabName = 'details';
          const sourceFamilyId = $scope.familyId;
          const redirectParams = { sourceFamilyId, isContactOpener, tabName };

          leadsToOpportunityConvert(
            $scope.familyId,
            contactService,
            opportunityNewModalService,
            redirectParams,
            $scope.cardId,
          );
        } else if (
          $scope.statusType.PipelineStatusID !== pipelineStatus.PipelineStatusID
        ) {
          $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
        }
      };

      $scope.getApplicationReasonList = () => {
        pipelineService
          .getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
          .then(({ reasonList }) => {
            $scope.applicationReasonList = reasonList || null;
          });
      };
      $scope.getApplicationReasonList();

      $scope.populateSettingsReasonList = () => {
        pipelineService.settingsReasonGet().then((response) => {
          if (response.data) {
            $scope.settingsReasonList = response.data;
            _.forEach($scope.settingsReasonList, (object) => {
              $scope.lastTwoSubstatusesArr.push(object.PipelineSubStatusID);
            });
          }
        });
      };
      $scope.populateSettingsReasonList();
      $scope.updateStageIsNotProceeding = (
        pipelineStatus,
        origValueInString,
      ) => {
        const isLead = pipelineStatus.TypeName === 'Lead';
        const isApplication =
          pipelineStatus.TypeName.toLowerCase() ===
          PIPELINE_TYPES_STR.APPLICATION;
        const reasonListLead = isLead && $scope.settingsReasonList;
        const reasonListApplication =
          isApplication && $scope.applicationReasonList;

        const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
          {
            reasonList: reasonListLead || reasonListApplication,
            showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
              $scope.getContactFamilyInfo.ReferralItem,
            ),
          },
        );
        confirmationPopup.result.then((result) => {
          if (result.isOk) {
            if (isLead || isApplication) {
              $scope.cardsMoveToNextStatus.reasonSubstatusID =
                result.selectedReasonId;
            }
            $scope.cardsMoveToNextStatus.shouldSendNurtureCampaigns =
              result.shouldSendNurtureCampaigns;
            $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
          } else {
            pipelineStatus.PipelineStatusID = Number(origValueInString);
          }
        });
      };
      // pipeline-end
      $scope.formatDate = function (date) {
        return new Date(date);
      };

      $scope.hiddenTabs = false;
      $scope.narrowNav = true;

      $scope.openHiddenTabs = function () {
        $scope.hiddenTabs = $scope.hiddenTabs === false;
      };

      $scope.closeHiddenTabs = function () {
        $scope.hiddenTabs = false;
      };
      $scope.open = function (size) {
        const modalInstance = $uibModal.open({
          templateUrl: 'insuranceDetailsModal.html',
          controller: 'ModalInstanceCtrl',
          size,
          resolve: {
            items() {
              return $scope.items;
            },
          },
        });
        modalInstance.result.then(
          (selectedItem) => {
            $scope.selected = selectedItem;
          },
          () => {
            $log.info(`Modal dismissed at: ${new Date()}`);
          },
        );
      };

      $scope.filterInitial = function (FirstName, LastName) {
        return utilitiesService.filterInitial(FirstName || '', LastName || '');
      };

      // Client Summary Data
      // ------------------------------------------------------------
      $scope.contactFamilyAddress = function () {
        if ($scope.familyId) {
          contactService
            .contactFamilyInfoGet($scope.familyId, $scope.overrideContactCache)
            .then((response) => {
              const { data } = response;
              $scope.getContactFamilyInfo = {
                ...data,
                AdviserId: data.BrokerID,
              };
              setUtmValue({ $scope, $timeout, contactSharedDataService, data });
              if (
                typeof $scope.getContactFamilyInfo.BrokerID !== 'undefined' &&
                $scope.getContactFamilyInfo.BrokerID !== null
              ) {
                $scope.getContactFamilyInfo.BrokerID = $scope.getContactFamilyInfo.BrokerID.toString();
              } else {
                $scope.getContactFamilyInfo.BrokerID = 0;
              }
              $scope.getContactFamilyInfo.AdviserFullNameInitials = utilitiesService.filterInitialOneString(
                $scope.getContactFamilyInfo.AdviserFullName,
              );

              $scope.getContactFamilyInfo.Source = $scope.getContactFamilyInfo
                .ReferralCategory
                ? $scope.getContactFamilyInfo.ReferralCategory.split('-')
                : null;

              angular.forEach(
                $scope.getContactFamilyInfo.Addresses,
                (value) => {
                  const contactValueAddress =
                    typeof value.formatted_address !== 'undefined'
                      ? value.formatted_address
                      : '';
                  const contactValueAddressID =
                    typeof value.AddressID !== 'undefined'
                      ? value.AddressID
                      : 0;
                  if (
                    value.Type === 'Home' ||
                    value.Type === 'Current Address'
                  ) {
                    $scope.contactHomeAddressID = contactValueAddressID;
                    setContactHomeAddress({ $scope })(contactValueAddress);
                  }
                },
              );
            });
        }
      };
      $scope.getContactFamilyInfo = {};
      const completeAdviserAssignment = (adviserFamilyId, data) => {
        toastSuccess('Assigned Adviser to Client', 'Successfully');
        $scope.contactFamilyAddress();
        commonFnService.tranferLoanApp(
          adviserFamilyId,
          $scope.familyId,
          data.title,
          $scope.clientHeaderName,
          0,
          () => $scope.processTimelineRefreshNotification(),
        );
      };

      $scope.adviserSelected = function (data) {
        if (data) {
          /**
           * Timeout to delay user's keyboard activity
           */
          $timeout(() => {
            adviserAllocateSwal(
              data.title,
              (confirmed) => {
                if (!confirmed) {
                  return;
                }

                $scope.getContactFamilyInfo.AdviserFullName = data.title;
                const adviserFamilyId =
                  typeof data.originalObject !== 'undefined'
                    ? data.originalObject.FamilyId
                    : 0;
                if ($scope.contactModel.isCorporateUser) {
                  corporateService
                    .assignClientToAdviserSet(
                      adviserFamilyId,
                      $scope.familyId,
                      0,
                    )
                    .then(() => {
                      completeAdviserAssignment(adviserFamilyId, data);
                    });
                } else {
                  contactService
                    .putAssignAdviser($scope.familyId, adviserFamilyId)
                    .then(() => {
                      completeAdviserAssignment(adviserFamilyId, data);
                    });
                }
              },
              'contact',
            );
          }, 500);
        }
      };

      $scope.saveAdviser = function () {
        $scope.listView = false;
      };

      function getRecentView() {
        broadcastDataService.recentView.get().then((res) => {
          broadcastDataService.recentView.list = res;
        });
      }

      $scope.getSummaryData = function (
        clientsData,
        isSelectedPrimaryContact = false,
      ) {
        getRecentView();
        isCorporateUser();
        getClientInformation(
          contactService,
          clientsData,
          isSelectedPrimaryContact,
        );
      };

      /**
       * This is called to fix the loading of breadcrumb
       */
      if ($state.params.activeTab) {
        contactService
          .clientInformGet($scope.familyId, 0, true)
          .then((response) => {
            $scope.getSummaryData(response.data);
            checkForDeceased(
              response.data,
              crmConfirmation,
              configService.feature,
            );
          });
      }

      $scope.$watch('contactHomeAddress', (newValue) => {
        if (typeof newValue === 'undefined') {
          $scope.contactHomeAddress = 'New Zealand';
        }
        if ($scope.contactHomeAddressID && $scope.contactHomeAddressID > 0) {
          generalService
            .addressDetailsGet($scope.contactHomeAddressID)
            .then((response) => {
              const responseData = response.data;
              if (responseData) {
                $scope.geoCoded = responseData.geoCoded || '';
                $scope.contactHomeAddressCountry = responseData.country || '';
                const googleInterval = $interval(() => {
                  if (!$window.google) {
                    return false;
                  }
                  const toolTipContent = `${'<div class="iw-container margin-left-10 margin-right-10 margin-top-10"><div>'}${
                    $scope.contactHomeAddress
                  }</div></div>`;
                  if (responseData.latitude && responseData.longitude) {
                    mapService.mapDesign(
                      responseData.latitude,
                      responseData.longitude,
                      $scope.contactHomeAddress,
                      toolTipContent,
                      true,
                      'contactHomeAddressMapSummary',
                      '10',
                    );
                  }
                  $interval.cancel(googleInterval);
                }, 500);
              }
            });
        }
      });

      // Returns trusted URL for Google Map Embed CORS Issue
      // ------------------------------------------------------------
      $scope.trustedSource = function (source) {
        return $sce.trustAsResourceUrl(source);
      };

      // Open a new tab
      // ------------------------------------------------------------
      $scope.selectClientsTab = function () {
        $timeout(() => {
          angular.element('#clients').find('a').triggerHandler('click');
        }, 100);
      };

      // Summary Transaction
      // ------------------------------------------------------------
      /*
// Non existing :
    $scope.summaryTransaction = [];
    contactService.transList($scope.familyId).then(function (response) {
      $scope.summaryTransaction = response.data;
    });
*/

      // Tagged List Data
      // ------------------------------------------------------------
      // Tag Modal
      $scope.openTagModal = function (action, index) {
        const familyId = $scope.familyId;
        $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/create_tag_modal.html',
          controller: 'OpenTagModalCtrl',
          scope: $scope,
          resolve: {
            index: () => index,
            action: () => action,
            familyId: () => familyId,
          },
        });
      };

      $scope.getTaggedList = function () {
        contactService.taggedList($scope.familyId).then((response) => {
          $scope.taggedListDetails = response.data;
        });
      };

      $scope.getTaggedList();

      // Print Summary
      // ------------------------------------------------------------
      $scope.printSummaryDownloading = false;
      $scope.downloadPrintSummary = () => {
        if ($scope.printSummaryDownloading) {
          toaster.pop(
            'warning',
            'Processing...',
            'Still downloading contact summary pdf',
          );
        }
        $scope.printSummaryDownloading = true;
        toastr.info('Downloading contact summary pdf...');
        contactService
          .contactSummaryPDFGet($scope.familyId)
          .then((response) => {
            const eventTimeout = $timeout(() => {
              const data = response.data;
              const currentDate = $filter('date')(new Date(), 'MM-dd-yy');
              const element = $window.document.createElement('a');
              element.setAttribute(
                'href',
                `data:${data.ContentType};base64,${data.DocumentContent}`,
              );
              element.setAttribute(
                'download',
                `contactsummary_${currentDate}.pdf`,
              );

              element.style.display = 'none';
              $window.document.body.append(element);

              element.click();
              toastr.clear();

              element.remove();
              $scope.printSummaryDownloading = false;
            });
            $scope.$on('$destroy', () => $timeout.cancel(eventTimeout));
          });
      };

      $scope.printSummary = () => {
        if (!currentUserService.isID) {
          const props = { clientHeaderName: $scope.clientHeaderName };

          const modalInstance = $uibModal.open({
            template: `<print-summary-modal modal-instance="vm.modalInstance" contact-details="vm.props.contactDetails"></print-summary-modal>`,
            controller: 'CommonModalPlaceholderCtrl',
            controllerAs: 'vm',
            resolve: {
              props: () => props,
            },
          });

          modalInstance.result.then(() => {
            $scope.downloadPrintSummary();
          });
          return;
        }
        $scope.downloadPrintSummary();
      };

      // Recent Activity List Data
      // ------------------------------------------------------------
      $scope.recentActivityList = {};
      $scope.pages = [];
      $scope.recentActivityArray = [];

      if ($scope.familyId) {
        contactService
          .geRecentActivityList($scope.familyId)
          .then((response) => {
            $scope.recentActivityList = response.data;
            $scope.recentActivityArray = _.chunk($scope.recentActivityList, 5);
            $scope.showIndex = 1;
          });
      }

      const viewport = () => {
        let e = window;
        let a = 'inner';
        if (!('innerWidth' in window)) {
          a = 'client';
          e = $window.documentElement || $window.document.body;
        }
        return {
          width: e[`${a}Width`],
        };
      };

      $scope.getWindowWidth = function () {
        return {
          w: viewport().width,
        };
      };

      $scope.$watch(
        $scope.getWindowWidth,
        () => {
          // For Slick Carousel Slide Height Change
          $scope.updateSlickHeight();
        },
        true,
      );

      $scope.updateSlickHeight = function () {
        $timeout(() => {
          angular
            .element('.slick-list')
            .height(angular.element('.slide.slick-active').height());
        }, 500);
      };

      $scope.PrevActivity = function () {
        angular.element('.carousel').slick('slickPrev');
        $scope.updateSlickHeight();
      };
      $scope.nextActivity = function () {
        angular.element('.carousel').slick('slickNext');
        $scope.updateSlickHeight();
      };

      // Document
      $scope.documentUpload = {};
      $scope.documentUpload.isUploading = false;
      $scope.documentUpload.progress = {
        percent: 0,
        total: 0,
        loaded: 0,
      };

      $scope.convertToDate = function (date) {
        return $filter('date')(new Date(date), 'dd MMM yyyy');
      };

      $scope.contactFamilyAddress();

      $scope.isDateValid = function (date) {
        const d = new Date(date);
        return parseInt(d.getFullYear(), 10) > 1;
      };

      $scope.convertUtcToUserDate = convertUtcToUserDate;

      // sms subscription
      angular.extend($scope, {
        sendSms() {
          const vm = this;
          if ($scope.contactModel && !$scope.contactModel.isSmsEnabled) {
            return;
          }
          if ($rootScope.crmCountry === 'New Zealand') {
            generalService
              .SMSSubscriptionStatusGet($scope.familyId)
              .then((response) => {
                if (_.size(response.data) === 0) {
                  //  show the subscription modal
                  vm.openSMSSubscriptionModal();
                } else if (
                  _.size(response.data) > 0 &&
                  _.first(response.data).SMSSubscriptionId ===
                    SMS_SUBSCRIPTION.SUBSCRIPTION_FOR_APPROVAL
                ) {
                  //  show the subscription pending modal
                  vm.openSMSSubscriptionPendingModal();
                } else {
                  //  proceed
                  $state.go('app.corporateSendSms', {
                    familyId: vm.familyId,
                    contactType: 'client',
                    isFromContact: true,
                  });
                }
              });
          } else {
            $state.go('app.corporateSendSms', {
              familyId: vm.familyId,
              contactType: 'client',
              isFromContact: true,
            });
          }
        },
        openSMSSubscriptionModal() {
          const vm = this;
          const modalInstance = $uibModal.open({
            templateUrl: '/assets/views/modals/sms_subscription.html',
            controller: 'OpenSMSSubscriptionModalCtrl',
            size: 'md',
            resolve: {
              familyId: () => {
                return vm.familyId;
              },
            },
            windowClass: 'sms-subscription',
          });

          modalInstance.result.then(
            () => {
              // close
              vm.openSMSSubscriptionPendingModal();
            },
            () => {
              // dismiss
            },
          );
        },
        openSMSSubscriptionPendingModal() {
          $uibModal.open({
            templateUrl: '/assets/views/modals/sms_pending.html',
            controller: 'OpenSMSSubscriptionPendingModalCtrl',
            size: 'md',
            windowClass: 'sms-subscription',
          });
        },
      });

      $scope.deleteContact = function (clientName) {
        let contactClientName = clientName;
        if (typeof contactClientName === 'undefined') {
          contactClientName = 'this client';
        }
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: `Remove ${contactClientName} from contacts.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              contactService.deleteContact($scope.familyId).then(
                () => {
                  toaster.pop(
                    'success',
                    'Client Deleted',
                    'Client successfully deleted from contacts.',
                  );
                  $scope.routeContactList();
                },
                (error) => {
                  if (!_.isNil(error.data.Message)) {
                    SweetAlert.swal(
                      'Delete Message',
                      error.data.Message,
                      'error',
                    );
                  } else {
                    SweetAlert.swal(
                      'Delete Message',
                      'We are unable to delete client from contacts',
                      'error',
                    );
                  }
                },
              );
            }
          },
        );
      };

      $scope.navigateTo = (state, familyId, contactType) => {
        $state.go(state, { familyId, contactType });
      };

      $scope.handleCreditCheckModalResponse = (response) => {
        if (!response) {
          return;
        }
        const modalTimeout = $timeout(() => {
          swal({
            title: 'Credit check arranged',
            text:
              'Please go to the Loan tools menu and click Credit Checks to retrieve the report',
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#42B4B4',
            confirmButtonText: 'Got it',
            closeOnConfirm: true,
          });
        }, 300);
        timeoutService.addTimeout(
          modalTimeout,
          WATCHER_CONTEXT.CONTACT_DETAILS_CTRL,
        );
      };

      $scope.showCreditCheckButton = () => {
        return uiService.isCountry('Australia') && uiService.showCreditCheck;
      };

      $scope.getDesignatedFamilyBrokerInfo = () => {
        contactModelService
          .getFamilyAdviserInfo($scope.familyId)
          .then((response) => {
            if (!response) {
              return;
            }
            creditCheckService.creditCheckValidation(response.familyId);
            $scope.designatedBrokerInfo = response;
          });
      };
      $scope.isCreditCheckDisabled = () => {
        return creditCheckSubscriptionService.creditCheck.isCreditCheckDisabled;
      };
      $scope.getDesignatedFamilyBrokerInfo();

      $scope.getBrokerBasicinfo = () => {
        generalService.getBrokerBasicinfo().then((response) => {
          if (!response) {
            return;
          }
          $scope.brokerBasicinfo = response;
        });
      };
      $scope.getBrokerBasicinfo();

      $scope.showCreateMultiLoanCPAModal = () => {
        const props = { clientHeaderName: $scope.clientHeaderName };
        $uibModal.open({
          template: `<multi-loan-cpa-modal
          modal-instance="vm.modalInstance"
          client-header-name="vm.props.clientHeaderName"
        ></multi-loan-cpa-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          keyboard: false,
          backdrop: 'static',
          size: 'lg',
          resolve: {
            props: () => props,
          },
          windowClass: 'multi-loan-cpa-modal',
        });
      };

      $scope.showCreateMultiLoanCQPModal = () => {
        const props = { clientHeaderName: $scope.clientHeaderName };
        $uibModal.open({
          template: `<multi-loan-cqp-modal
          modal-instance="vm.modalInstance"
          client-header-name="vm.props.clientHeaderName"
        ></multi-loan-cqp-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          keyboard: false,
          backdrop: 'static',
          size: 'lg',
          resolve: {
            props: () => props,
          },
          windowClass: 'multi-loan-cqp-modal',
        });
      };

      $scope.$on('$destroy', () => {
        timeoutService.cancelTimeouts(WATCHER_CONTEXT.CONTACT_DETAILS_CTRL);
      });

      $scope.registerChildHandler = (handler) => {
        $scope.timelineChildNotificationHandler = handler;
      };

      $scope.processTimelineRefreshNotification = (
        action = 'refresh-timeline',
        data = {},
      ) => {
        $scope.timelineChildNotificationHandler(action, data);
      };

      $scope.generatingHelloBook = false;
      $scope.generateHelloBook = () => {
        checkGenerateHelloBookUtil({
          vm: $scope,
          $timeout,
          $uibModal,
          helloBookPreviewService,
          helloBookService,
          downloadDataService,
        });
      };

      $scope.updateClientList = (data) => {
        if (data && data.isContactUpdated) {
          contactSharedDataService.reloadClients &&
            contactSharedDataService.reloadClients();
        }
      };

      $scope.openSendHelloBook = (additionalProps = {}) => {
        const props = {
          ...additionalProps,
          familyId: $scope.familyId,
          isNewModal: uiService.newHelloModalEnabled,
        };
        return new Promise((resolve, reject) => {
          helloBookPreviewService.open(props).then(
            (response) => {
              contactSharedDataService.reloadContactSummary &&
                contactSharedDataService.reloadContactSummary();
              $scope.updateClientList(response);

              if (!response || !response.sent) {
                return resolve({ sent: false });
              }

              contactSharedDataService.reloadContactLending &&
                contactSharedDataService.reloadContactLending();
              contactSharedDataService.reloadContactLoanTimeline();
              resolve({
                sent: response.sent,
                onlineFactFind: response.onlineFactFind,
              });
            },
            (error) => {
              $scope.updateClientList(error);
              reject(error);
            },
          );
        });
      };

      $scope.sendHelloBook = () =>
        sendHelloBook({
          vm: $scope,
          helloBookPreviewService,
        });
      bccEmailSync &&
        corporateModelService.setGmailSyncOnDemand($scope.familyId);
    });
