import angular from 'angular';
import loanProductFinder from './loanProductFinder';
import tradePersonalReferencesSection from './tradePersonalReferencesSection';
import insuranceReferralsSection from './insuranceReferralsSection';
import fundingDetailsSection from './fundingDetailsSection';

export default angular.module('loanAppSections.scenes', [
  loanProductFinder,
  tradePersonalReferencesSection,
  insuranceReferralsSection,
  fundingDetailsSection,
]).name;
