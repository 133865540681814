import template from './opportunityCommunications.html';
import controller from './opportunityCommunicationsCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    familyId: '<',
    involvedParties: '<',
    adviserId: '<',
  },
  controller,
  controllerAs: 'vm',
};
