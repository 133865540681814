import set from 'lodash/set';
import get from 'lodash/get';
import {
  DOCUMENT_REQUEST_METHOD_ID,
  FILE_ATTACHMENT_LIMIT,
  GUIDE_TYPE,
  HELLO_PACK_KEY,
  PACK_MODE,
  HELLO_PACK_OPTIONS_TOGGLE,
  MODAL_SERVICE,
} from 'Common/constants/helloBook';
import { getTemporaryUniqueId } from 'Common/utilities/math';
import {
  showHelloPackToggle,
  getCreditGuideDisplay,
} from 'Common/utilities/helloBook';
import { catchError } from 'Common/utilities/promise';
import { grammaticallySeparateByComma } from 'Common/utilities/string';

const FACT_FIND_DESCRIPTION = `<p>Collect critical client information to make a recommendation in the customer's best interests</p>`;
const ONLINE_FF_MODEL_VALUE = 'model.options.onlineFactFind.value';
const FACT_FIND_VALID = 'model.factFind.isFactFindValid';

export default class HelloPackEditorCtrl {
  constructor(
    currentUserService,
    helloBookService,
    helloPackService,
    configService,
    uiService,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.helloBookService = helloBookService;
    this.helloPackService = helloPackService;
    this.configService = configService;
    this.uiService = uiService;
  }

  $onInit() {
    this.packMode = this.packMode || PACK_MODE.HELLO_PACK;
    this.isInsuranceHelloPackService =
      this.selectedService === MODAL_SERVICE.insurance;
    this.isInviteIOFF = this.packMode === PACK_MODE.INVITE_IOFF;
    this.isAU = !!this.currentUserService.isAU;
    this.showCreditGuideWithHelloPack = this.configService.feature.showCreditGuideWithHelloPack;
    this.factFindHandler = {
      onToggle: () => {},
    };
    this.isInsuranceHelloPackService &&
      this.helloPackService.handler.skipDealView();

    const {
      displayCreditGuide,
      displayCreditGuideWithHelloPack,
    } = getCreditGuideDisplay({ $scope: this });
    const displayHelloPack = showHelloPackToggle({
      $scope: this,
      displayCreditGuideWithHelloPack,
    });

    const inviteIOFFOption = {
      value: this.uiService.showIOFF,
      title: 'Online fact find',
      description: FACT_FIND_DESCRIPTION,
      isVisible: this.uiService.showIOFF,
      disabled: true,
    };

    const defaultOptions = {
      [HELLO_PACK_KEY.DEFAULT]: {
        value: displayHelloPack,
        defaultSettingKey: 'isHelloBookEnabled',
        apiKey: 'helloBook',
        title: 'Hello Book',
        description: `<p>A friendly marketing document to introduce yourself and your services.</p>`,
        thumbnail: 'hello_book',
        thumbnailAlt: 'Hello Book',
        thumbnailTitle: 'Learn about us',
        thumbnailDescription:
          'Find out who we are & how we help in your Hello Book',
        isVisible: displayHelloPack,
      },
      [HELLO_PACK_KEY.CREDIT_GUIDE_ONLY]: {
        value: displayCreditGuide,
        defaultSettingKey:
          displayCreditGuide && HELLO_PACK_OPTIONS_TOGGLE.CREDIT_GUIDE,
        apiKey: 'creditGuide',
        title: GUIDE_TYPE.AU,
        description: `<p>A document that outlines the terms and conditions associated with your services. Remember, each client requires a copy.</p>`,
        thumbnail: 'credit_guide',
        thumbnailAlt: GUIDE_TYPE.AU,
        thumbnailTitle: 'Things you should know',
        thumbnailDescription:
          'Our Credit Guide explains our obligations to you',
        isVisible: displayCreditGuide,
      },
      [HELLO_PACK_KEY.WIH_CREDIT_GUIDE]: {
        value: displayCreditGuideWithHelloPack,
        defaultSettingKey:
          displayCreditGuideWithHelloPack && 'isHelloBookEnabled',
        apiKey: 'helloBook',
        title: 'Hello Book & Credit Guide',
        description: `<p>A friendly marketing document to introduce yourself, your services and the associated terms and conditions of engagement.</p>`,
        thumbnail: 'hello_book',
        thumbnailAlt: 'Hello Book & Credit Guide',
        thumbnailTitle: 'Learn about us',
        thumbnailDescription:
          'Find out who we are & how we help in your Hello Book & Credit Guide',
        isVisible: displayCreditGuideWithHelloPack,
      },
      disclosureGuide: {
        value: this.uiService.showDisclosureGuide,
        defaultSettingKey: 'isDisclosureEnabled',
        apiKey: 'disclosureGuide',
        disabled: !this.uiService.showDisclosureGuide,
        title: GUIDE_TYPE.NZ,
        description: `<p>A document that outlines the terms and conditions associated with your services. Remember, each client requires a copy.</p>`,
        thumbnail: 'credit_guide',
        thumbnailAlt: GUIDE_TYPE.NZ,
        thumbnailTitle: 'Things you should know',
        thumbnailDescription:
          'Our Disclosure Guide explains our obligations to you',
        isVisible: this.currentUserService.isNZ && !this.onlyFactFind,
      },
      calendlyInvite: {
        value: !this.onlyFactFind,
        defaultSettingKey: 'isCalendlyInviteEnabled',
        apiKey: 'calendly',
        title: 'Calendly Invite',
        description: `<p>Your account isn’t quite ready to turn on Calendly bookings. Not to worry - <a href="mailto:mycrmsupport@loanmarket.com.au">MyCRM Support</a> will be able to get this fixed for you.</p>`,
        thumbnail: 'calendar',
        thumbnailAlt: 'Calendly',
        thumbnailTitle: 'Book a meeting',
        thumbnailDescription: 'Find a time that suits you',
        isVisible: this.currentUserService.isAU && !this.onlyFactFind,
        disabled: false,
      },
      uploadedAttachments: {
        value: !this.onlyFactFind,
        title: 'Uploaded attachments',
        description: `<p>Upload ${FILE_ATTACHMENT_LIMIT} document (max) as attachments to the email.</p>`,
        component: `<hello-pack-attachment attached-files="vm.model.attachedFiles" on-change="vm.onUploadAttachmentChanged(attachedFiles)"></hello-pack-attachment>`,
        thumbnail: 'attachment',
        thumbnailAlt: 'Attachment',
        thumbnailTitle: 'Attachments',
        displayAttachment: true,
        isVisible: !this.onlyFactFind,
      },
      onlineFactFind: this.isInsuranceHelloPackService
        ? {
            ...inviteIOFFOption,
            disabled: false,
            defaultSettingKey: 'isFactFindEnabled',
          }
        : {
            value: true,
            defaultSettingKey: 'isFactFindEnabled',
            apiKey: 'onlineFactFind',
            title: 'Online fact find',
            description: FACT_FIND_DESCRIPTION,
            component: `<hello-pack-fact-find toggle-value="item.value" fact-find-default-setting="vm.factFindDefaultSetting" on-change="vm.onFactFindChange(props)" handler="vm.factFindHandler"></hello-pack-fact-find>`,
            isVisible: true,
          },
      deal: {
        component: `<hello-pack-deal family-id="vm.familyId" model="vm.model" one-touch-privacy="vm.oneTouchPrivacy" get-privacy-consents="vm.getPrivacyConsents()" ng-if="vm.factFindDefaultSetting"></hello-pack-deal>`,
        isVisible:
          !this.model.loanApplicationId &&
          !this.isInsuranceHelloPackService &&
          !this.hideDealOption,
        hideToggle: true,
        value: true,
      },
      documentRequest: {
        value: true,
        defaultSettingKey:
          !this.isInsuranceHelloPackService &&
          'factFindSections.supportingdocuments',
        title: 'Document request',
        description: `<p>Choose how to request documents from your client.</p>`,
        component: `<hello-pack-document-request model="vm.model"></hello-pack-document-request>`,
        isVisible: !this.isInsuranceHelloPackService,
      },
    };

    const insuranceProfilerOptions = {
      ccToAdviser: {
        value: true,
        defaultSettingKey: 'isCCToAdviser',
        apiKey: 'isCCToAdviser',
        title: 'CC to adviser',
        description: `<p>Send a copy of this email to the adviser.</p>`,
        isVisible: true,
      },
      uploadedAttachments: {
        value: false,
        title: 'Uploaded attachments',
        defaultSettingKey: 'isUploadedAttachments',
        description: `<p>Upload ${FILE_ATTACHMENT_LIMIT} document (max) as attachments to the email.</p>`,
        component: `<hello-pack-attachment attached-files="vm.model.attachedFiles" on-change="vm.onUploadAttachmentChanged(attachedFiles)"></hello-pack-attachment>`,
        thumbnail: 'attachment',
        thumbnailAlt: 'Attachment',
        thumbnailTitle: 'Attachments',
        displayAttachment: true,
        isVisible: true,
      },
    };

    const inviteIOFFOptions = {
      onlineFactFind: { ...inviteIOFFOption },
    };

    let options = {};
    switch (true) {
      case this.packMode === PACK_MODE.INSURANCE_PROFILER: {
        options = insuranceProfilerOptions;
        break;
      }
      case this.packMode === PACK_MODE.INVITE_IOFF: {
        options = inviteIOFFOptions;
        break;
      }
      default: {
        options = defaultOptions;
        break;
      }
    }

    this.editorHandler = {
      setDefaultTemplate: () => {},
    };
    this.privacyConsentHandler = {
      setClientsByLoanId: () => {},
    };

    set(this, 'model.options', options);

    const isGuarantorAccepted =
      this.onlyFactFind || this.packMode === PACK_MODE.HELLO_PACK;
    this.acceptableInvolvedParties = {
      IsApplicant: true,
      ...(isGuarantorAccepted
        ? {
            IsGuarantor: true,
          }
        : {}),
    };
  }

  $onChanges() {
    if (this.adviserInfo && this.adviserTheme) {
      this.getDefaultSettings();
    }
  }

  getPrivacyConsents() {
    const loanApplicationId = get(this.model, 'loanApplicationId', 0);
    if (!loanApplicationId) {
      return;
    }
    this.helloBookService
      .getPrivacyPolicy(loanApplicationId)
      .then((privacyConsents = []) => {
        this.privacyConsentHandler.setClientsByLoanId(privacyConsents);
      })
      .catch(catchError);
  }

  updateFactFindDescription(privacyConsents) {
    const withoutPolicyNames = privacyConsents.reduce((initial, current) => {
      const { privacyConsent, displayName, isSelected } = current;
      if (privacyConsent || !isSelected) {
        return initial;
      }
      return [...initial, displayName];
    }, []);
    const privacypolicy = !!withoutPolicyNames.length;
    this.factFindDefaultSetting = {
      ...this.factFindDefaultSetting,
      privacypolicy,
    };
    const additionalDescription = privacypolicy
      ? `${FACT_FIND_DESCRIPTION}<p class="additional-description">Privacy consent will be requested from ${grammaticallySeparateByComma(
          withoutPolicyNames,
          'and',
        )}${withoutPolicyNames.length === 1 ? ' only' : ''}</p>`
      : FACT_FIND_DESCRIPTION;
    set(
      this,
      'model.options.onlineFactFind.description',
      additionalDescription,
    );
  }

  setCommunicationAppendMessage(title, description) {
    const color = get(this, 'adviserTheme.sidebarBgColor', '');
    const style = color ? `style="color:${color}"` : '';
    this.model.communicationAppendMessage = `
    <p>
      <a class="dummy-link" ${style}>${title}</a>
      ${description}
    </p>
  `;
  }

  getPdfAttachmentTemplates() {
    this.model.communicationAppendMessage = '';
    const templates = Object.keys(this.model.options).reduce((accum, key) => {
      const item = get(this, `model.options[${key}]`, {});
      const isValidTemplate = item.value && item.thumbnail;
      if (!isValidTemplate || !item.isVisible) {
        return accum;
      }

      if (key === HELLO_PACK_KEY.DEFAULT) {
        const isCreditGuideEnabled = get(
          this,
          'model.options.creditGuide.value',
          false,
        );
        const isDisclosureGuideEnabled = get(
          this,
          'model.options.disclosureGuide.value',
          false,
        );
        const guideText = isCreditGuideEnabled ? GUIDE_TYPE.AU : GUIDE_TYPE.NZ;
        const isGuideEnabled = isCreditGuideEnabled || isDisclosureGuideEnabled;

        const helloPack = {
          ...item,
          thumbnailDescription: `${item.thumbnailDescription} ${
            isGuideEnabled ? `& ${guideText}` : ''
          }`,
        };
        this.setCommunicationAppendMessage(
          helloPack.thumbnailTitle,
          helloPack.thumbnailDescription,
        );
        return accum.concat(helloPack);
      }

      const isHelloPackEnabled = get(
        this,
        'model.options.helloPack.value',
        false,
      );

      const isGuideKey =
        key === HELLO_PACK_KEY.CREDIT_GUIDE_ONLY || key === 'disclosureGuide';
      if (isGuideKey && !isHelloPackEnabled) {
        this.setCommunicationAppendMessage(
          item.thumbnailTitle,
          item.thumbnailDescription,
        );
      }
      return isGuideKey && isHelloPackEnabled ? accum : accum.concat(item);
    }, []);
    set(this, 'model.templates', templates);
  }

  onClientUpdate({ clients }) {
    this.model.clients = clients;
    this.oneTouchPrivacy &&
      !this.isInsuranceHelloPackService &&
      this.updateFactFindDescription(clients);
    this.helloPackService.handler.getClientExcludedInDeal();
  }

  onUploadAttachmentChanged(files = []) {
    set(this, 'model.attachedFiles', [...files]);
  }

  onUpdateContent(props = {}) {
    this.model.content = { ...props };
  }

  factFindOnlyValidate() {
    const isFactFindValid = get(this, FACT_FIND_VALID, false);
    const factFindToggledOn = get(this, ONLINE_FF_MODEL_VALUE, false);
    const documentRequestToggledOn = get(
      this,
      'model.options.documentRequest.value',
      false,
    );

    return factFindToggledOn ? isFactFindValid : documentRequestToggledOn;
  }

  onCheckFactFindValidation() {
    if (this.isInviteIOFF || this.isInsuranceHelloPackService) {
      this.model.isFactFindValid = true;
      this.helloPackService.onSetValidityFactFind(this.model.isFactFindValid);
      return;
    }

    const isFactFindToggleOn = get(this, ONLINE_FF_MODEL_VALUE, false);

    const isFactFindValid = this.oneTouchPrivacy
      ? get(this, FACT_FIND_VALID, false)
      : get(this, FACT_FIND_VALID, false) &&
        get(this, 'model.loanApplicationId', false);
    this.model.isFactFindValid = this.onlyFactFind
      ? this.factFindOnlyValidate()
      : !isFactFindToggleOn || (isFactFindToggleOn && isFactFindValid);
    this.helloPackService.onSetValidityFactFind(this.model.isFactFindValid);
    !this.onlyFactFind && this.onCheckFactFindSkip();
  }

  onCheckFactFindSkip() {
    const factFindToggledOn =
      this.model?.options?.onlineFactFind?.value ?? false;
    const documentRequestToggledOn =
      this.model?.options?.documentRequest?.value ?? false;
    const dealValue = !(factFindToggledOn || documentRequestToggledOn);
    const skipDealView = dealValue || !this.oneTouchPrivacy;
    set(this, 'model.options.deal.value', !dealValue);
    this.helloPackService.handler.skipDealView(skipDealView);
  }

  checkValidation() {
    this.onCheckFactFindValidation();
  }

  onFactFindChange(props = {}) {
    this.model.factFind = { ...props };
    this.onCheckFactFindValidation();
  }

  onChangeItemValue({ value, item, key }) {
    set(this, `model.options[${key}].value`, value);
    if (item.thumbnail) {
      this.getPdfAttachmentTemplates();
    }
    this.checkValidation();
    if (key === 'onlineFactFind') {
      this.factFindHandler.onToggle(value);
    }

    const isToggleFileInvite =
      key === 'documentRequest' &&
      this.model.documentRequestMethod ===
        DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE &&
      value;
    isToggleFileInvite && this.helloPackService.handler.onToggleFileInvite();
  }

  parseFileInviteData(data) {
    if (!data) {
      return {
        reminders: {},
        templates: [],
        dueDate: '',
      };
    }
    const dueDate = data.dueDate ? new Date(data.dueDate) : '';
    return {
      ...data,
      dueDate,
    };
  }

  getDefaultSettings() {
    return this.helloBookService
      .getDefaultSettings(this.adviserInfo.familyId)
      .then((data = {}) => {
        data.isDisclosureEnabled = this.uiService.showDisclosureGuide
          ? data.isDisclosureEnabled
          : false;
        const isBrandingReady =
          !this.adviserInfo.isBYOB ||
          !!(this.adviserInfo.tradingName && this.adviserTheme.logoDocId);
        this.model.documentRequestMethod = data.documentRequestMethod
          ? data.documentRequestMethod
          : DOCUMENT_REQUEST_METHOD_ID.FACT_FIND;
        this.model.fileInvite = this.parseFileInviteData(data.fileInvite);
        this.model.isBrandingReady = isBrandingReady;
        data.isFactFindEnabled = isBrandingReady && data.isFactFindEnabled;
        this.model.attachedFiles = get(data, 'attachments', []).map(
          (file, index) => ({
            ...file,
            index,
            id: getTemporaryUniqueId(index),
            showConfirmDeletePopover: false,
          }),
        );
        this.factFindDefaultSetting = data.factFindSections || {};
        const editorMessage =
          this.onlyFactFind && !this.isInviteIOFF ? ' ' : data.message;
        this.editorHandler.setDefaultTemplate(editorMessage);
        const options = get(this, 'model.options', {});
        !this.onlyFactFind &&
          Object.keys(options).forEach((key) => {
            const defaultKey = get(
              this,
              `model.options[${key}].defaultSettingKey`,
              '',
            );
            const defaultValue = get(data, `${defaultKey}`, true);
            set(this, `model.options[${key}].value`, defaultValue);
          });

        // set uploaded attachments
        set(
          this.model,
          'options.uploadedAttachments.value',
          get(data, 'isUploadedAttachments', true),
        );

        this.model.calendlyLink = data.calendlyLink;
        const isDisableCalendly =
          this.currentUserService.isNZ || !this.model.calendlyLink;
        if (isDisableCalendly) {
          set(this, 'model.options.calendlyInvite.disabled', isDisableCalendly);
          set(this, 'model.options.calendlyInvite.value', !isDisableCalendly);
        }
        if (!this.isInviteIOFF) {
          set(this, 'model.options.onlineFactFind.disabled', !isBrandingReady);
        }
        if (this.isInsuranceHelloPackService) {
          set(this, ONLINE_FF_MODEL_VALUE, this.uiService.showIOFF);
        }
      })
      .finally(() => {
        this.getPdfAttachmentTemplates();
        const loanApplicationId = get(this.model, 'loanApplicationId', 0);
        loanApplicationId && this.getPrivacyConsents();
        this.showEditorItem = true;
      });
  }
}
