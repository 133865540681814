import values from 'lodash/values';
import {
  POST_SETTLEMENT_FOLLOW_UP_DEFAULT_TAB,
  SERVICE_TAB_INDEX,
  SERVICE_ID,
} from 'Common/constants/customerCareModules';

export const getActiveTabIndex = ({ $scope, $stateParams }) => {
  if (!$scope.customerCareTabs || !$scope.customerCareTabs.length) {
    return 0;
  }
  const tabObj = $scope.customerCareTabs.find(
    (tab) => tab.urlValue === $stateParams.activeTab,
  );
  return (tabObj && tabObj.index) || 0;
};

export const onTabClick = ({ $scope, $state, stateService, $stateParams }) => (
  tab,
) => {
  if ($stateParams.activeSubTab) {
    $scope.activeSubTab = $stateParams.activeSubTab;
  }
  $state.params.activeTab = tab.urlValue;
  $state.params.activeSubTab = '';
  if (tab.index === SERVICE_TAB_INDEX.POST_SETTLEMENT) {
    $state.params.activeSubTab =
      $scope.activeSubTab || POST_SETTLEMENT_FOLLOW_UP_DEFAULT_TAB;
  }
  stateService.transition($state.current, $state.params);
  $scope.selectTabUpdate(tab.id);
};

export const onChangeTab = ({ $scope }) => (tabId) => {
  if (!tabId) {
    return;
  }
  const tabObj = $scope.customerCareTabs.find((tab) => tab.id === tabId);
  if (!tabObj) {
    return;
  }
  $scope.onTabClick(tabObj);
};

export const onGoToContact = (params) => {
  const { $state, currentUserService, familyId, isEntity } = params;
  const activeTab = 'summary';
  const isParamsPassed = $state && currentUserService && familyId;
  if (!isParamsPassed || currentUserService.isNzCorp) {
    return false;
  }
  const isClient = familyId && !isEntity;
  const goToPage = isClient ? 'app.contactsSingle' : 'app.BusinessAccount';
  return $state.go(goToPage, { familyId, activeTab });
};
export const callUpdateValues = ({ $scope, customerCareTabsService }) => {
  switch ($scope.serviceId) {
    case SERVICE_ID.FIXED_RATE_EXPIRES:
      customerCareTabsService.reloadCustomerCareFixedRateValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    case SERVICE_ID.APPROVAL_EXPIRIES:
      customerCareTabsService.reloadCustomerCarePreApprovalValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    case SERVICE_ID.INTEREST_ONLY_EXPIRIES:
      customerCareTabsService.reloadCustomerCareInterestValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    case SERVICE_ID.BIRTHDAYS:
      customerCareTabsService.reloadCustomerCareBirthdayValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    case SERVICE_ID.ANNUAL_REVIEWS:
      customerCareTabsService.reloadCustomerCareAnnualValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    case SERVICE_ID.POST_SETTLEMENT:
      customerCareTabsService.reloadCustomerCarePostSettlementValues({
        showLockScreen: $scope.showLockScreen,
        customerCareAdviserList: $scope.customerCareAdviserList,
      });
      break;
    default:
  }
};
export const getTabBrokers = ({
  $scope,
  mycrmLookupService,
  currentUserService,
  customerCareTabsService,
}) => () => {
  const params = {
    customerCareTypeId: $scope.serviceId,
    allAdviserLabel: 'All Advisers',
  };

  mycrmLookupService
    .getAdvisers(params)
    .then((data) => {
      $scope.isFirstLoading = false;
      $scope.customerCareAdviserList = data;
      if (currentUserService.isCorporate) {
        $scope.customerCareAdviserList.shift();
      }
      callUpdateValues({ $scope, customerCareTabsService });
    })
    .catch(() => {
      $scope.isFirstLoading = false;
    });
};

export const queryStringifyId = (paramName, dataArray) => {
  if (!paramName || !dataArray) {
    return;
  }
  let queryString = '';

  dataArray.forEach((element, key) => {
    queryString += `${!key ? '' : '&'}${paramName}=${element}`;
  });
  return queryString;
};

export const sortColumn = (orderBy) => {
  if (!orderBy || typeof orderBy !== 'function') {
    return '';
  }
  return orderBy()[0].slice(1);
};
export const sortType = (sorting) => {
  if (!sorting || typeof sorting !== 'function') {
    return '';
  }
  return values(sorting())[0].toUpperCase();
};

export const addSelectedCustomerCareIds = (selectedIds) => (
  loanstructureId,
  isChecked,
) => {
  if (isChecked) {
    selectedIds.push(loanstructureId);
  } else {
    selectedIds.splice(selectedIds.indexOf(loanstructureId), 1);
  }
  return selectedIds;
};

export const isRetainAllAdviser = (adviserList, isUnlocking) => {
  return isUnlocking
    ? adviserList.filter((adviser) => adviser.familyId !== 0)
    : adviserList;
};

export const adviserChanged = (selectedIds, tableParams) => {
  if (!selectedIds) {
    return;
  }
  selectedIds.splice(0, selectedIds.length);
  tableParams.reload();
};
