import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { toastError } from 'Common/utilities/alert';
import dotProp from 'dot-prop-immutable';
import { COMPANY_TYPES } from 'Common/constants/partyTypes';
import {
  getSelectedAdviser,
  getAdviserName,
} from './../util/businessAccountSingle';
import {
  isBluesStoneApplication,
  organisationInfoSetNZBN,
  isNotValidNZBNChar,
  toggleTrustType,
  onOrganisationInfoSet,
  getBusinessEntityPhoneCountryCode,
} from './util/businessAccountSingle';
import { validateNZBNfieldOnSubmit } from '../util/createContact';
import { showPipelineLineInContact } from 'Common/utilities/contact';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('BusinessAccountSingleCtrl', function BusinessAccountSingleCtrl(
      $scope,
      $timeout,
      mapService,
      contactService,
      corporateService,
      toaster,
      $state,
      $stateParams,
      generalService,
      broadcastDataService,
      commonFnService,
      optionsService,
      loanAppDetailsService,
      stateService,
      currentUserService,
      configService,
      noteService,
      countryCodeService,
      uiService,
      contactModelService,
      dashboardService,
      contactSharedData,
    ) {
      if ($state.params.familyId) {
        dashboardService.storeRecentlyViewedContact($state.params.familyId, {
          IsBusiness: true,
        });
      }
      $scope.isShowPipelineLine = showPipelineLineInContact({
        currentUserService,
      });
      $scope.notesAction = noteService.action;
      const stateTransition = (tab) => {
        $stateParams.activeTab = tab;
        stateService.transition($state.current, $stateParams);
      };
      const defaultLimit = 2;
      $scope.loanLimitTo = defaultLimit;
      $scope.isInternationalPhoneNumbers = uiService.isInternationalPhoneNumber;
      $scope.business = {
        Phone: '',
      };
      $scope.isAU = currentUserService.isAU;
      $scope.uiService = uiService;
      $scope.homePhoneCountryCode = countryCodeService.homePhoneCountryCode();

      $scope.bluesStoneApplication = isBluesStoneApplication({
        ...currentUserService,
        ...configService,
      });

      $scope.routeContactList = stateService.routeToContactList;

      toggleTrustType({ $scope });

      $scope.familyId = $state.params.familyId;
      $scope.slideSidebar = () => {
        angular.element('.row-offcanvas').toggleClass('active');
      };
      $scope.isBusiness = true;
      $scope.editBusiness = false;
      $scope.contactAdvisers = {
        AssistantAdvID: 0,
      };
      $scope.contactModel = {};
      $scope.contactModel.isCorporateUser = false;

      $scope.listView = false;

      $scope.summaryHelper = {
        getRecentView() {
          broadcastDataService.recentView.get().then((res) => {
            if (res.data) {
              broadcastDataService.recentView.list = res;
            }
          });
        },
        getAssignedAssistant(adviserId, familyId) {
          corporateService
            .assignAssistantToClientGet(adviserId, parseInt(familyId, 10))
            .then(({ data }) => {
              const assistantFamilyId = dotProp.get(
                data,
                '0.AssistantFamilyID',
              );
              $scope.contactAdvisers = {
                ...$scope.contactAdvisers,
                AssistantAdvID: assistantFamilyId,
                AssistantFamilyId: assistantFamilyId,
              };
            });
        },
        isCorporateUser() {
          contactService.getUserInfo().then((res) => {
            if (res.data && parseInt(res.data.AccessType, 10) === 5) {
              $scope.contactModel.isCorporateUser = true;
            }
          });
        },
        processURITab() {
          if ($state.params.activeTab) {
            $scope.selectedContacts = $state.params.activeTab;
          } else {
            $scope.selectedContacts = 'summary';
          }
          $scope.selectBusinessContacts($scope.selectedContacts);
        },
        listeners() {
          $scope.$watch('selectedContacts', (newValue) => {
            if (newValue === 'summary') {
              $scope.getLoans();
            }
            if (newValue === 'loans') {
              loanAppDetailsService.loadLoanList = true;
            }
          });
        },
        init() {
          $scope.summaryHelper.isCorporateUser();
          $scope.summaryHelper.processURITab();
          $scope.summaryHelper.listeners();
          $scope.getBusinessDetails();
        },
      };

      $scope.getBusinessDetails = () => {
        contactService
          .organisationInfoGet($state.params.familyId)
          .then((response) => {
            const organisationInfo = response.data;
            organisationInfoSetNZBN(organisationInfo, response.data);
            $scope.businessAccount = organisationInfo;
            $scope.business = _.clone($scope.businessAccount); // for edit mode
            $scope.AdviserId = organisationInfo.AllocatedAdviserID;
            $scope.AdviserName = organisationInfo.AllocatedAdviserName;
            contactSharedData.familyData = {
              IsClient: false,
              FamilyId: $state.params.familyId,
              ...organisationInfo,
            };
            $scope.summaryHelper.getAssignedAssistant(
              $scope.AdviserId,
              $state.params.familyId,
            );

            if (typeof _.head(organisationInfo.Address) === 'undefined') {
              $scope.business.Address = '';
            } else {
              mapService.loadAPI();

              $scope.business.Address =
                $scope.business.Address[0].formatted_address;

              $scope.businessAddress = _.head(organisationInfo.Address);

              const toolTipContent =
                `${
                  '<div class="iw-container margin-left-10 margin-right-10 margin-top-10">' +
                  '<div>'
                }${$scope.businessAddress.formatted_address}</div>` + `</div>`;

              $timeout(() => {
                mapService.mapDesign(
                  $scope.businessAddress.latitude,
                  $scope.businessAddress.longitude,
                  $scope.businessAddress.formatted_address,
                  toolTipContent,
                  true,
                  'businessContactHomeAddressMap',
                  '10',
                );
              });
              /**
               * issue on google map api callback so promise
               * $timeout will be the quickfix to this
               * https://stackoverflow.com/questions/38627259
               * */
            }

            const isOrgPhone =
              organisationInfo.Phone && organisationInfo.Phone.length;

            if (organisationInfo.Email && organisationInfo.Email.length) {
              $scope.business.OfficeEmail =
                $scope.business.Email[0].EmailAddress;
            }
            if (isOrgPhone) {
              $scope.business.Phone = countryCodeService.formatExistingMobileAuNzNumber(
                {
                  isInternationalPhoneNumbers:
                    $scope.isInternationalPhoneNumbers,
                  phoneNumber: $scope.business.Phone[0].Number,
                },
              );
            }

            if ($scope.isInternationalPhoneNumbers && !isOrgPhone) {
              $scope.business.Phone = '';
            }

            $scope.businessAccount.Insurance = false;
            $scope.businessAccount.Logo = 'assets/images/company/accuro.png';
            if (
              organisationInfo &&
              organisationInfo.Address &&
              organisationInfo.Address.length
            ) {
              $scope.businessAccount.Formatted_address =
                organisationInfo.Address[0].formatted_address;
            }

            corporateService
              .getAdvisersDropdown($state.params.familyId, 0, 1)
              .then((adviser) => {
                $scope.allocatedAdviser = adviser.data;
                $scope.getContactAssignedAdviser(adviser.data);
              });
            $scope.getLoans();
            $scope.summaryHelper.getRecentView();
            getBusinessEntityPhoneCountryCode({
              $scope,
              contactModelService,
              currentUserService,
            });
          });
      };

      $scope.getLoans = () => {
        contactService.loanListGet($state.params.familyId).then((response) => {
          const existingLoans = [];
          const inProgressLoans = [];
          let { data } = response;
          if (!data) {
            return;
          }
          data = _.orderBy(data, ['LoanID'], ['desc']);
          data.forEach((value) => {
            const statusCategory =
              value.LoanStatus && value.LoanStatus.LoanStatusCategory;
            if (!statusCategory) {
              return;
            }
            if (statusCategory === 'Existing') {
              existingLoans.push(value);
            }
            if (statusCategory === 'InProgress') {
              inProgressLoans.push(value);
            }
          });
          $scope.existingLoans = existingLoans;
          $scope.inProgressLoans = inProgressLoans;
        });
      };

      $scope.adviserSelected = (data) => {
        $scope.selectedAdviser = data;
      };

      $scope.assignAssistantAdv = (assistantId) => {
        corporateService
          .assignAssistantToClientSet(
            assistantId,
            $scope.AdviserId,
            parseInt($state.params.familyId, 10),
          )
          .then(() => {
            $scope.summaryHelper.getAssignedAssistant(
              $scope.AdviserId,
              $state.params.familyId,
            );
            toaster.pop('success', 'Assistant adviser successfully assigned.');
          });
      };

      $scope.addAssistant = () => {
        $scope.summaryHelper.getAssignedAssistant(
          $scope.AdviserId,
          $state.params.familyId,
        );
      };

      const successAllocation = (familyId, name) => {
        $scope.summaryHelper.getAssignedAssistant(
          familyId,
          $state.params.familyId,
        );
        toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
        $scope.mapModel.map.isCancelledTriggered = true;
        $scope.getBusinessDetails();
        commonFnService.tranferLoanApp(
          familyId,
          $state.params.familyId,
          name,
          $scope.businessAccount.Name,
          0,
        );
      };

      $scope.getContactAssignedAdviser = (list) => {
        const AdviserName = $scope.AdviserName;
        contactService
          .getContactAssignedAdviser($state.params.familyId)
          .then(({ data }) => {
            $scope.selectedAdviser = getSelectedAdviser(data, list);
            $scope.AdviserName = getAdviserName(
              $scope.selectedAdviser,
              AdviserName,
            );
            $scope.selectedAdviser = {
              ...$scope.selectedAdviser,
              AdviserId: data,
            };
          });
      };

      $scope.showAdviserContactList = () => {
        $scope.listView = true;
      };

      $scope.bizAllocateAdviser = (adviser) => {
        const name = adviser.title;
        if (adviser) {
          swal(
            {
              title: 'Confirm Change of Adviser',
              text: `By clicking proceed, the allocated adviser of this loan will be changed to ${name}?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Proceed',
              closeOnConfirm: true,
            },
            (confirmed) => {
              $scope.listView = true;
              if (!confirmed || !adviser.originalObject.FamilyId) {
                return;
              }

              if ($scope.contactModel.isCorporateUser) {
                corporateService
                  .assignClientToAdviserSet(
                    adviser.originalObject.FamilyId,
                    $state.params.familyId,
                    $state.params.familyId,
                  )
                  .then(() =>
                    successAllocation(adviser.originalObject.FamilyId, name),
                  );
              } else {
                contactService
                  .putAssignAdviser(
                    $state.params.familyId,
                    adviser.originalObject.FamilyId,
                  )
                  .then(() =>
                    successAllocation(adviser.originalObject.FamilyId, name),
                  );
              }
              $scope.listView = false;
            },
          );
        }
      };

      /**
       * Start: For edit mode
       * */
      $scope.businessAutoAddresss = () => {
        $timeout.cancel($scope.searchTimeout);
        if ($scope.business.Address && $scope.business.Address.length > 3) {
          $scope.searchTimeout = $timeout(() => {
            generalService
              .placeSearch($scope.business.Address)
              .then((respond) => {
                $scope.officeAutoAddressDetails = respond.data;
              });
          }, 500);
        }
      };

      $scope.selectBusinesAddress = (homeAddress) => {
        $scope.business.Address = homeAddress;
        $scope.geoCodedAddress = true;
        $scope.closeOfficeAddress();
      };

      $scope.closeOfficeAddress = () => {
        $scope.officeAutoAddressDetails = [];
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.saveBusinessAccount = () => {
        const isValidNZBNChar = validateNZBNfieldOnSubmit(
          { ...configService, ...currentUserService },
          { $scope },
        );
        const intlPhoneNumberElement = angular.element('#phoneNumberBusiness');

        if (
          isNotValidNZBNChar(isValidNZBNChar) &&
          $scope.trustType === COMPANY_TYPES.COMPANY_STRING
        ) {
          return;
        }

        if (
          $scope.business.Name &&
          $scope.business.Name.length > 0 &&
          $scope.editBusiness
        ) {
          const org = {
            OrganisationId: $scope.business.OrganisationId,
            Name: $scope.business.Name,
            Description: $scope.business.Description,
            OrganisationTaxNumber: $scope.business.OrganisationTaxNumber,
            OrganisationCompanyNumber:
              $scope.business.OrganisationCompanyNumber,
            Notes: $scope.business.Notes,
            FamilyId: $state.params.familyId,
            PersonId: '0',
            NZBN:
              ($scope.business.employmentNZBN &&
                $scope.business.employmentNZBN.toString()) ||
              '',
            Address: [
              {
                formatted_address: $scope.business.Address,
              },
            ],
          };

          if ($scope.business.OfficeEmail) {
            org.Email = [
              {
                Type: 'Email',
                EmailAddress: $scope.business.OfficeEmail,
              },
            ];
          }

          if (_.head($scope.business.Phone)) {
            if ($scope.isInternationalPhoneNumbers) {
              $scope.selectedCountryCode = countryCodeService.getAssignedCountryCode(
                {
                  isInternationalPhoneNumbers:
                    $scope.isInternationalPhoneNumbers,
                  selectorElement: intlPhoneNumberElement,
                },
              );
              org.Phone = [
                {
                  Type: 'Work',
                  Number: $scope.business.Phone,
                  CountryCode: $scope.selectedCountryCode.iso2.toUpperCase(),
                },
              ];
            } else {
              org.Phone = [
                {
                  Type: 'Work',
                  Number: $scope.business.Phone,
                },
              ];
            }
          }
          const myCRMconfigs = {
            ...configService,
            ...currentUserService,
          };

          const isIntlTelInputNumberValid =
            $scope.isInternationalPhoneNumbers &&
            !countryCodeService.validatePhoneNumberFormat({
              element: intlPhoneNumberElement,
              isPhoneRequired: false,
              message: 'This is not required',
              phoneNumber: $scope.business.Phone,
              isLandline: true,
            });

          if (isIntlTelInputNumberValid) {
            return false;
          }
          onOrganisationInfoSet({
            $scope,
            contactService,
            contactModelService,
            myCRMconfigs,
            org,
          });
        } else {
          toastError('Invalid Form - Business Name required');
        }
      };

      /**
       * End: For edit mode
       * */
      $scope.deleteBusinessAccount = (info) => {
        if (info) {
          swal(
            {
              title: 'Delete Business Account',
              text: 'This record will be removed from your clients list',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: true,
            },
            () => {
              contactService.deleteContact($state.params.familyId).then(() => {
                toaster.pop(
                  'success',
                  'Deleted',
                  'Business contact successfully deleted',
                );
                $scope.routeContactList();
              });
            },
          );
        }
      };

      $scope.selectBusinessContacts = (selectedContacts, defaultAction) => {
        stateTransition(selectedContacts);
        $scope.closeHiddenTabs();
        $timeout(() => {
          angular
            .element(`#${selectedContacts}`)
            .find('a')
            .triggerHandler('click');
        }, 0);
        $scope.selectedContacts = selectedContacts;

        if (defaultAction) {
          $scope.defaultTabOnloadAction = defaultAction;
        }
      };

      $scope.registerChildHandler = (handler) => {
        $scope.timelineChildNotificationHandler = handler;
      };

      $scope.processTimelineRefreshNotification = () => {
        $scope.timelineChildNotificationHandler('refresh-timeline');
      };

      $scope.goToTabAndOpenModal = (tab, defaultAction) => {
        $scope.selectBusinessContacts(tab, defaultAction);
      };

      $scope.moreTab = () => {
        $scope.hiddenTabs = false;

        $scope.openHiddenTabs = () => {
          $scope.hiddenTabs = $scope.hiddenTabs === false;
        };

        $scope.closeHiddenTabs = () => {
          $scope.hiddenTabs = false;
        };

        $scope.calcWidth = () => {
          let navwidth = 0;
          const parentTab = $('.nav-tabs > li.contact-parent-tab');
          $scope.hiddenTabCount = $('.more li').length;

          const morewidth = $('ul.nav-tabs .more').outerWidth(true);

          parentTab.each((index, element) => {
            navwidth += $(element).outerWidth(true);
          });

          const availablespace = $('ul.nav-tabs').outerWidth(true) - morewidth;

          if (navwidth > availablespace) {
            const lastItem = parentTab.last();
            lastItem.attr('data-width', lastItem.outerWidth(true));
            lastItem.prependTo($('.nav-tabs .more ul'));
            $scope.calcWidth();
          } else {
            const firstMoreElement = $('.nav-tabs li.more li').first();

            if (navwidth + firstMoreElement.data('width') < availablespace) {
              firstMoreElement.insertBefore($('.nav-tabs .more'));
            }
          }

          $scope.toggleOverflowTabs();
        };

        $scope.toggleOverflowTabs = () => {
          $scope.narrowNav = $scope.hiddenTabCount <= 0;
        };

        $scope.hiddenTabCount = $('.more li').length;

        $scope.toggleOverflowTabs();

        $timeout(() => {
          $scope.calcWidth();
        });

        $(window).on('resize orientationchange load', () => {
          $scope.calcWidth();
        });
      };

      $scope.moreTab();
      $scope.summaryHelper.init();
    });
