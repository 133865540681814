import {
  LIST_OF_QUESTIONS,
  LIST_OF_QUESTIONS_NZ,
} from 'Common/constants/customerObjectives';

export const COMMON_HOLDERS = {
  SELECT_PREFERENCE: 'Select Preference',
  CHOOSE_REASON: 'Choose reason',
  SELECT_DURATION: 'Select duration',
  ENTER_DETAILS: 'Enter Details',
  ADD_PREFERENCE: 'Add Preference',
  SELECT_FREQUENCY: 'Select frequency',
  CHOOSE_PERIOD: 'Choose period',
  CHOOSE_OPTION: 'Choose option',
  SET_REASONS: 'Set priorities and reasons',
  ADD_REASON: 'Add Reason',
};

export const REQUIREMENTS_OBJECTIVES_AU = {
  [LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE]: COMMON_HOLDERS.SELECT_PREFERENCE,
  [LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT]: COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS.RATE_DURATION]: COMMON_HOLDERS.SELECT_DURATION,
  [LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS]: COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE]:
    COMMON_HOLDERS.ADD_PREFERENCE,
  [LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT]:
    COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE]:
    COMMON_HOLDERS.ADD_PREFERENCE,
  [LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY]: COMMON_HOLDERS.SELECT_FREQUENCY,
  [LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY]: COMMON_HOLDERS.CHOOSE_PERIOD,
  [LIST_OF_QUESTIONS.REPAYMENT_OPTION]: COMMON_HOLDERS.CHOOSE_OPTION,
  [LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT]: COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS]: COMMON_HOLDERS.SET_REASONS,
  [LIST_OF_QUESTIONS.OTHER_REQUIREMENTS_DETAILS]: COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS.REASON_FOR_REFINANCE]: COMMON_HOLDERS.ADD_REASON,
  [LIST_OF_QUESTIONS.EXISTING_CREDIT_CARD_DETAILS]:
    COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS.CONFLICT_OF_INTEREST_DETAILS]:
    COMMON_HOLDERS.ENTER_DETAILS,
};

export const REQUIREMENTS_OBJECTIVES_NZ = {
  [LIST_OF_QUESTIONS_NZ.CHOOSE_RATE_PREFERENCE]:
    COMMON_HOLDERS.SELECT_PREFERENCE,
  [LIST_OF_QUESTIONS_NZ.RATE_PREFERENCES_IMPORTANT]:
    COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS_NZ.RATE_DURATION]: COMMON_HOLDERS.SELECT_DURATION,
  [LIST_OF_QUESTIONS_NZ.RATE_IMPORTANT_DETAILS]: COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS_NZ.SPECIAL_CHOOSE_RATE_PREFERENCE]:
    COMMON_HOLDERS.ADD_PREFERENCE,
  [LIST_OF_QUESTIONS_NZ.SPECIAL_RATE_PREFERENCES_IMPORTANT]:
    COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS_NZ.CHOOSE_REPAYMENT_PREFERENCE]:
    COMMON_HOLDERS.ADD_PREFERENCE,
  [LIST_OF_QUESTIONS_NZ.REPAYMENT_FREQUENCY]: COMMON_HOLDERS.SELECT_FREQUENCY,
  [LIST_OF_QUESTIONS_NZ.REPAYMENT_INTEREST_ONLY]: COMMON_HOLDERS.CHOOSE_PERIOD,
  [LIST_OF_QUESTIONS_NZ.REPAYMENT_OPTION]: COMMON_HOLDERS.CHOOSE_OPTION,
  [LIST_OF_QUESTIONS_NZ.REPAYMENT_IMPORTANT]: COMMON_HOLDERS.CHOOSE_REASON,
  [LIST_OF_QUESTIONS_NZ.CONFLICT_DECLARATION_DETAILS]:
    COMMON_HOLDERS.SET_REASONS,
  [LIST_OF_QUESTIONS_NZ.OTHER_REQUIREMENTS_DETAILS]:
    COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS_NZ.REASON_FOR_REFINANCE]: COMMON_HOLDERS.ADD_REASON,
  [LIST_OF_QUESTIONS_NZ.EXISTING_CREDIT_CARD_DETAILS]:
    COMMON_HOLDERS.ENTER_DETAILS,
  [LIST_OF_QUESTIONS_NZ.CONFLICT_OF_INTEREST_DETAILS]:
    COMMON_HOLDERS.ENTER_DETAILS,
};

export const TEXT_PLACEHOLDERS = {
  ADVISER: 'Search Clients or Companies',
  CORPORATE: 'Search Advisers, Assistants, Orgs',
  SUPER_ADMIN: 'Search MyCRM',
};
